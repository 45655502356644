import { Element } from './getWord';
import { Annotation } from '../arenaParser';
import { getGeometry } from './getGeometry';

const ROW_HEAD = "ROW_HEAD";
const COLUMN_HEAD = "COLUMN_HEAD";
const CELL_EXPLICIT_BOUNDARIES = "CELL_EXPLICIT_BOUNDARIES";
const CELL_IMPLICIT_BOUNDARIES = "CELL_IMPLICIT_BOUNDARIES";
const TABLE_CELL = "TABLE_CELL";
const EXPLICIT_BOUNDARY = "Explicit";
const IMPLICIT_BOUNDARY = "Implicit";

function getLabelName(element: Element): string {
    if ("isRowHead" in element.metadata && element.metadata.isRowHead) {
        return ROW_HEAD;
    } else if ("isColumnHead" in element.metadata && element.metadata.isColumnHead) {
        return COLUMN_HEAD;
    } else if ("top" in element.metadata && element.metadata.top === EXPLICIT_BOUNDARY) {
        return CELL_EXPLICIT_BOUNDARIES;
    } else if ("top" in element.metadata && element.metadata.top === IMPLICIT_BOUNDARY) {
        return CELL_IMPLICIT_BOUNDARIES;
    } else {
        return TABLE_CELL;
    }
}

export function getTableCell(element: Element): Annotation {
    return ({
        label: "",
        labelName: getLabelName(element),
        geometry: getGeometry(element),
        id: element.id
    });
}