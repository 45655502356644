export const distinctColors = [
  "#66cdaa",
  "#0000ff",
  "#00fa9a",
  "#f08080",
  "#ff00ff",
  "#1e90ff",
  "#dda0dd",
  "#ff1493",
  "#87cefa",
  "#7f0000",
  "#808000",
  "#8fbc8f",
  "#00ffff",
  "#ff4500"
]

/**
 * A class with an easy to use interface for retriving different colors.
 *
 * @param colors a list of colors defined as a string. The default is a list
 * of 20 visually distinct colors.
 */
export class ColorGenerator {
  index: number
  colors: Array<string>

  constructor(colors = distinctColors) {
    this.index = 0
    this.colors = colors
  }

  /**
   * @returns a new color
   */
  next(): string {
    const color = this.colors[this.index % this.colors.length]
    this.index++
    return color
  }
}
