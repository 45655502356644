import React, { useState, useEffect } from "react"
import { getAssetImage } from "../API/Api"
import PageLoader from "../Components/PageLoader"
import queryString from "query-string"
import { CognitoUser } from "@aws-amplify/auth"
import { getIdentityToken } from "../Utils/UserUtils"
import styled from "styled-components"
import { Tabs, Button } from "@amzn/awsui-components-react"
import TranscriptionWithTable from "../Annotation/TranscriptionWithTable"
import AnnotationViewer from "../Annotation/annotation-viewer"
import arenaParser, {
  transformSchemaToUIFormat,
  WORDS
} from "../Annotation/arenaParser"
import globalLegend from "../Annotation/global-legend"

const PLACEHOLDER_IMAGE =
  "https://s3-us-west-2.amazonaws.com/thunder-assets/placeholder.png"

type Props = {
  match: {
    params: {
      asset: string
    }
  }
  location: {
    pathname: string
    search: string
  }
  stage: string
  user: {
    cognitoUser: CognitoUser
  }
}

const DownloadAnnotations = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const AssetView = (props: Props) => {
  const assetId = props.match.params.asset
  const [isLoading, setIsLoading] = useState(true)
  const [originalImage, setOrginalImage] = useState(PLACEHOLDER_IMAGE)
  const [annotationObject, setAnnotationObject] = useState({
    schemaVersion: 0,
    pageMetadata: {
      width: 0,
      height: 0,
      baseAssetId: ""
    },
    elements: []
  })

  const [jsonUrl, setJsonUrl] = useState("")

  useEffect(() => {
    const queryObject = queryString.parse(props.location.search) || {}
    const { key } = queryObject

    const getAssets = async () => {
      const idToken = getIdentityToken(props.user.cognitoUser)

      if (key && typeof key === "string") {
        const jsonKeyPrefix =
          key
            .substring(0, key.lastIndexOf("."))
            .replace("ImageAssets", "Annotations") + ".json"
        const jsonFileResponse = await getAssetImage(
          jsonKeyPrefix,
          idToken,
          props.stage
        )
        const imageWithAnnotationResponse = await getAssetImage(
          key,
          idToken,
          props.stage
        )

        setOrginalImage(imageWithAnnotationResponse.url)
        setJsonUrl(jsonFileResponse.url)
        setAnnotationObject(JSON.parse(imageWithAnnotationResponse.annotation))
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }

    getAssets()
  }, [])

  const tabs = [
    {
      label: "All annotations",
      id: "all",
      content: (
        <AnnotationViewer
          originalImage={{ url: originalImage, name: assetId }}
          annotations={transformSchemaToUIFormat(annotationObject)}
          datasetColorMap={globalLegend}
        />
      )
    },
    {
      label: "Word transcriptions",
      id: "transcriptions",
      content: (
        <TranscriptionWithTable
          originalImage={{ url: originalImage, name: assetId }}
          annotations={arenaParser({
            annotationType: WORDS,
            annotationData: annotationObject
          })}
        />
      )
    }
  ]

  return isLoading ? (
    <PageLoader />
  ) : (
    <div>
      <DownloadAnnotations>
        <Button icon="download" href={jsonUrl}>
          Download Annotations
        </Button>
      </DownloadAnnotations>
      <Tabs tabs={tabs} activeTabId="all" />
    </div>
  )
}

export default AssetView
