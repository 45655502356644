import React, {useState} from 'react'
import {StepMapping} from './Config'
import {IStep} from "../../Utils/Marvin_Wizard/interfaces";
import Wizard from "../../Utils/Marvin_Wizard/wizard";
import {variant, getRequestJSON, getDefaultFormData} from './Utils'
import './style.css'


export default (props: {
    //to do: make the stage be accessed by the whole app.
    stage: string,
    submitting: boolean,
    actionSubmit: (
        json:object,
        extraActions: () => void
    ) => void
}) => {
    const [currStep, setCurrStep] = useState<number>(0),
        [data, setData] = useState<object>(
            getDefaultFormData(props.stage)
        ),
        handleWorkflowTrigger = () => {
            const json = getRequestJSON(data)
            props.actionSubmit(
                json,
                () => {
                    setData(getDefaultFormData(props.stage))
                    setCurrStep(0)
                }
            )
        }

    const steps: IStep[] = StepMapping.map((itemStep, i) => (
        itemStep.getStep({
            stage: props.stage,
            index: i,
            totalStep: StepMapping.length,
            toStep: setCurrStep,
            getData: () => data,
            setData
        })
    ))

    if(steps.length > 0) {
        steps[steps.length - 1].actions = [
            ...(steps[steps.length - 1].actions || []),
            {
                id: `submit`,
                variant: variant,
                text: 'Submit',
                disabled: props.submitting,
                onClick: handleWorkflowTrigger
            }
        ]
    }

    return <Wizard
        currentStep={currStep}
        stepPrefix="Step"
        steps={steps}
    />
}
