import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import PageTitle from '../Components/PageTitle'
import Button from '@amzn/awsui-components-react/polaris/button'
import Form from '@amzn/awsui-components-react/polaris/form'
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox'
import Modal from '@amzn/awsui-components-react/polaris/modal'
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import Input from '@amzn/awsui-components-react/polaris/input'
import { Icon } from '@amzn/awsui-components-react';
import { CustomDetailEvent } from '@amzn/awsui-components-react';
import ImageGrid from '../Components/ImageGrid'
import { Url, getAssetImages } from '../API/Api';
import { removePrefixDirectoryFromString } from '../Utils/StringUtils';
import { CognitoUser } from '@aws-amplify/auth';
import { getIdentityToken } from '../Utils/UserUtils';
import queryString from 'query-string'
import { withSlideAnimation } from '../Animations/Animations';
import ImageModal, { Dimensions } from '../Components/ImageModal';
import { debounce } from '../Utils/AuxillaryUtils';

type Props = {
    location: {
        pathname: string,
        search: string,
    },
    history: {
        replace: Function
    }
    stage: string,
    user: {
        cognitoUser: CognitoUser
    }
}

type SelectableImage = {
    name: string,
    url: string,
    selected: boolean
}

const ErrorContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
`
const ItemTitle = styled.span`
    margin-left: 0.5rem;
    font-size: 13px;
`
const TitleRow = styled.span`
    display: flex;
`
const AnimatedFormField = withSlideAnimation(FormField, "TOP")

const renderImageName = (imageName: string) => {
    let name = imageName

    // Removes the leading '/' to beautify the name
    if(imageName.charAt(0) === "/") { name = imageName.substring(1) }
    const directories = name.split('/')

    return (
        <NameContainer>
            {
                directories.map((pathName, index) => (
                        <TitleRow key={pathName}>
                            {index + 1 === directories.length 
                                ? <Icon name="file"/>
                                : <Icon name="folder"/>
                            }
                            <ItemTitle>{pathName}</ItemTitle>
                        </TitleRow>
                    )
                )
            }
        </NameContainer>
    )
    
}

// Transforms bucket-name/path/to/directory -> {bucketname, directory}
const splitPathIntoBucketAndDirectory = (path: string) => {
    const slashIndex = path.indexOf("/")
    const hasDirectories = slashIndex >= 0
    return {
        bucket: hasDirectories ? path.slice(0, slashIndex) : path,
        directory: hasDirectories ? path.slice(slashIndex+1) : ""
    }
}

const parseQueryParameters = (params: string) => {
    const queryObject = queryString.parse(params) || {}
    const {bucketName, dirPath, roleARN} = queryObject
    const bucket = typeof(bucketName) === "string" && bucketName || ""
    const directory = typeof(dirPath) === "string" && dirPath || ""
    const role = typeof(roleARN) === "string" && roleARN || ""
    return {bucket, directory, role}
}

const calculateNextIndex = (index: number, numImages: number) => (
    index + 1 < numImages ? index + 1 : numImages - 1
)
const calculatePrevIndex = (index: number) => (
    index - 1 >= 0 ? index -1 : 0
)

const VisualizerView = (props: Props) => {
    const {bucket, directory, role} = parseQueryParameters(props.location.search)

    const [isLoading, setIsLoading] = useState(false)
    const [images, setImages] = useState<Array<SelectableImage>>([])
    const [errorMsg, setErrorMsg] = useState<string>("")
    const [bucketNameMissing, setBucketNameMissing] = useState<string>("")
    const [continuationToken, setContinuationToken] = useState<string>("")
    const [roleARN, setRoleARN] = useState<string>(role)
    const [bucketName, setBucketName] = useState<string>(bucket)
    const [dirPath, setDirPath] = useState<string>(directory)
    const [showSelectedImages, setShowSelectedImages] = useState<boolean>(false)
    const [showRoleField, setShowRoleField] = useState<boolean>(false)
    const [imageIndex, setImageIndex] = useState<number>(0)
    const [showModal, setShowModal] = useState(false)
    const [imageClicked, setImageClicked] = useState<Dimensions>({x: 0, y: 0, width: 0, height: 0})
    const onShowSelectedImages = () => setShowSelectedImages(true)
    const loadMoreAssets = () => setIsLoading(true)
    const onCloseModal = () => setShowModal(false)
    const onMoveNextRequest = () => setImageIndex(calculateNextIndex(imageIndex, images.length))
    const onMovePrevRequest = () => setImageIndex(calculatePrevIndex(imageIndex))
    const onRoleARNChange = (item: CustomDetailEvent<Input.ChangeDetail>) => {
        setRoleARN(item.detail.value)
    }
    const onPathChange = (item: CustomDetailEvent<Input.ChangeDetail>) => {
        const path = item.detail.value
        const {bucket, directory} = splitPathIntoBucketAndDirectory(path)
        setBucketName(bucket)
        setDirPath(directory)
    }
    const debouncedPathChange = debounce(onPathChange, 1200)
    const onShowRoleField = (event: CustomDetailEvent<Checkbox.ChangeDetail>) => {
        setShowRoleField(event.detail.checked)
    }
    const onImageClick = (image: Url, index: number, event: SyntheticEvent) => {
        setShowModal(true)
        setImageIndex(index)
    
        const box = event.currentTarget.getBoundingClientRect()
        setImageClicked({
          x: box.left,
          y: box.top,
          width: box.width,
          height: box.height,
        })
      }

    const onVisualize = () => {
        if(bucketName) {
            setImages([])
            setContinuationToken("")
            setIsLoading(true)
            setBucketNameMissing("")
            setErrorMsg("")

            // Set the query parameters when the user submits the form for easy url sharing
            const params = queryString.stringify({ bucketName, roleARN, dirPath })
            props.history.replace({
                pathname: props.location.pathname,
                search: params,
            })
        } else {
            setBucketNameMissing("Bucket name is required")
        }
    }

    // Submit the form on page load when the user provides query parameters
    useEffect(() => {onVisualize()}, [bucketName, dirPath, roleARN])

    // Calculate the image dimensions when the image changes
    useEffect(function getActiveImageDimensions() {
        if(imageIndex < 0 || imageIndex >= images.length) return
    
        const activeImage = images[imageIndex]
        const image = document.getElementById(activeImage.name)
        if(image) {
            const box = image.getBoundingClientRect()
            setImageClicked({
              x: box.left,
              y: box.top,
              width: box.width,
              height: box.height,
            })
        }
      }, [imageIndex])

    useEffect(() => {
        const getImages = async () => {
            try {
                const idToken = getIdentityToken(props.user.cognitoUser)
                const nextBatch = await getAssetImages(dirPath, idToken, props.stage, continuationToken, bucketName, roleARN)
                const nextImages = (nextBatch.Images && nextBatch.Images.map((image: Url) => ({ selected: false, ...image }))) || []
                nextImages.length === 0 && setErrorMsg(nextBatch.Error || "")
                const strippedImages = nextImages.map(img => ({...img, name: removePrefixDirectoryFromString(img.name, dirPath)}))
                setImages([
                    ...images,
                    ...strippedImages
                ])
                setContinuationToken(nextBatch.ContinuationToken || "")
                setIsLoading(false)
            } catch (e) {
                setErrorMsg(e.toString())
                console.log(e)
            }
        }

        if (isLoading) {
            getImages()
        }
    }, [isLoading])
    const selected = images.filter(img => img.selected)
    const viewSelectedImageText = `View selected images (${selected.length})`
    const bucketNameValidation = bucketNameMissing && {errorText: bucketNameMissing}
    const errorMessage = errorMessageFormatter(errorMsg)
    const path = bucketName && `${bucketName}/${dirPath}` || ""

    return <div>
        <PageTitle title="Visualize an S3 directory" />
        <Modal
            content={selected.map(image => <p>{image.name}</p>)}
            visible={showSelectedImages}
            header="Selected images"
            onDismiss={() => setShowSelectedImages(false)}
        ></Modal>
        <Form
            description={<p>View any of the images stored in S3. Follow the steps in this <a href="https://quip-amazon.com/8zczAKda8cwK/SOP-Whitelisting-accounts-to-S3-visualizer">wiki</a> if you want to use an AWS account other than <b>rekognition-tools-aws-prod</b></p>}>
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">
                    <div className="awsui-util-action-stripe">
                        <div className="awsui-util-action-stripe-title">
                            <h2>S3 details</h2>
                        </div>
                        <div className="awsui-util-action-stripe-group">
                            <Button 
                                text={viewSelectedImageText} 
                                onClick={onShowSelectedImages} />
                        </div>
                    </div>
                </div>

                <ColumnLayout>
                    <div data-awsui-column-layout-root="true">
                        <FormField label="S3 directory path" {...bucketNameValidation}>
                            <Input value={path} onInput={debouncedPathChange} placeholder="S3_bucket/path/to/directory"/>
                        </FormField>
                        <Checkbox label={<span>Use your own AWS account instead of <em>rekognition-tools-aws-prod</em></span>} onChange={onShowRoleField} />
                        {
                            showRoleField && <AnimatedFormField label="IAM role arn" hintText="Role must provide S3 read permission">
                                <Input value={roleARN || ""} onChange={onRoleARNChange} placeholder="arn:aws:iam::123456789:role/my-read-s3-role"/>
                            </AnimatedFormField>
                        }
                    </div>
                </ColumnLayout>
            </div>
        </Form>
        {
            errorMsg && <ErrorContainer>
                {errorMessage.header}
                {errorMessage.body}
            </ErrorContainer>
        }
        <ImageGrid
            images={images}
            onImageClick={onImageClick}
            onSelect={(_, index) => {
                setImages(images.map(
                    (item, i) => index === i 
                        ? { ...item, selected: !item.selected } 
                        : item
                    ))
            }}
            hasMore={continuationToken !== ""}
            renderNameComponent={renderImageName}
            isLoading={isLoading}
            onScrollEnd={loadMoreAssets}
        />
        <ImageModal
            showModal={showModal}
            image={images[imageIndex] || null}
            nextImage={images[calculateNextIndex(imageIndex, images.length)]}
            prevImage={images[calculatePrevIndex(imageIndex)]}
            onDismiss={onCloseModal}
            onMoveNextRequest={onMoveNextRequest}
            onMovePrevRequest={onMovePrevRequest}
            currentImageNumber={imageIndex + 1}
            numberOfImages={images.length}
            dimensions={imageClicked}
            loadMoreImages={loadMoreAssets}
        />
    </div>
}


const errorMessageFormatter = (errorMsg: string) => {
    if(errorMsg.includes("An error occurred (AccessDenied)")) {
        return {
            header: <h2>Thunder doesn't have permission to use your role to access S3</h2>,
            body: <p>Follow this <a href="https://quip-amazon.com/8zczAKda8cwK/SOP-Whitelisting-accounts-to-S3-visualizer">wiki</a> to give Thunder the appropriate permission then try again.</p>
        }
    }
    if(errorMsg.includes("Invalid length for parameter RoleArn")) {
        return {
            header: <h2>Your role arn is the wrong format</h2>,
            body: <p>Take a look at this <a href="https://docs.aws.amazon.com/general/latest/gr/aws-arns-and-namespaces.html#genref-arns">wiki</a> for more details</p>
        }
    }
    return {
        header: <h2>No images found</h2>,
        body: <p>Please try a different S3 bucket or directory.</p>
    }
}

export default VisualizerView