import React, {Fragment} from 'react'
import FormSection from '@amzn/awsui-components-react/polaris/form-section'
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import {getActions, checkStepDone} from '../Utils'
import {StepConfig, WorkflowAttrs} from '../Interfaces'
import {IStep} from "../../../Utils/Marvin_Wizard/interfaces";
import {StepMapping} from '../Config'


const Review = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const currData = props.getData()
    return <Fragment>
    {
        StepMapping.filter((itemStep, i) => {
            return i !== 4
        }).map((itemStep, i) => {
            const values = itemStep.getValues(currData)
            return <FormSection
                key={i}
                header={"Step " + (i + 1)}
            >
                <div
                    data-awsui-column-layout-root="true"
                >
                <ColumnLayout columns={1}>
                <div data-awsui-column-layout-root="true">
                    {
                        Object.keys(values).map(key => {
                            return <div key={values[key]['label']}>
                                <div className="awsui-util-label">{values[key]['label']}</div>
                                <div>{values[key].getDomReview()}</div>
                            </div>
                        })
                    }
                </div>
                </ColumnLayout>
                </div>
            </FormSection>
        })
    }
    </Fragment>
}

export default (config: StepConfig): IStep => {
    const currData = config.getData()
    return {
        id: 'step_review',
        content: <Review
            getData={config.getData}
            setData={config.setData}
        />,
        title: 'Review',
        onClick: (stepIndex: number) => {
            return (e: any) => {
                config.toStep(stepIndex)
            }
        },
        actions: getActions(
            config.index,
            config.totalStep,
            config.toStep,
            checkStepDone(config.index, currData)
        )
    }
}
