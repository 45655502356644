interface Amplify {
  [dev: string]: Object,
  beta: Object,
  prod: Object,
}

export const AmplifyConfig: Amplify = {
    dev: {
        Auth: {
          identityPoolId: "us-west-2:be2b9369-0150-4a48-8cbf-5f75024f789d",
          region: "us-west-2",
          userPoolId: "us-west-2_jJ92VxOwD",
          userPoolWebClientId: "6tia4k2i5l5pleis383isbedm5",
          mandatorySignIn: true,
          oauth: {
            domain: 'thunder-dev.auth.us-west-2.amazoncognito.com',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code'
          }
        }
    },
    beta: {
        Auth: {
            identityPoolId: "us-west-2:8b315d1a-7219-40b1-b53b-b8a4a90fe0be",
            region: "us-west-2",
            userPoolId: "us-west-2_Whd9HGIIN",
            userPoolWebClientId: "1u6b1pkrh232shglkr7kkk4shq",
            mandatorySignIn: true,
            oauth: {
              domain: 'thunder-beta.auth.us-west-2.amazoncognito.com',
              scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: 'https://beta.thunder.rekognition.aws.a2z.com/',
              redirectSignOut: 'https://beta.thunder.rekognition.aws.a2z.com/',
              responseType: 'code'
            }
          }
    },
    prod: {
        Auth: {
            identityPoolId: "us-west-2:46514c3e-0502-48bd-ae85-1d108ecd8d91",
            region: "us-west-2",
            userPoolId: "us-west-2_bxAZB1V3k",
            userPoolWebClientId: "6jn24if47vi2h9445lkcgjslg7",
            mandatorySignIn: true,
            oauth: {
              domain: 'thunder-prod.auth.us-west-2.amazoncognito.com',
              scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: 'https://thunder.rekognition.aws.a2z.com/',
              redirectSignOut: 'https://thunder.rekognition.aws.a2z.com/',
              responseType: 'code'
            }
          }
    }
}