import * as React from "react";
import "./styles.css";
import { IStep, StepStyle } from "./interfaces";

export interface IWizardNavigationProps {
  currentStep: number;
  stepPrefix: string;
  steps: IStep[];
}

export class WizardNavigation extends React.Component<IWizardNavigationProps, {}> {

  getStepStyle(navStep: number): string {

    if (navStep < this.props.currentStep) {
      return StepStyle.PAST;
    } else if (navStep === this.props.currentStep) {
      return StepStyle.ACTIVE;
    }
    return StepStyle.FUTURE;
  }

  isPastStep(navStep: number): boolean {
    return navStep < this.props.currentStep;
  }

  render() {
    const steps = this.props.steps;
    let index: number = 0;
    if (steps.length === 1) {
      return null;
    }

    return (
      <div className="col-m-2">
        <div className="awsmob-wizard-navigation">
          {steps.map((step: IStep) => (
            !step.hidden && <div key={index}>
              <small>{this.props.stepPrefix + " " + (index + 1)}</small>
              <div className={this.getStepStyle(index)}>
                {(this.isPastStep(index++) && step.onClick) &&
                  <span onClick={step.onClick(index - 1)}>{step.navTitle || step.title}</span> ||
                  <span>{step.navTitle || step.title}</span>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
