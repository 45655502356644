import { Filters } from "./filterConfig";
import { ParsedIdentityData, ParsedIdentityDataItem } from "../../API/Parsers/Types";
import { isEmpty } from "lodash";

export type JSONDownload = {
  name: string;
  id: string;
  images: string[];
};

export type Metadata = {
  key: string;
  value: string;
}[];

export const emptyIdentityData = {
  id: "",
  name: "",
  populationGroup: "",
  gender: "",
  dateOfBirth: "",
  occupation: "",
  url: "",
  referenceImages: []
};

export const parseIdentityMetadata = (data: ParsedIdentityDataItem): Metadata => {
  const metadataFields: { [key: string]: string } = {
    population_group: "Population Group",
    gender: "Gender",
    data_of_birth: "Date of Birth",
    occupation: "Occupation",
    url: "Wikipedia"
  };
  return Object.entries(data).reduce((acc: any, [key, value]) => {
    if (metadataFields[key]) {
      acc = [...acc, { key: metadataFields[key], value }];
    }
    return acc;
  }, []);
};

export const calculateNextIndex = (index: number, numImages: number) =>
  index + 1 < numImages ? index + 1 : numImages - 1;

export const calculatePrevIndex = (index: number) =>
  index - 1 >= 0 ? index - 1 : 0;

export const updateUrlHashParams = (params: Filters) => {
  const queryParams = JSON.stringify(params);
  const existingHash = window.location.hash;
  const paramsIndex = existingHash.indexOf("?");
  window.location.hash =
    paramsIndex === -1
      ? existingHash.substring(paramsIndex) + "?" + queryParams
      : existingHash.substring(0, paramsIndex) + "?" + queryParams;
  return;
};

export const getParsedUrlHashParams = (): Promise<any> =>
  new Promise(resolve => {
    const [slugs, queryParams] = window.location.hash.split("?");
    const id = slugs.split("/").pop();
    let params: Filters = {};

    if (queryParams && !isEmpty(queryParams)) {
      params = JSON.parse(decodeURIComponent(queryParams));
      resolve({ id, params });
    }
    resolve({ id, params });
  });

export const downloadObjectAsJson = (
  fileContent: JSONDownload,
  fileName: string
) => {
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(fileContent));
  const downloadAnchorNode: HTMLAnchorElement = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", fileName + ".json");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
