import React, { useState } from "react";
import styled from "styled-components";
import { ImageDropzone } from "../Components/ImageUpload/ImageDropzone";
import { UploadListContainer } from "../Components/ImageUpload/UploadListContainer";
import { getFaceClusterPresignedUrl } from "../API/Api";
import { CognitoUser } from "@aws-amplify/auth";
import { getIdentityToken } from "../Utils/UserUtils";
import { History } from "history";
import {supportedProjects, projectToFeatureMap} from '../Components/ImageUpload/projects'

const maxFileSize = 104857600; // 100 * 1024 * 1024 = 100 MB

type ImageData = {
  image: File;
  previewUrl: any;
  date: number;
  size: number;
};

type Props = {
  stage: string;
  user: {
    cognitoUser: CognitoUser;
  };
  history: History;
};

const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ImageUpload = (props: Props) => {
  const [uploading, setUploading] = useState(false);
  const [imagesArray, setImagesArray] = useState<Array<ImageData>>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [project, setProject] = useState(supportedProjects[0])

  const onDrop = (files: Array<File>) => {
    setUploadSuccess(false);
    var images: Array<ImageData> = files.map(image => {
      var imagePreviewUrl = URL.createObjectURL(image);
      return {
        image: image,
        previewUrl: imagePreviewUrl,
        date: image.lastModified,
        size: image.size
      };
    });
    setImagesArray([...imagesArray, ...images]);
  };

  const postToPresignedUrl = async (filename: string, image: File) => {
    const idToken = getIdentityToken(props.user.cognitoUser);
    const userEmail = props.user.cognitoUser.getUsername();
    const alias = userEmail.split("_").pop();
    const finalAlias = alias!.substring(0, alias!.lastIndexOf("@"));
    const feature = projectToFeatureMap[project]
    const response = await getFaceClusterPresignedUrl(
      finalAlias,
      idToken,
      filename,
      feature,
      props.stage
    );
    const url = response.url;
    var config = {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "image/*"
      }),
      body: image
    };

    return fetch(url, config)
      .then(response => {
        if (!response.ok) {
          throw new Error("Unable to upload image to S3");
        } else {
          return true;
        }
      })
      .catch(error => {
        console.log("Error when uploading images to S3", error);
        alert(error);
      });
  };

  const uploadImages = () => {
    // Upload images to presigned Url
    setUploading(true);
    Promise.all(
      imagesArray.map(async item => {
        await postToPresignedUrl(item.image.name, item.image);
      })
    ).then(() => {
      setImagesArray([]);
      setUploadSuccess(true);
      setUploading(false);
      /** TODO: Uncomment this to direct the person to the annotation tool once approval is provided */
      // var route = "/cluster"
      // props.history.push(route)
    });
  };

  const onRemoveImage = (previewUrl: string) => {
    var filteredImages = imagesArray.filter(image => {
      return image.previewUrl != previewUrl;
    });
    setImagesArray(filteredImages);
  };

  const numFiles: number = imagesArray.length;

  return (
    <Content>
      <div className="awsui-grid">
        <div className="awsui-row">
          <div className="col-4">{ImageDropzone(onDrop, maxFileSize)}</div>
          <div className="col-8">
            {UploadListContainer(
              numFiles,
              uploadImages,
              onRemoveImage,
              imagesArray,
              uploading,
              uploadSuccess,
              project,
              setProject
            )}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ImageUpload;
