/**
 * Promise that resolves when image is loaded
 * else rejects if image is unable to load
 *
 * @param {*} url
 * @param {*} success An optional callback fired onload
 * @param {*} error An optional callback fired onerror
 * @resolves An HTMLImageElement on success
 */
export default (
  url: string,
  success?: Function,
  error?: Function
): Promise<any> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => (success && success(img), resolve(img));
    img.onerror = () => (error && error(), reject("Image could not be loaded"));
    img.src = url;
  });
