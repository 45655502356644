import React, { useState, useEffect } from "react";
import PageTitle from "../Components/PageTitle";
import PageSubtitle from "../Components/PageSubtitle";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import CardsSelection from "@amzn/awsui-components-react/polaris/cards-selection";
import CardsPagination from "@amzn/awsui-components-react/polaris/cards-pagination";
import CardsFiltering from "@amzn/awsui-components-react/polaris/cards-filtering";
import Button from "@amzn/awsui-components-react/polaris/button";
import TableCounter from "../Components/TableCounter";
import { Link } from "react-router-dom";
import { Project, getQAProjects } from "../API/Api";
import styled from "styled-components";
import { TableSelection } from "@amzn/awsui-components-react";
import { CognitoUser } from "@aws-amplify/auth";
import { getIdentityToken } from "../Utils/UserUtils";

const MAX_PAGE_SIZE = 10;

type CardDefinition = {
  header: (item: Project) => JSX.Element;
  sections: Array<section>;
};
type section = {
  id: string;
  width: number;
  header: (item: Project) => string;
  content: (item: Project) => JSX.Element;
};


type Props = {
  stage: string;
  user: {
    cognitoUser: CognitoUser;
  };
};

const CardTitle = styled.h2`
  && {
    font-weight: 500;
    font-size: 2rem;
  }
`;

const LinkButton = styled(Button)`
  && {
    margin: 10px 0px;
  }
`;

const cardDefinition: CardDefinition = {
  header: (item: Project) => (
    <CardTitle>
      <Link to={`/qa/${item.Name.toLocaleLowerCase()}`}>{item.Name}</Link>
    </CardTitle>
  ),
  sections: []
};

const CreateLinkButton = (text: string, project: string, disabled: boolean) => (
  <Link to={`/qa/${project.toLocaleLowerCase()}`}>
    <LinkButton disabled={disabled} text={text} variant="normal" />
  </Link>
);

const QAProjectsView = (props: Props) => {
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [selectedProject, setSelectedProject] = useState<any | null>(null);

  useEffect(() => {
    const getProjects= async () => {
        const idToken = getIdentityToken(props.user.cognitoUser);
        const projects = await getQAProjects(idToken, props.stage);
        setProjects(projects)
    }
    getProjects(); 
  }, []);

  const handleSelectionChange = (
    item: CustomEvent<TableSelection.SelectionChangeDetail<Project>>
  ) => {
    setSelectedProject(item.detail.selectedItems[0])
  };

  const renderActionStripe = () => (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>
          Projects
          <TableCounter>{` (${projects.length})`}</TableCounter>
        </h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        {selectedProject
          ? CreateLinkButton(
              "View datasets",
              selectedProject.Name,
              false
            )
          : CreateLinkButton("View datasets", "", true)}
      </div>
    </div>
  );

  return (
    <div>
      <PageTitle title="Projects" />
      <PageSubtitle content="List of all projects available for QA" />
      <Cards
        header={renderActionStripe()}
        cardDefinition={cardDefinition}
        items={projects}
        loadingText="Loading resources"
      >
        <CardsSelection
          onSelectionChange={handleSelectionChange}
          selectionType="single"
        />
        <CardsFiltering filteringPlaceholder="Find projects"></CardsFiltering>
        <CardsPagination pageSize={MAX_PAGE_SIZE}></CardsPagination>
      </Cards>
    </div>
  );
};

export default QAProjectsView;
