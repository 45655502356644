import {WorkflowAttrs} from './Interfaces'
import {IAction} from "../../Utils/Marvin_Wizard/interfaces";
import {WorkflowConfigs, StepMapping} from './Config'

export const  variant: ("icon" | "normal" | "primary" | "link") = "primary"

export const getActions = (
    index: number,
    totalStep: number,
    toStep: (step: number) => void,
    canMoveForward: boolean
): IAction[] => {
    const actions = []

    if(index > 0) {
        actions.push({
            id: `action_prev`,
            variant: variant,
            text: 'Previous',
            onClick: (e: any) => {
                toStep(index - 1)
            }
        })
    }

    if(index < totalStep - 1) {
        actions.push({
            id: `action_next`,
            variant: variant,
            text: 'Next',
            disabled: !canMoveForward,
            onClick: (e: any) => {
                toStep(index + 1)
            }
        })
    }
    return actions
}

export const checkStepDone = (
    indexStep: number,
    currData: WorkflowAttrs
) => {
    let canMoveForward = true
    const values = StepMapping[indexStep].getValues(currData)
    Object.keys(values).forEach(
        (keyCheck: string) => {
            const  valueItem = currData.hasOwnProperty(keyCheck) ? currData[keyCheck] : '',
                attrs = values[keyCheck];
            let passedValidation = true;
            (attrs.validation || []).forEach(
                (validation) => {
                    if(
                        valueItem &&
                        !validation.pattern.exec(valueItem)
                    ) {
                        passedValidation = false
                    }
                }
            )
            if(
                valueItem ? !passedValidation : values[keyCheck].required
            ) {
                canMoveForward = false
            }
        }
    )
    return canMoveForward
}

export const getRequestJSON = (
    data: WorkflowAttrs
): {
    [attr: string]: any
} => {
    const currWorkflow = WorkflowConfigs[data.workflowType]
    const typeOverrides = currWorkflow.annotationTypeAttrs.reduce(
        (resp, item) => {
            const attrKey = item.id,
            annotationTypes = data.annotationTypes || {}
            return {
                ...resp,
                [attrKey]: Object.keys(annotationTypes).reduce(
                    (respAttr, aType) => {
                        return {
                            ...respAttr,
                            [aType]: annotationTypes[aType][attrKey]
                        }
                    },
                    {}
                )
            }
        },
        {}
    );
    return {
        "WorkflowId": "WorkflowRequestFromThunder",
        "StepName": "WorkflowRequestFromThunder",
        "Payload": [
            {
                "Workflow": "BatchTextractWorkflow",
                "Input": {
                    "BatchName": data.workflowBatchName,
                    "DatasetId": data.datasetId,
					"AssetIngestion": {
                        "Feature": currWorkflow.valueFeature,
                        "ReuseExistingDataset": data.reuseExistingDataset,
                        "Source": {
                            "Account": data.ingestionAccount,
                            "Bucket": data.s3Bucket,
                            "Prefix": data.s3Prefix || ''
                        },
                        "Metadata": {
                            "Tags": [],
                            "Source": data.source
                        }
                    },
                    "AssetAnnotation": {
                        "Name": "Textract",
                        "Input": {
                            "IsRectified": data.isRectified,
                            "AllowRerun": data.allowRerun,
                            "AnnotationOverrides": {
                                "Features": data.features,
                                ...typeOverrides
                            }
                        }
                    },
                    "BatchRuntimeConfig": {
                        "SuccessPercentage": 90,
                        "Concurrency": 5
                    }
                }
            }
        ]
    }
}

export const getDefaultFormData = (stage: string) => ({
    stage,
    isRectified: false,
    assetSourceType: 'datasetId'
})
