import React, { useState, useEffect, Fragment } from "react";
import PageTitle from "../Components/PageTitle";
import Observer from "../Components/IntersectionObserver";
import MetadataContainer from "../Components/MetadataContainer";
import Button from "@amzn/awsui-components-react/polaris/button";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import PageLoader from "../Components/PageLoader";
import * as Styled from "../Components/IdentityDetails/StyledComponents";
import FaceImages from "../Components/IdentityDetails/FaceImages";
import { set } from "lodash";
import {
  emptyIdentityData,
  parseIdentityMetadata,
  calculateNextIndex,
  calculatePrevIndex,
  updateUrlHashParams,
  getParsedUrlHashParams,
  Metadata
} from "../Components/IdentityDetails/utils";
import { Filters } from "../Components/IdentityDetails/filterConfig";
import {
  getQAIdentityData,
  getQAIdentityImageAssets,
  postQAResults
} from "../API/Api";
import { getIdentityToken } from "../Utils/UserUtils";
import { debounce } from "lodash";
import {
  ParsedIdentityData,
  ParsedIdentityDataItem,
  ParsedIdentityAssets,
  ParsedIdentityAsset,
  PostResults
} from "../API/Parsers/Types";
import IdentityImagesFilter from "../Components/IdentityDetails/IdentityImagesFilter";

const FACE_TASK_TYPE = 'Face';
const REFERENCE_IMAGE_TASK_TYPE = 'ReferenceImage';
const ANNOTATION_TYPE = 'LabelVerification';

type Props = {
  stage: string;
  user: any;
};

const IdentityDetailsView = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [identityId, setIdentityId] = useState("");
  const [identityData, setIdentityData] = useState<ParsedIdentityDataItem>({
    label_id: "",
    name: "",
    date_of_birth: "",
    population_group: "",
    gender: "",
    occupation: "",
    url: "",
    verified_population_group: "",
    verified_gender: ""
  });
  const [metadata, setMetadata] = useState<Metadata>([]);
  const [showMetadata, setShowMetadata] = useState(false);
  const [referenceImages, setReferenceImages] = useState<ParsedIdentityAssets>([]);
  const [images, setImages] = useState<ParsedIdentityAssets>([]);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [executionId, setExecutionId] = useState("");
  const [continuationToken, setContinuationToken] = useState("");
  const [filters, setFilters] = useState<Filters>({});
  const [showFilterDropdown, setShowFilterDropdown] = useState<
    boolean | string
  >(false);
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [markedImages, setMarkedImages] = useState<string[]>([]);
  const [imageIndex, setImageIndex] = useState(0);

  const onCompleteQAClick = async () => {
    setLoading(true);
    const payload: PostResults = { identityId, assets: markedImages };
    const idToken = getIdentityToken(props.user.cognitoUser);
    await postQAResults(payload, idToken, props.stage);
    window.location.hash = "qa/faces";
  };

  const onFilterSelect = (value: string, key: string) => {
      setFilters(Object.assign({ ...filters }, Object.assign(filters, set({}, key, value))));
  }

  const isImageMarked = ({ label_id }: ParsedIdentityAsset) =>
    markedImages.indexOf(label_id) > -1;

  const onImageMark = (
    e: CustomEvent<Checkbox.ChangeDetail>,
    { label_id }: ParsedIdentityAsset
  ) =>
    e.detail.checked
      ? setMarkedImages([...markedImages, label_id])
      : setMarkedImages(markedImages.filter(id => id !== label_id));

  const getIdentityData = async () => {
    const idToken = getIdentityToken(props.user.cognitoUser);
    const data = await getQAIdentityData(idToken, {
      query_from: 0, 
      return_size: 10,
      task_type: FACE_TASK_TYPE,
      annotation_type: ANNOTATION_TYPE
    }, props.stage);
    let index = 0;
    for(index = 0; index < data.length; index++){
      if(data[index].label_id === identityId){
        break;
      }
    }
    setIdentityData(data[index]);
    setMetadata(parseIdentityMetadata(data[index]));
    setLoading(false);
  };

  const getImages = async (task_type: string, setImagesFn: Function, query_from: number = 0, return_size: number = 10) => {
    const idToken = getIdentityToken(props.user.cognitoUser);
    const data = await getQAIdentityImageAssets(idToken, {
      query_from,
      return_size,
      task_type,
      annotation_type: ANNOTATION_TYPE
    }, props.stage);
    setImagesFn(data);
  };

  const debouncedGetImages = debounce(getImages, 100);

  useEffect(function onMount() {
    getParsedUrlHashParams().then(
      ({ id, params }) => {
        setIdentityId(id)
      }
    );
  }, []);

  useEffect(
    function watchIdentityId() {
      if (identityId){
        getIdentityData();
        getImages(REFERENCE_IMAGE_TASK_TYPE, setReferenceImages);
      }
    },
    [identityId]
  );

  useEffect(
    function watchFilters() {
      setContinuationToken("");
      setShowFilterDropdown(false);
      setImages([]);
      updateUrlHashParams(filters);
    },
    [filters]
  );

  const renderReferenceImages = () => {
    if(!referenceImages || referenceImages.length === 0) return;

    return (
      <Styled.PolarisContainer
        header={"Reference images"}
        style={{ width: "100%" }}
        content={
          <div>
            <Styled.ReferenceImagesContainer>
              {referenceImages.map((referenceImage, i) => (
                <img
                  src={referenceImage.url}
                  style={{ height: "100%", width: "100%", objectFit: "contain" }}
                  key={i}
                />
              ))}
            </Styled.ReferenceImagesContainer>
          </div>
        }
      />
    );
  };

  return (
    <Fragment>
      {(loading && <PageLoader />) || (
        <Styled.Container>
        {
          <div className="title">
            <PageTitle title={identityData.name} />
          </div>
        }
          <Styled.LeftPanel>
            <MetadataContainer
              header={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <h2>Identity Details</h2>
                  <div
                    onClick={() => setShowMetadata(!showMetadata)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon name={showMetadata ? "angle-up" : "angle-down"} />
                  </div>
                </div>
              }
              metadata={showMetadata ? metadata : undefined}
            />
            <Styled.StickyContainer>
              {renderReferenceImages()}
            </Styled.StickyContainer>
          </Styled.LeftPanel>
          <Styled.RightPanel>
            <Fragment>
              <div className="awsui-util-container">
                <div
                  className="flex-space-between"
                  style={{ marginBottom: "-1.5rem" }}
                >
                  <h2>
                    Images of {identityData.name}{" "}
                    {imagesLoading ? "" : `(${images.length})`}
                  </h2>
                  <Button
                    text="Complete QA"
                    onClick={onCompleteQAClick}
                    variant="primary"
                    disabled={imagesLoading}
                  />
                </div>
                <IdentityImagesFilter
                  loading={imagesLoading}
                  name={identityData.name}
                  count={images.length}
                  filters={filters}
                  showDropdown={showFilterDropdown}
                  openDropdown={key => setShowFilterDropdown(key)}
                  onFilterChange={(e, key) => {
                    onFilterSelect(e.detail.id, key)}
                  }
                  onArrayValueChange={(value, key) => {
                    setFilters(Object.assign({ ...filters }, Object.assign(filters, set({}, key, value))));
                  }}
                  onDateChange={(e, key) =>
                    setFilters(
                      Object.assign(filters, set({}, key, e.detail.value))
                    )
                  }
                  onInputChange={(e: any, key) => {
                    setFilters(
                      Object.assign(filters, Object.assign(filters, set({}, key, e.detail.value)))
                    )
                  }}
                  onClearClick={() => setFilters({})}
                  onSubmit={setFilters}
                  onDismiss={() => setShowFilterDropdown(false)}
                />
              </div>
              <FaceImages 
                images={images} 
                isImageMarked={isImageMarked} 
                getImages={getImages}
                onImageMark={onImageMark}
                setImages={setImages} />
              <div style={{ textAlign: "center" }}>
                {imagesLoading && <PageLoader />}
              </div>
            </Fragment>
          </Styled.RightPanel>
        </Styled.Container>
      )}
    </Fragment>
  );
};

export default IdentityDetailsView;

