import React, { useState } from "react"
import styled from "styled-components"
import { Annotation } from "./arenaParser"
import { BBox } from "@amzn/barometer"
import { Checkbox, ExpandableSection } from "@amzn/awsui-components-react"
import TableCounter from "../Components/TableCounter"
import { ColorGenerator } from "../Utils/colors"
import queryString from "query-string"

const UnselectedLabelColor = "#979797"

interface Props {
  originalImage: {
    url: string
    name: string
  }
  annotations: Annotation[],
  datasetColorMap: { [type: string]: string }
}

const CheckboxGroup = styled.div``

const DisclaimerDiv = styled.div`
    font-size: 12px;
    line-height: 1.4em;
`;

type ColorProps = { color: string }
const CheckboxContainer = styled.div`
  background-color: ${(props: ColorProps) => `${props.color}33`};
  border: 1px solid ${(props: ColorProps) => props.color};
  border-radius: 10px;
  padding: 5px 10px;
  margin: 1rem 0;
  cursor: pointer;
  display: flex;

  :hover {
    background-color: ${(props: ColorProps) => `${props.color}66`};
  }
`

const CheckboxLabel = styled.span`
  margin: 0 5px;
  word-break: break-word;
`

const findAllUniqueAnnotationTypes = (annotations: Annotation[]) => {
  return Array.from(
    new Set(annotations.map(annotation => annotation.labelName))
  ).sort()
}

const findAllUniqueAnnotationHierarchies = (annotations: Annotation[]) => {
  return Array.from(
    new Set(annotations
      .filter(annotation => annotation.label === "kv_hierarchies")
      .map(annotation => annotation.labelName))
  );
}

type Legend = {
  [string: string]: string
}

const createLegend = (
  annotationTypes: Array<string>,
  colorMap: { [type: string]: string }
) => {
  let legend: Legend = {}
  let colorGenerator = new ColorGenerator()
  annotationTypes.forEach(type => {
    legend[type] = colorMap[type] || colorGenerator.next()
  })
  return legend
}

const initialState = (annotationTypes: Array<string>) => {
  let visible: { [string: string]: boolean } = {}
  annotationTypes.forEach(type => {
    visible[type] = false
  })

  // Set the first label to be visible by default
  visible[Object.keys(visible)[0]] = true
  return visible
}

const AnnotationViewer = ({
  originalImage,
  annotations,
  datasetColorMap
}: Props) => {
  const annotationTypes = findAllUniqueAnnotationTypes(annotations)
  const annotationHierarchies = findAllUniqueAnnotationHierarchies(annotations)
  const legend = createLegend(annotationTypes, datasetColorMap)

  const [visibleState, setVisibleState] = useState(
    initialState(annotationTypes)
  )

  const dimensions = {
    width: 100,
    height: 100
  };

  return (
    <div className="awsui-grid">
      <div className="awsui-row">
        <div className="col-8">
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <h2>{originalImage.name}</h2>
            </div>
            <BBox
              asset={{
                URL: originalImage.url,
                type: "Image",
                dimensions: dimensions
              }}
              legend={legend}
              annotations={annotations.filter(
                annotation => visibleState[annotation.labelName]
              )}
              config={{
                boxStrokeWidth: 3,
                showLegend: false
              }}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <h2>
                Labels{" "}
                <TableCounter>{` (${annotationTypes.length - annotationHierarchies.length})`}</TableCounter>
              </h2>
            </div>
            <CheckboxGroup>
              {annotationTypes.filter((annotationType) => {
                return !annotationHierarchies.includes(annotationType)
              }).map(type => {
                return (
                  <CheckboxContainer
                    key={type}
                    color={
                      visibleState[type] ? legend[type] : UnselectedLabelColor
                    }
                    onClick={() =>
                      setVisibleState({
                        ...visibleState,
                        [type]: !visibleState[type]
                      })
                    }
                  >
                    <Checkbox
                      key={type}
                      checked={visibleState[type]}
                      onChange={event =>
                        setVisibleState({
                          ...visibleState,
                          [type]: event.detail.checked
                        })
                      }
                    ></Checkbox>
                    <CheckboxLabel>{type}</CheckboxLabel>
                  </CheckboxContainer>
                )
              })}
            </CheckboxGroup>
            {annotationHierarchies.length && <ExpandableSection header="KEY/VALUE/KVC by Hierarchies">
              <CheckboxGroup>
                {annotationHierarchies.map((type) => {
                  return (
                    <CheckboxContainer
                      key={`depth-${type}`}
                      color={
                        visibleState[type] ? legend[type] : UnselectedLabelColor
                      }
                      onClick={() =>
                        setVisibleState({
                          ...visibleState,
                          [type]: !visibleState[type]
                        })
                      }
                    >
                      <Checkbox
                        key={`depth-${type}`}
                        checked={visibleState[type]}
                        onChange={event =>
                          setVisibleState({
                            ...visibleState,
                            [type]: event.detail.checked
                          })
                        }
                      ></Checkbox>
                      <CheckboxLabel>{type}</CheckboxLabel>
                    </CheckboxContainer>
                  )
                })}
              </CheckboxGroup>
              <DisclaimerDiv>Selecting individual hierarchies for visualization does not reflect current Product/Science definition of HKV levels. Product/Science definition includes L0, L1, L2, and L2+ for all higher KV levels. In this visualization KEY_X means Xth level in the HKV tree. Level X will show all the KVs at that level, X-1 will show the parents of X level KVs, etc.</DisclaimerDiv>
            </ExpandableSection>
            || ""}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnotationViewer
