import React from "react";
import styled from "styled-components";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

type feature = {
  name: string;
  dateAdded: string;
};

type Features = Array<feature>;

const StyledFeatureDate = styled.span`
  float: right;
`;

export const NewFeaturesTable = () => {
  /* When new features are added to Thunder, add an entry here with the name of the feature and date added */
  const newFeatures: Features = [
    { name: "QA Tool", dateAdded: "December 2019"},
    { name: "Annotation Upload Tool", dateAdded: "December 2019"},
    { name: "Homepage", dateAdded: "November 2019" },
    { name: "Textract Annotation Legend", dateAdded: "September 2019" },
    { name: "S3 Visualizer", dateAdded: "July 2019" }
  ];
  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <h4>New features added</h4>
      </div>
      <ColumnLayout columns={1} borders="horizontal">
        <div data-awsui-column-layout-root="true">
          {newFeatures.map((feature, i) => (
            <div key={i}>
              <span>{feature.name}</span>
              <StyledFeatureDate className="awsui-util-status-inactive awsui-util-font-size-0">
                {feature.dateAdded}
              </StyledFeatureDate>
            </div>
          ))}
        </div>
      </ColumnLayout>
    </div>
  );
};
