import React from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import "./App.css";
import Router from "./Routes/Router";
import DynamicBreadcrumbs from "./Components/DynamicBreadcrumbs";
import SideNavigation from "./Components/SideNavigation";

const DEFAULT_LAYOUT_SETTINGS = {
  navigationOpen: false,
  toolsHide: true
};

const setAppLayoutClassNames = (pathname: string) => {
  const gutters = pathname !== "/" && pathname !== "/cluster";
  return `${gutters ? "" : "awsui-util-no-gutters"} ${pathname.substring(1)}`;
};

type Props = {
  location: {
    pathname: string;
  };
};

const App = (props: Props) => {
  const {
    location: { pathname }
  } = props;
  return (
    <AppLayout
      className={setAppLayoutClassNames(pathname)}
      content={<Router />}
      navigation={<SideNavigation pathname={pathname} />}
      breadcrumbs={
        pathname !== "/" && <DynamicBreadcrumbs pathname={pathname} />
      }
      navigationOpen={DEFAULT_LAYOUT_SETTINGS.navigationOpen}
      toolsHide={DEFAULT_LAYOUT_SETTINGS.toolsHide}
    />
  );
};

export default App;
