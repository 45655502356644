import React, {Fragment, useState} from 'react'
import PageTitle from '../Components/PageTitle'
import PageSubtitle from '../Components/PageSubtitle'
import Tabs from '@amzn/awsui-components-react/polaris/tabs'
import Flash from '@amzn/awsui-components-react/polaris/flash'
import Icon from '@amzn/awsui-components-react/polaris/icon'
import styled from 'styled-components'
import WorkflowTriggerJson from '../Components/WorkflowTriggerJson'
import WorkflowTriggerForm from '../Components/WorkflowTriggerForm'
import { triggerWorkflowCreation} from '../API/Api'
import { CognitoUser } from '@aws-amplify/auth';
import { getIdentityToken } from '../Utils/UserUtils';

const JARVIS_ACCOUNT: {[stage: string]: string} = {
    dev: "acdc_workflow_beta",
    beta: "acdc_workflow_beta",
    prod: "acdc_workflow_fe"
}

const getFormatedCurrentDate = () => {
    const now = new Date(),
        d = now.getDate() - 1,
        m = now.getMonth() + 1,
        y = now.getFullYear()
    return `${y}-${m}-${d}T00:00:00.000Z`
}

const getWorkflowReviewUrlJarvis = (stage: string, datasetId: string) => `https://jarvis-console.amazon.com/domains/${JARVIS_ACCOUNT[stage]}/processes?start_time=${getFormatedCurrentDate()}&q=${datasetId}&sort=start_time%20desc`

const getWorkflowReviewUrlIsengard = (arn: string) => `https://us-west-2.console.aws.amazon.com/states/home?region=us-west-2#/executions/details/${arn}`

type Props = {
    stage: string,
    user: {
      cognitoUser: CognitoUser
    }
}

type Notification = {
    type: 'success' | 'error' | undefined,
    message: any
} | null

const LinkFlash = styled.a`
    margin-left: 15px;
`

export default (props: Props) => {
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [currTabId, setCurrTabId] = useState<string>('form')
    const [notification, setNotification] = useState<Notification>(null)

    //actions need to be take after the workflow trigger request is done
    const actionRequestFinished = (
        resp: {
            succeed: boolean,
            errorMsg?: string,
            workflowArn?: string
        },
        json: {[key: string]: any},
        extraActions?: () => void
    ) => {
        const type = resp.succeed ? 'success' : 'error',
            message = resp.succeed ? 
            <Fragment>
                <span>Workflow created successfully</span>
                {resp.workflowArn &&
                <LinkFlash 
                    href={getWorkflowReviewUrlIsengard(resp.workflowArn)}
                    target="_blank"
                    rel="noopener noreferrer"
                >Click to View in Isengard <Icon name="external" /></LinkFlash>
                }
                <LinkFlash 
                    href={getWorkflowReviewUrlJarvis(props.stage, json.Payload[0].Input.DatasetId)}
                    target="_blank"
                    rel="noopener noreferrer"
                >Click to View in Jarvis <Icon name="external" /></LinkFlash>
            </Fragment> : resp.errorMsg
        setNotification({
            type,
            message
        })
        setSubmitting(false)
        extraActions && extraActions()
    }

    // function to trigger the workflow creation
    const triggerWorkflow = (
        json: object,
        extraActions?: () => void
    ) => {
        if(json) {
            const idToken = getIdentityToken(props.user.cognitoUser)
            const onRequestFinished = (resp: {
                succeed: boolean,
                errorMsg?: string
            }) => {
                actionRequestFinished(resp, json, extraActions)
            }
            triggerWorkflowCreation(
                json,
                onRequestFinished,
                idToken,
                props.stage
            )
            setSubmitting(true)
            setNotification(null)
        }else{
            setSubmitting(false)
            setNotification({
                type: 'error',
                message: 'The input is not a valid JSON'
            })
        }
    }

    const message = notification && <Flash
        content={notification.message}
        type={notification.type}
        dismissible={true}
        visible={true}
    ></Flash>

    const tabs = [
        {
            'label': 'Form',
            'id': 'form',
            'content': <Fragment>
                {message}
                <WorkflowTriggerForm
                    stage={props.stage}
                    submitting={submitting}
                    actionSubmit={triggerWorkflow}
                />
            </Fragment>
        },
        {
            'label': 'JSON',
            'id': 'json',
            'content': <Fragment>
                {message}
                <WorkflowTriggerJson
                    stage={props.stage}
                    submitting={submitting}
                    actionSubmit={triggerWorkflow}
                />
            </Fragment>
        }
    ]

    const handleTabChange = (e:any) => {
        setCurrTabId(e.detail.activeTabId)
    }

    return <div>
        <PageTitle title="Workflows"/>
        <PageSubtitle content="Rekognition annotation workflow Mangement"/>
        <Tabs
            tabs={tabs}
            activeTabId={currTabId}
            onChange={handleTabChange}
        />
    </div>
}
