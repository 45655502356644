import React, { useState, useEffect, SyntheticEvent } from 'react'
import PageTitle from '../Components/PageTitle'
import PageSubtitle from '../Components/PageSubtitle'
import AnimatedTable from '../Components/AnimatedTable'
import TableFiltering from '@amzn/awsui-components-react/polaris/table-filtering'
import TablePagination from '@amzn/awsui-components-react/polaris/table-pagination'
import TableSelection from '@amzn/awsui-components-react/polaris/table-selection'
import TableSorting from '@amzn/awsui-components-react/polaris/table-sorting'
import Button from '@amzn/awsui-components-react/polaris/button'
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import { generateAssetLink } from '../Routes/RouteConfig'
import { getAssetsForDataset, getDataset, Dataset, Url, getAssetImages, emptyDataset } from '../API/Api'
import EmptyTableView from '../Components/EmptyTableView'
import styled from 'styled-components'
import ImageGrid from '../Components/ImageGrid'
import ImageModal, { Dimensions } from '../Components/ImageModal'
import {stripFilenameFromS3Path} from '../Utils/StringUtils'
import queryString from 'query-string'
import { numberToString, bytesToString } from '../Utils/StringUtils'
import { CognitoUser } from '@aws-amplify/auth';
import { getIdentityToken } from '../Utils/UserUtils';

const PAGE_SIZE = 50

type Props = {
    match: {
        params: {
          project: string,
          dataset: string
        },
        url: string,
    },
    location: {
      data: Dataset
    },
    stage: string,
    user: {
      cognitoUser: CognitoUser
    }
}

type S3Object = {
  Key: string,
  LastModified: string,
  Size: number,
}

type item = {
    name: string,
    key: string,
    lastModified: string,
    size: number
}

type OptionProps = {
  isActive: boolean
}

const VIEW_MODE = {
  GALLERY: "GALLERY",
  TABLE: "TABLE",
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ActionBar = styled.div`
    margin: 2rem; 
`

const OptionContainer = styled.div`
  padding: 0.5rem;
`
const Option = styled.span`
  cursor: pointer;
  color: #0073ba;
  font-size: 1.5rem;
  padding: 0px 10px;
  font-weight: ${(props: OptionProps) => props.isActive ? 800 : 500};
  :hover {
    text-decoration: underline;
  }
`

const createColumnDefinitions = (url: string) => [
  {
    id: "name",
    header: "Asset name",
    cell: (item: item) => generateAssetLink(url, item.name, item.key)
  },
  {
    id: "size",
    header: "Size",
    cell: (item: item) => bytesToString(item.size)
  },
  {
    id: "modified",
    header: "Last modified",
    cell: (item: item) => new Date(item.lastModified).toLocaleDateString()
  }
]

const transformAssetsToTableEntries = (assets: Array<S3Object>): Array<item> => {
  return assets ? 
    assets.map(s3Object => ({
      name: s3Object.Key.split('/').pop() || "Bad s3 key",
      key: s3Object.Key,
      size: s3Object.Size,
      lastModified: s3Object.LastModified,
    })) : []
}

const renderDatasetDetails = (dataset: Dataset) => (
  <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Dataset details</h2>
        </div>
        <ColumnLayout columns={3} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          <div>
            <div className="awsui-util-spacing-v-s">
              <div>
                <div className="awsui-util-label">Dataset type</div>
                <div>{dataset.DatasetType}</div>
              </div>
              <div>
                <div className="awsui-util-label">Exported on</div>
                <div>{new Date(dataset.ExportedOn).toLocaleDateString()}</div>
              </div>
            </div>
          </div>
          <div>
            <div className="awsui-util-spacing-v-s">
              <div>
                <div className="awsui-util-label">Annotation version</div>
                <div>{dataset.AnnotationVersion}</div>
              </div>
              <div>
                <div className="awsui-util-label">Asset count</div>
                <div>{numberToString(dataset.Count)}</div>
              </div>
            </div>
          </div>
          <div>
            <div className="awsui-util-spacing-v-s">
              <div>
                  <div className="awsui-util-label">{dataset.SyntheticData ? "Synthetic data type" : "Is data synthetic?"}</div>
                  <div>{dataset.SyntheticData ? dataset.SyntheticType : dataset.SyntheticData.toString()}</div>
              </div>
              <div>
                <div className="awsui-util-label">Annotation types</div>
                <div>{dataset.AnnotationTypes.join(", ")}</div>
              </div>
            </div>
          </div>
        </div>
      </ColumnLayout>
    </div>
)

const renderGallery = (images: Array<Url>, onImageClick: (image: Url, index: number, event: SyntheticEvent) => void, isLoading: boolean, hasMore: boolean, loadMoreAssets: () => void) => (
  <ImageGrid 
    images={images} 
    onImageClick={onImageClick}
    hasMore={hasMore}
    isLoading={isLoading}
    onScrollEnd={loadMoreAssets} />
)

const renderTable = (pageUrl: string, isLoading: boolean, assets: Array<S3Object>) => (
  <AnimatedTable
    loading={isLoading}
    loadingText="Loading assets"
    columnDefinitions={createColumnDefinitions(pageUrl)}
    items={transformAssetsToTableEntries(assets)}
    wrapLines={false}
    empty={<EmptyTableView itemType="assets" />}>
    <TableFiltering filteringPlaceholder="Find assets" />
    <TablePagination pageSize={PAGE_SIZE} />
    <TableSorting
        sortableColumns={[
            { id: "name", field: "name" },
            { id: "size", field: "size" },
            { id: "modified", field: "modified" },
        ]}
    />
    <TableSelection />
  </AnimatedTable>
)

const renderModalActionBarComponent = (currentUrl: string, currentImage: Url) => () => {
  if(!currentImage) return <div></div>
  const assetName = stripFilenameFromS3Path(currentImage.name)
  const key = currentImage.name
  const params = queryString.stringify({key: key})
  const url = `${window.location.origin}#${currentUrl}/assets/${assetName}?${params}`
  const navigate = () => {
    window.open(url)
  }
  return <ActionBar>
        <Button text="View annotations" variant="normal" onClick={navigate} icon="external" />
  </ActionBar>
}

const calculateNextIndex = (index: number, numImages: number) => (
  index + 1 < numImages ? index + 1 : numImages - 1
)
const calculatePrevIndex = (index: number) => (
  index - 1 >= 0 ? index -1 : 0
)

const DatasetView = (props: Props) => {
  const datasetName = props.match.params.dataset
  const project = props.match.params.project
  const pageUrl = props.match.url
  const [isLoading, setIsLoading] = useState(true)
  const [dataset, setDataset] = useState<Dataset>(emptyDataset)
  const [assets, setAssets] = useState({ Contents: [] })
  const [images, setImages] = useState<Array<Url>>([])
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [continuationToken, setContinuationToken] = useState<string>("")
  const [viewMode, setViewMode] = useState(VIEW_MODE.GALLERY)
  const [showModal, setShowModal] = useState(false)
  const [imageClicked, setImageClicked] = useState<Dimensions>({x: 0, y: 0, width: 0, height: 0})
  
  const isGalleryView = viewMode === VIEW_MODE.GALLERY
  const isTableView = viewMode === VIEW_MODE.TABLE
  const loadMoreAssets = () => setIsLoading(true)
  const onCloseModal = () => setShowModal(false)
  const onMoveNextRequest = () => setImageIndex(calculateNextIndex(imageIndex, images.length))
  const onMovePrevRequest = () => setImageIndex(calculatePrevIndex(imageIndex))
  const onImageClick = (image: Url, index: number, event: SyntheticEvent) => {
    setShowModal(true)
    setImageIndex(index)

    const box = event.currentTarget.getBoundingClientRect()
    setImageClicked({
      x: box.left,
      y: box.top,
      width: box.width,
      height: box.height,
    })
  }

  const switchViewMode = (newViewMode: string) => () => {
    if(newViewMode !== viewMode) {
      setIsLoading(true)
      setViewMode(newViewMode)
    }
  }

  useEffect(function getActiveImageDimensions() {
    if(imageIndex < 0 || imageIndex >= images.length) return

    const activeImage = images[imageIndex]
    const image = document.getElementById(activeImage.name)
    if(image) {
        const box = image.getBoundingClientRect()
        setImageClicked({
          x: box.left,
          y: box.top,
          width: box.width,
          height: box.height,
        })
    }
  }, [imageIndex])

  useEffect(() => {
    const getDatasetDetails = async () => {
      const idToken = getIdentityToken(props.user.cognitoUser)
      const datasetDetails = await getDataset(project, datasetName, idToken, props.stage)
      setDataset(datasetDetails)
      setIsLoading(false)
    }
    
    getDatasetDetails()
  }, [])

  useEffect(() => {
    const getAssets = async () => {
      const idToken = getIdentityToken(props.user.cognitoUser)
      const assets = await getAssetsForDataset(dataset, idToken, props.stage)
      setAssets(assets)
      setIsLoading(false)
    }

    if(isTableView && assets.Contents.length === 0) { getAssets() } 
    else { setIsLoading(false) }

  }, [viewMode])

  useEffect(() => {
    const getImages = async () => {
      const {project, dataset} = props.match.params
      const idToken = getIdentityToken(props.user.cognitoUser)
      const datasetDetails = await getDataset(project, dataset, idToken, props.stage)
      const imagePath = `${datasetDetails.S3Path}/ImageAssets`
      const nextBatch = await getAssetImages(imagePath, idToken, props.stage, continuationToken)
      setImages([
        ...images,
        ...nextBatch.Images
      ])
      setContinuationToken(nextBatch.ContinuationToken || "")
      setIsLoading(false)
    }

    if(isLoading && isGalleryView) {
      getImages()
    }
  }, [isLoading])

  const renderAssetView = (viewMode: string) => {
    switch(viewMode) {
      case VIEW_MODE.GALLERY:
        return renderGallery(images, onImageClick, isLoading, continuationToken !== "", loadMoreAssets)
      case VIEW_MODE.TABLE:
        return renderTable(pageUrl, isLoading, assets.Contents)
      default:
        return renderGallery(images, onImageClick, isLoading, continuationToken !== "", loadMoreAssets)
    }
  }
  
  return (
    <div>
      <PageTitle title={datasetName} />
      <Row>
        <PageSubtitle content={`Details and assets for ${datasetName}`} />
        <OptionContainer>
          <Option isActive={isGalleryView} onClick={switchViewMode(VIEW_MODE.GALLERY)}>Gallery view</Option>
          |
          <Option isActive={isTableView} onClick={switchViewMode(VIEW_MODE.TABLE)}>Table view</Option>
        </OptionContainer>
      </Row>
      {renderDatasetDetails(dataset)}
      {renderAssetView(viewMode)}
      <ImageModal
          showModal={showModal}
          image={images[imageIndex] ? {...images[imageIndex], name: stripFilenameFromS3Path(images[imageIndex].name)} : null}
          nextImage={images[calculateNextIndex(imageIndex, images.length)]}
          prevImage={images[calculatePrevIndex(imageIndex)]} 
          onDismiss={onCloseModal}
          onMoveNextRequest={onMoveNextRequest}
          onMovePrevRequest={onMovePrevRequest}
          currentImageNumber={imageIndex + 1}
          numberOfImages={images.length}
          dimensions={imageClicked}
          loadMoreImages={loadMoreAssets}
          ActionBar={renderModalActionBarComponent(pageUrl, images[imageIndex])} />
    </div>
    )
}

export default DatasetView