import { Element } from "./getWord";
import { Annotation } from "../arenaParser";
import { getAnnotationFromElement } from './getAnnotationFromElement';
import { getClickableItem } from './getClickableItem';
import { getTableCell } from './getTableCell';
import { getValue } from './getValue';
import { getWord } from './getWord';

const TABLE = "TABLE";
const SIGNATURE_LABEL_NAME = "Signature";
const SEGMENT = "SEGMENT";
const PRINTED_LABEL_NAME = "PRINTED";
const PARAGRAPH = "PARAGRAPH";
const LINE_LABEL_NAME = "LINE";
const KEY_VALUE_CONTAINER = "KEY_VALUE_CONTAINER"
const KEY = "KEY";
const HEADER = "HEADER";
const HANDWRITING_LABEL_NAME = "HANDWRITING";
const DOCUMENT_CONTENT_AREA = "DOCUMENT_CONTENT_AREA";

export { getWord, getValue, getTableCell, getClickableItem };

export const getLine = (element: Element): Annotation => {
    return getAnnotationFromElement(element, LINE_LABEL_NAME);
}

export const getKey = (element: Element): Annotation => {
    return getAnnotationFromElement(element, KEY);
}

export const getKeyValueContainer = (element: Element): Annotation => {
    return getAnnotationFromElement(element, KEY_VALUE_CONTAINER);
}

export const getTable = (element: Element): Annotation => {
    return getAnnotationFromElement(element, TABLE);
}

export const getContentArea = (element: Element): Annotation => {
    return getAnnotationFromElement(element, DOCUMENT_CONTENT_AREA);
}

export const getHandwriting = (element: Element): Annotation => {
    return getAnnotationFromElement(element, HANDWRITING_LABEL_NAME);
}

export const getPrinted = (element: Element): Annotation => {
    return getAnnotationFromElement(element, PRINTED_LABEL_NAME);
}

export const getSegmentation = (element: Element): Annotation => {
    return getAnnotationFromElement(element, SEGMENT)
}

export const getHeader = (element: Element): Annotation => {
    return getAnnotationFromElement(element, HEADER)
}

export const getParagraph = (element: Element): Annotation => {
    return getAnnotationFromElement(element, PARAGRAPH)
}

export const getSignature = (element: Element): Annotation => {
    return getAnnotationFromElement(element, SIGNATURE_LABEL_NAME)
}