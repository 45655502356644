import React from "react";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Button from "@amzn/awsui-components-react/polaris/button";
import styled from "styled-components";

type FormData = {
  id: string | null,
  name: string;
  gender: string;
  yearOfBirth: string;
  populationGroup: string;
};

const ListContainer = styled.div`
  .awsui-expandable-section-header-expanded.awsui-expandable-section-header-default:not([data-awsui-focused]) {
    padding-bottom: 1.5rem;
  }
`;

const Identity = styled.div`
  position: relative;
  border-bottom: 1px solid var(--awsui-color-border-container-default);
  .close {
    position: absolute;
    top: 1rem;
    right: 0;
  }
`;

const DetailContainer = styled.div`
  margin-bottom: 1rem;
  &:first-of-type {
    margin-top: 0.5rem;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const listHeader = ({ name, id }: FormData, disabled: boolean, onDelete: Function) => (
  <div>
    <div>{name}</div>
    <Button
      className={"close"}
      icon="close"
      iconAlt="Delete Image"
      variant="icon"
      disabled={disabled || false}
      onClick={(e: CustomEvent) => (e.stopPropagation(), onDelete(id))}
    />
  </div>
);

const listContent = (item: FormData) => (
  <div>
    <DetailContainer>
      <div className="awsui-util-label">Gender</div>
      <div>{item.gender}</div>
    </DetailContainer>
    <DetailContainer>
      <div className="awsui-util-label">Year of Birth</div>
      <div>{item.yearOfBirth}</div>
    </DetailContainer>
    <DetailContainer>
      <div className="awsui-util-label">Population Group</div>
      <div>{item.populationGroup}</div>
    </DetailContainer>
  </div>
);

export const IdentityList = (
  identities: Array<FormData>,
  identitiesInUse: Array<string>,
  onDeleteIdentity: (id: string) => void
) => (
  <ListContainer className="awsui-util-spacing-v-s">
    {identities.map((identity, i) => (

      <Identity key={i}>
        <ExpandableSection
          key={i}
          header={listHeader(identity, identitiesInUse.includes(identity.id!), onDeleteIdentity)}
          content={listContent(identity)}
        />
      </Identity>
    ))}
  </ListContainer>
);
