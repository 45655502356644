import React, {useEffect} from 'react'
import FormSection from '@amzn/awsui-components-react/polaris/form-section'
import {getActions, checkStepDone} from '../Utils'
import {StepConfig, WorkflowAttrs} from '../Interfaces'
import {IStep} from "../../../Utils/Marvin_Wizard/interfaces";
import {CheckboxField, TextField} from '../FormComponents'
import {WorkflowConfigs, formValueTypes} from '../Config'



const AdditionalConfig = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const currData = props.getData()
    const currWorkflowType = currData.workflowType

    if(currWorkflowType) {
        let defaultValues = {}
        const listAttrs = WorkflowConfigs[currWorkflowType].extraConfigs,
        listFormItems = listAttrs.map((item) => {
            if(item.type === formValueTypes.BOOLEAN) {
                if(!currData.hasOwnProperty(item.id)) {
                    defaultValues = {
                        ...defaultValues,
                        [item.id]: false
                    }
                }
                return <CheckboxField
                    key={item.id}
                    getData={props.getData}
                    setData={props.setData}
                    attrs={item}
                />
            }else if(item.type === formValueTypes.STRING){
                return <TextField
                    key={item.id}
                    getData={props.getData}
                    setData={props.setData}
                    attrs={item}
                />
            }else{
                // will add more if more type of config needs to be supported
                return null
            }
        })
        useEffect(() => {
            if(Object.keys(defaultValues).length > 0) {
                props.setData({
                    ...currData,
                    ...defaultValues
                })
            }
        })
        return <FormSection
            header="Select Workflow Type"
        >
            <div
                className="wrap-wizard-content"
                data-awsui-column-layout-root="true"
            >
                {listFormItems}
            </div>
        </FormSection>
    }else{
        return null
    }
}

export default (config: StepConfig): IStep => {
    const currData = config.getData()
    return {
        id: 'step_other',
        content: <AdditionalConfig
            getData={config.getData}
            setData={config.setData}
        />,
        title: 'Additional configuration',
        onClick: (stepIndex: number) => (e: any) => {
            config.toStep(stepIndex)
            console.log(e)
        },
        actions: getActions(
            config.index,
            config.totalStep,
            config.toStep,
            checkStepDone(config.index, currData)
        )
    }
}
