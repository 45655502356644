export default {
  WORD: "#8b0000",
  LINE: "#006400",
  KEY: "#9acd32",
  VALUE: "#ffd700",
  KEY_VALUE_CONTAINER: "#000080",
  CLICKABLE_ITEM_FALSE: "#ff0000",
  CLICKABLE_ITEM_TRUE: "#00ff00",
  TABLE: "#ff8c00",
  TABLE_CELL: "#ba55d3",
  SEGMENT: "#ffe4b5",
  DOCUMENT_CONTENT_AREA: "#696969"
}
