import React from 'react'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import Input from '@amzn/awsui-components-react/polaris/input'
import Icon from '@amzn/awsui-components-react/polaris/icon'
import Select from '@amzn/awsui-components-react/polaris/select'
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox'
import {WorkflowAttrs} from './Interfaces'
import styled from 'styled-components'

export interface ConfigFormField {
    name: string,
    id: string,
    required: boolean,
    options?: {
        id: string,
        label: string
    }[],
    validation?: Array<{
        pattern: RegExp,
        errorMsg: string
    }>
}

const WrapSection = styled.div`
    max-width: 75%;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px 30px;
`

const TextError = styled.div`
    margin-top: 8px;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    background: rgba(189,0,0);
    .wrapIcon {
        margin-right: 10px;
    }
`

const ErrorMsg = (props: {msg: string}) => {
    return <TextError>
        <span className="wrapIcon">
            <Icon name="status-negative"/>
        </span>
        {props.msg}
    </TextError>
}

export const CheckboxField = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void,
    attrs: ConfigFormField
}) => {
    const currData = props.getData()
    const handleCheckboxChange = (e: any) => {
        props.setData({
            ...currData,
            [props.attrs.id]: e.detail.checked
        })
    }
    return <FormField
            className="awsui-util-d-b awsui-util-mb-m"
        >
            <Checkbox
                label={props.attrs.name}
                onChange={handleCheckboxChange}
                checked={currData[props.attrs.id] || false}
                name={props.attrs.id}
            ></Checkbox>
        </FormField>
}

export const TextField = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void,
    attrs: ConfigFormField
}) => {
    const currData = props.getData()
    const handleInputChange = (e: any) => {
        props.setData({
            ...currData,
            [props.attrs.id]: e.detail.value
        })
    }
    let errorMsg = null;
    (props.attrs.validation || []).forEach(
        (validation) => {
            if(
                currData[props.attrs.id] &&
                !validation.pattern.exec(currData[props.attrs.id])
            ) {
                errorMsg = validation.errorMsg
            }
        }
    )
    return <FormField
            className="awsui-util-d-b awsui-util-mb-m"
            label={props.attrs.name}
        >
            <Input
                onChange={handleInputChange}
                value={currData[props.attrs.id]|| ""}
                name={props.attrs.id}
            />
            {
                errorMsg &&
                <ErrorMsg msg={errorMsg} />
            }
        </FormField>
}

export const TextSelector = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void,
    attrs: ConfigFormField
}) => {
    const currData = props.getData(),
        handleSelectorChange = (e: any) => {
            props.setData({
                ...currData,
                [props.attrs.id]: e.detail.selectedId
            })
        },
        options = props.attrs.options
    return (options && options.length > 0)?
        <FormField
            className="awsui-util-d-b awsui-util-mb-m"
            label={props.attrs.name}
        >
            <Select
                onChange={handleSelectorChange}
                selectedId={currData[props.attrs.id]}
                options={options}
            />
        </FormField> : null
}

export const FeatureSection = (props: {
    title: string,
    children: any
}) => {
    return <WrapSection>
        <h4>{props.title}</h4>
        <hr />
        <div className="feature__content">
            {props.children}
        </div>
    </WrapSection>
}
