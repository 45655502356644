import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type action = {
  text: string;
  route: string;
  externalLink?: string;
  iconSource: string;
};

type Actions = Array<action>;

const hoverColor = "var(--awsui-color-grey-100)";

const StyledActionCard = styled.div`
    height: 100%;
    && {
      :hover {
        background: ${hoverColor};
      }
    }
  `,
  StyledLink = styled(Link)`
    && {
      color: black;
      :hover {
        text-decoration: none;
      }
    }
  `,
  StyledExternalLink = styled.a`
    && {
      color: black;
      :hover {
        text-decoration: none;
      }
    }
  `;

export const Actions = () => {
  /* Add, edit, or remove any of the actions here to change the main action buttons at the top of the page */
  const actions: Actions = [
    {
      text: "Browse the data repository",
      route: "/projects",
      iconSource: ""
    },
    {
      text: "Start a data workflow",
      route: "/workflows/create",
      iconSource: ""
    },
    {
      text: "Visualize an S3 directory",
      route: "/visualizer",
      iconSource: ""
    },
    {
      text: "Track your data ingestion",
      route: "",
      externalLink: "https://amun-ra.aka.amazon.com/",
      iconSource: ""
    },
    {
      text: "View projects for QA",
      route: "/qa",
      iconSource: ""
    },
    {
      text: "Upload files",
      route: "/upload",
      iconSource: ""
    }
  ];
  return (
    <div className="awsui-util-mb-l">
      <h1>Here are some things you can do</h1>
      <div className="awsui-grid">
        <div className="awsui-row">
          {actions.map(action => (
            <div key={action.text} className="col-xxxs-12 col-xxs-6">
              {action.externalLink ? (
                <StyledExternalLink href={action.externalLink} target="_blank">
                  <StyledActionCard className="awsui-util-container">
                    <h3>{action.text}</h3>
                  </StyledActionCard>
                </StyledExternalLink>
              ) : (
                <StyledLink to={action.route}>
                  <StyledActionCard className="awsui-util-container">
                    <h3>{action.text}</h3>
                  </StyledActionCard>
                </StyledLink>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
