import React from 'react'

type Props = {
    itemType: string
}

const EmptyTableView = ({itemType}: Props) => (
    <div className="awsui-util-t-c">
        <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>{`No ${itemType}`}</b>
        </div>
        <p className="awsui-util-mb-s">
            {`No ${itemType} to display`}
        </p>
    </div>
)

export default EmptyTableView