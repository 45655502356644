import { Annotation } from "../arenaParser"
import { getGeometry } from "./getGeometry"
import { ClickableItemElement } from "../arenaSchema"

const CLICKABLE_ITEM_TRUE = "CLICKABLE_ITEM_TRUE"
const CLICKABLE_ITEM_FALSE = "CLICKABLE_ITEM_FALSE"
const CLICKABLE_ITEM = "CLICKABLE_ITEM"

function getLabelName(element: ClickableItemElement): string {
  if (element.text) {
    return element.text.gt === "true"
      ? CLICKABLE_ITEM_TRUE
      : CLICKABLE_ITEM_FALSE
  }

  if (element.clickableValue) {
    return element.clickableValue ? CLICKABLE_ITEM_TRUE : CLICKABLE_ITEM_FALSE
  }

  return CLICKABLE_ITEM
}

export function getClickableItem(element: ClickableItemElement): Annotation {
  return {
    label: "",
    labelName: getLabelName(element),
    geometry: getGeometry(element),
    id: element.id
  }
}
