import React from "react";

type Props = {
  header: string | JSX.Element;
  metadata?: Array<KeyValues> | undefined;
};

interface KeyValues {
  key: string;
  value: string;
}

const MetadataContainer = (props: Props) => (
  <div className="awsui-util-container">
    <div className="awsui-util-container-header">
      {(typeof props.header === "string" && <h2>{props.header}</h2>) ||
        props.header}
    </div>
    {props.metadata && (
      <div className="awsui-util-spacing-v-s">
        {props.metadata.map(keyValue => (
          <div key={keyValue.key}>
            <div className="awsui-util-label">{keyValue.key}</div>
            <div>
              {keyValue.value.indexOf("https://") !== -1 ? (
                <a href={keyValue.value}>{keyValue.value}</a>
              ) : (
                keyValue.value
              )}
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);

export default MetadataContainer;
