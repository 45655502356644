import { Element } from "./getWord";
import { Annotation } from "../arenaParser";
import { getGeometry } from './getGeometry';

const VALUE_WITH_CHILDREN = "VALUE_WITH_CHILDREN";
const VALUE_WITHOUT_CHILDREN = "VALUE_WITHOUT_CHILDREN";

function getLabelName(element: Element): string {
    if ("children" in element && element.children && element.children.length > 0) {
        return VALUE_WITH_CHILDREN;
    } else {
        return VALUE_WITHOUT_CHILDREN;
    }
}

export function getValue(element: Element): Annotation {
    return ({
        label: "",
        labelName: getLabelName(element),
        geometry: getGeometry(element),
        id: element.id
    });
}
