import { ClusterImage, ParsedFaceImage } from "./Types";

/**
 * Parses the given image with its list of bounding boxes into a list of urls to face regions
 * @param image
 */
export const parseFaceUrlResponse = (
  image: ClusterImage
): Array<ParsedFaceImage> => {
  var imageUrl = image.ImageUrl;
  var parsedImages: Array<ParsedFaceImage> = image.Region.map(region => {
    var parsedRegion = {
      width: region.Width,
      height: region.Height,
      x: region.Left,
      y: region.Top
    };
    return {
      url: imageUrl,
      region: parsedRegion
    };
  });

  return parsedImages;
};
