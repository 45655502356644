import React from 'react'
import BreadcrumbGroup from '@amzn/awsui-components-react/polaris/breadcrumb-group'

type Props = {
    pathname: string,
}

type Breadcrumbs = {
    text: string,
    href: string,
}

const capitalize = (string: string) => (
    `${string.charAt(0).toUpperCase()}${string.slice(1)}`
)

const buildBreadcrumbsFromPath = (pathname: string): Array<Breadcrumbs> => {
    let savedRoute = "#"

    // Need to slice off the leading '/' in the pathname prior to spliting
    return pathname.slice(1).split('/').reduce((routes, route) => {
        savedRoute = `${savedRoute}/${route}`
        return route === "datasets" || route === "assets" 
            ? routes 
            : [...routes, {text: capitalize(route), href: savedRoute}]
    }, [{text: "Home", href: "#/"}])
}

const DynamicBreadcrumbs = ({pathname}: Props) => (
    <BreadcrumbGroup label="Breadcrumbs" items={buildBreadcrumbsFromPath(pathname)}></BreadcrumbGroup>
)

export default DynamicBreadcrumbs