import React from "react";
import styled from "styled-components";
import Button from "@amzn/awsui-components-react/polaris/button";

const ActionStripe = styled.div`
  &.cluster-tool.awsui-util-action-stripe {
    width: 100%;
    position: relative;
    top: 0.5rem;
    justify-content: flex-end;
    min-height: 3rem;
  }
`;

type Props = {
  index: number;
  items: Array<any>;
  submitDisabled: boolean;
  previous: Function;
  next: Function;
  submit: Function;
};

const SUBMIT = "Submit Progress",
  NEXT = "Next",
  PREVIOUS = "Previous";

export default ({
  index,
  items,
  submitDisabled,
  previous,
  next,
  submit
}: Props) => {
  const prevDisabled = index === 0 || !items.length,
    nextDisabled =
      !items.length || !items[index].annotation || index === items.length - 1;
  return (
    <ActionStripe className="cluster-tool awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-group">
        <Button
          text={PREVIOUS}
          onClick={(e: CustomEvent) => previous()}
          disabled={prevDisabled}
        />
        {
          <Button
            text={NEXT}
            onClick={(e: CustomEvent) => next()}
            disabled={nextDisabled}
          />
        }
        {
          <Button
            text={SUBMIT}
            onClick={(e: CustomEvent) => submit()}
            disabled={submitDisabled}
            variant={"primary"}
          />
        }
      </div>
    </ActionStripe>
  );
};
