import React, {useState} from 'react'
import {parseJson} from '../Utils/Json'
import Textarea from '@amzn/awsui-components-react/polaris/textarea'
import FormSection from '@amzn/awsui-components-react/polaris/form-section'
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import Button from '@amzn/awsui-components-react/polaris/button'

export default (props: {
    stage: string,
    submitting: boolean,
    actionSubmit: (
        json:object,
        extraActions?: () => void
    ) => void
}) => {
    const [input, setInput] = useState<string>('')

    const handleTextareaChange = (item: any) => {
        setInput(item.detail.value)
    }

    const handleWorkflowTrigger = () => {
        const json = parseJson(input)
        props.actionSubmit(json)
    }

    return <FormSection
            header="Create workflow with JSON"
            footer={
                <div className="awsui-modal-footer">
                <div className="awsui-util-f-r">
                    <Button
                        text="Submit"
                        variant="primary"
                        disabled={props.submitting}
                        onClick={handleWorkflowTrigger}
                    ></Button>
                </div>
                </div>
            }
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                <FormField
                    stretch={true}
                >
                    <Textarea 
                        placeholder="Add the JSON for the workflow and click the Submit button to trigger the workflow creation"
                        rows={20}
                        value={input}
                        onChange={handleTextareaChange}
                    ></Textarea>
                </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
}
