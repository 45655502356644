import styled, {keyframes} from 'styled-components'
import Table from '@amzn/awsui-components-react/polaris/table'

const slideInLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-5px);
    }
    to {
        opacity: 1;
        transform: translateX(-0px);
    }
`;

const AnimatedTable = styled(Table)`
  td {
    animation: ${slideInLeft} 0.75s ease;
  }
`

export default AnimatedTable