import { AnnotatedFace, Region } from "./reducer";

type Metadata = {
  BirthYear: number;
  Gender: string;
  PopulationGroup: string;
  Name: string;
};

type ClusterAnnotation = {
  X: number;
  Y: number;
  Height: number;
  Width: number;
  Age?: number;
  FileName: string;
};

type Cluster = {
  ClusterName: string;
  Metadata: Metadata;
  Annotations: Array<ClusterAnnotation>;
};

const parseRegion = (data: Region) => ({
  X: data.x,
  Y: data.y,
  Height: data.height,
  Width: data.width,
  Age: data.age || 0
});

const parseCluster = (data: AnnotatedFace) =>
  (data.annotation &&
    data.annotation.id && {
      ClusterName: data.annotation.id,
      Metadata: {
        BirthYear: parseInt(data.annotation.yearOfBirth),
        Gender: data.annotation.gender,
        PopulationGroup: data.annotation.populationGroup,
        Name: data.annotation.name
      },
      Annotations: [
        {
          ...parseRegion(data.region),
          FileName: data.filename
        }
      ]
    }) ||
  null;

/**
 * Returns approprate data structure for Clustering tool POST
 *
 * @param arr Array of BBoxes with annotations
 */
export default (arr: Array<AnnotatedFace>) => {
  const accumulator: Array<Cluster> = [];
  return arr.reduce((acc, item) => {
    const cluster = acc.find(
      (cluster: Cluster) =>
        (item.annotation && cluster.ClusterName === item.annotation.id) || false
    );

    // If cluster already exists
    if (cluster) {
      cluster.Annotations.push({
        ...parseRegion(item.region),
        FileName: item.filename
      });
      return acc;
    }

    // Else create new cluster
    const newCluster = parseCluster(item);
    if (newCluster) {
      acc.push(newCluster);
    }
    return acc;
  }, accumulator);
};
