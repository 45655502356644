import React, { Fragment } from "react";
import styled from "styled-components";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Input from "@amzn/awsui-components-react/polaris/input";
import Button from "@amzn/awsui-components-react/polaris/button";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import { get } from "lodash";
import {
  imageFilters,
  Filters,
  START_DATE,
  END_DATE,
  DATE_KEY,
  CONSENSUS_KEY,
  CONSENSUS,
  CONSENSUS_HIGH,
  CONSENSUS_LOW
} from "./filterConfig";
const WrapButtonDropdown = styled.div`
  max-width: 15%;
  display: flex;
  justify-content: flex-end;
  awsui-button-dropdown[initialized="true"] {
    width: 100%;
    .awsui-button-dropdown-container {
      display: flex;
      justify-content: flex-end;
    }
    awsui-button[initialized="true"] {
      max-width: 100%;
    }
    .awsui-button-dropdown-container,
    button.awsui-button {
      width: 100%;
      max-width: 200px;
    }
    button.awsui-button {
      display: flex;
      span[awsui-button-region="text"] {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const ConsensusFilterContainer = styled.div`
  align-items: flex-end; 
  width: 65%; 
  justify-content: space-evenly; 
  display: flex;
`;

const consensusItems = [
  {
    disabled: false,
    id: 'No',
    text: 'No'
  },
  {
    disabled: false,
    id: 'Yes',
    text: 'Yes'
  }
];

type Props = {
  loading: boolean;
  name: string;
  count: number;
  filters: Filters;
  showDropdown: string | boolean;
  openDropdown: (key: string) => void;
  onClearClick: () => void;
  onFilterChange: (
    e: CustomEvent<ButtonDropdown.ItemClick>,
    key: string
  ) => void;
  onArrayValueChange: (value: any[], key: string) => void;
  onDateChange: (e: CustomEvent<DatePicker.ChangeDetail>, key: string) => void;
  onInputChange: (e: CustomEvent<Input.ChangeDetail>, key: string) => void;
  onSubmit: (filters: Filters) => void;
  onDismiss: () => void;
};

export const isNotFutureDate = (date: Date) => date < new Date();

export const isValidEndDate = (date: Date, beginDate: string) =>
  date > new Date(beginDate) && isNotFutureDate(date);

export default (props: Props) => (
  <Fragment>
    <div className="flex-space-between">
      {Object.keys(imageFilters).map(key => {
        if (key !== DATE_KEY && key !== CONSENSUS_KEY) {
          const variant = props.filters[key] ? "primary" : "normal";
          const text = props.filters[key]
            ? imageFilters[key].options.find(
                (option: { text: string; id: string }) =>
                  option.id === props.filters[key]
              ).text
            : imageFilters[key].label;
          const items = [
            { text, id: null, disabled: true },
            ...imageFilters[key].options
          ];
          return (
            <WrapButtonDropdown key={key}>
              <ButtonDropdown
                text={text}
                items={items}
                disabled={props.loading}
                variant={variant}
                onItemClick={e => props.onFilterChange(e, key)}
              />
            </WrapButtonDropdown>
          );
        }
        const variant =
          key === DATE_KEY
            ? props.filters[START_DATE] || props.filters[END_DATE]
              ? "primary"
              : "normal"
            : props.filters[CONSENSUS_LOW] || props.filters[CONSENSUS_HIGH]
            ? "primary"
            : "normal";
        return (
          <Button
            key={key}
            text={imageFilters[key].label}
            onClick={() => props.openDropdown(key)}
            disabled={props.loading}
            variant={variant}
          />
        );
      })}
      <Button
        text="Clear filters"
        onClick={props.onClearClick}
        disabled={Object.keys(props.filters).length === 0}
      />
    </div>
    {props.showDropdown && (
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingTop: "0"
        }}
      >
        {(props.showDropdown === DATE_KEY && (
          <Fragment>
            <div>
              <div className="awsui-util-label">Start Date</div>
              <DatePicker
                value={props.filters[START_DATE] || ""}
                placeholder="Start Date"
                isDateEnabled={isNotFutureDate}
                onChange={e => props.onDateChange(e, START_DATE)}
              />
            </div>
            <div>
              <div className="awsui-util-label">End Date</div>
              <DatePicker
                value={props.filters[END_DATE] || ""}
                placeholder="End Date"
                isDateEnabled={date =>
                  isValidEndDate(date, props.filters[START_DATE] || "")
                }
                onChange={e => props.onDateChange(e, END_DATE)}
              />
            </div>
          </Fragment>
        )) || (
          <Fragment>
            <ConsensusFilterContainer>
              <ButtonDropdown items={consensusItems} onItemClick={e => {
                props.onArrayValueChange([e.detail.id], CONSENSUS);
              }}>
                Consensus
              </ButtonDropdown>
              <div>
                <div className="awsui-util-label">Lowest Consenus</div>
                <Input
                  value={get(props.filters, CONSENSUS_LOW)}
                  type="number"
                  placeholder="Percentage %"
                  onChange={e => props.onInputChange(e, CONSENSUS_LOW)}
                />
              </div>
              <div>
                <div className="awsui-util-label">Highest Consensus</div>
                <Input
                  value={get(props.filters, CONSENSUS_HIGH)}
                  type="number"
                  placeholder="Percentage %"
                  onChange={e => props.onInputChange(e, CONSENSUS_HIGH)}
                />
              </div>
            </ConsensusFilterContainer>
          </Fragment>
        )}
        <div style={{ alignSelf: "flex-end" }}>
          <Button
            text="Confirm"
            onClick={() => props.onSubmit({ ...props.filters })}
            disabled={false}
          />
          <Button text="Close" onClick={() => props.onDismiss()} />
        </div>
      </div>
    )}
  </Fragment>
);
