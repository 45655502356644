import { ArenaSchema, ClickableItemElement, KeyValueContainerElement, KeyElement, ValueElement } from "./arenaSchema"
import {
  getWord,
  getLine,
  getValue,
  getTableCell,
  getClickableItem,
  getKey,
  getKeyValueContainer,
  getTable,
  getContentArea,
  getHandwriting,
  getPrinted,
  getSegmentation,
  getHeader,
  getParagraph,
  getSignature
} from "./Parsers/index"

export const WORDS = "words"
export const LINE = "line"
export const KEY_VALUE = "key_value"
export const KEY_VALUE_CONTAINERS = "key_value_containers"
export const TABLES = "tables"
export const TABLE_CELLS = "table_cells"
export const CLICKABLE = "clickable"
export const CONTENT_AREA = "content_area"
export const HANDWRITING = "handwriting"
export const PRINTED = "printed"
export const SEGMENTATION = "segmentation"
export const LAYOUT = "layout"
export const SIGNATURE = "words_Signature_polygon"

const KV_KEY = "KEY"
const KV_VALUE = "VALUE"
const KV_VALUE_BBOX = "VALUE_BBOX"
const KV_VALUE_NORMAL = "VALUE_NORMAL"
const KV_CONTAINER = "KEY_VALUE_CONTAINER"

interface Data {
  annotationType: string
  annotationData: ArenaSchema
}

type Bbox = {
  x: number
  y: number
  width: number
  height: number
}

export interface Annotation {
  label: string | undefined
  labelName: string
  geometry: {
    polygon?: { x: number; y: number }[]
    boundingBox?: { x: number; y: number; w: number; h: number }
  }
  id: string
}

export const generateKvHierarchies = (
  arenaFormattedFile: ArenaSchema
): any => {
  const allKeyValueContainers: KeyValueContainerElement[] =
    arenaFormattedFile.elements.filter((element) => element.type === KV_CONTAINER) as KeyValueContainerElement[];
  const keyValueContainersAsChild = new Set();
  const allElements: any = {};
    
  for(let i=0; i<arenaFormattedFile.elements.length; i++) {
    const element = arenaFormattedFile.elements[i] as KeyElement | ValueElement;
    if((element.type === KV_KEY || element.type === KV_VALUE ||
      element.type === KV_VALUE_BBOX || element.type === KV_VALUE_NORMAL) && element.children) {
      element.children.filter((child) => child.elementType === KV_CONTAINER)
        .forEach(item => keyValueContainersAsChild.add(item.elementId));
    };
    allElements[element.id] = element;
  }

  const kvPerDepths: any = [];

  const keyValueContainers: KeyValueContainerElement[] = allKeyValueContainers.filter((keyValueContainer) => {
    return !keyValueContainersAsChild.has(keyValueContainer.id)
  });

  const traverseChildren = (containers: KeyValueContainerElement[], depth: number) => {

    if(depth === 10) return; // maximum traversal is 10 times (0~9)
    
    const keysPerDepth = containers
      .map((container => container.children.filter(child => child.elementType === KV_KEY)))
      .map((e) => allElements[e[0].elementId]);
    const valuesPerDepth = containers
      .map((container => container.children.filter(child => child.elementType === KV_VALUE ||
        child.elementType === KV_VALUE_BBOX || child.elementType === KV_VALUE_NORMAL)))
      .map((e) => allElements[e[0].elementId]);
  
    // [{containers: [], keys: [], values: []}, {....}]
    const kvPerDepth = {
      containers,
      keys: keysPerDepth,
      values: valuesPerDepth
    };

    const keyValueContainersAsImmediateChild = new Set();

    if (containers.length && keysPerDepth.length && valuesPerDepth.length) {
      kvPerDepths.push(kvPerDepth);
      // get the next child container and recursively call this function
      kvPerDepth.keys.forEach((key) => {
        key.children && key.children.forEach((child: any) => {
          if (child.elementType === KV_CONTAINER) {
            keyValueContainersAsImmediateChild.add(child.elementId);
          }
        });
      })

      kvPerDepth.values.forEach((value) => {
        value.children && value.children.forEach((child: any) => {
          if (child.elementType === KV_CONTAINER) {
            keyValueContainersAsImmediateChild.add(child.elementId);
          }
        });
      })

      const immediateKeyValueContainerChild = arenaFormattedFile.elements
        .filter((element) => keyValueContainersAsImmediateChild.has(element.id)) as KeyValueContainerElement[];

      if(immediateKeyValueContainerChild.length) {
        traverseChildren(immediateKeyValueContainerChild, depth + 1);
      }
    }
  };

  traverseChildren(keyValueContainers, 0);

  return kvPerDepths;
}

const createAnnotation = (
  labelName: string,
  label: string,
  id: string,
  bbox?: Bbox,
  polygon?: { x: number; y: number }[]
): Annotation => {
  return {
    labelName,
    label,
    id,
    geometry: {
      boundingBox: bbox
        ? {
            x: bbox.x,
            y: bbox.y,
            w: bbox.width,
            h: bbox.height
          }
        : undefined,
      polygon: polygon || []
    }
  }
}

export const transformSchemaToUIFormat = (
  arenaFormattedFile: ArenaSchema
): Annotation[] => {
  const annotations = arenaFormattedFile.elements.map(element => {
    // Clickable items has a special field value field needs to broken down
    if (element.type === "CLICKABLE_ITEM") {
      return getClickableItem(element as ClickableItemElement)
    }
    let labelName = element.type
    if (element.type === KV_CONTAINER) {
      let keyValueContainerElement = element as KeyValueContainerElement
      labelName = keyValueContainerElement.type + (keyValueContainerElement.property === undefined || keyValueContainerElement.property === "default" ? "" : ":" + keyValueContainerElement.property)
    }
    return createAnnotation(labelName, "", element.id, element.bbox, element.polygon);
    
  });

  const generatedKvHierarchies = generateKvHierarchies(arenaFormattedFile);
  const kvAnnotations = generatedKvHierarchies.map((kvPerDepth: any, depth: number) => {
    const kvAnnotation: any = [];
    for(const key in kvPerDepth) {
      const elements = kvPerDepth[key];
      elements.forEach((element: KeyValueContainerElement | KeyElement | ValueElement) => {
        const levelByDepth = generatedKvHierarchies.length - depth - 1;
        let labelName = `${element.type}_${levelByDepth}`;
        if (element.type === KV_CONTAINER) {
          let keyValueContainerElement = element as KeyValueContainerElement
          labelName = keyValueContainerElement.type + (keyValueContainerElement.property === undefined || keyValueContainerElement.property === "default" ? "" : ":" + keyValueContainerElement.property) + "_" + levelByDepth
        }
        kvAnnotation.push(createAnnotation(labelName, "kv_hierarchies", element.id, element.bbox, element.polygon));
      });
    };
    return kvAnnotation;
  });

  kvAnnotations.forEach((kvAnnotation: any) => {
    annotations.unshift(...kvAnnotation);
  });

  return annotations;
}

function arenaObjectParser(data: Data) {
  switch (data.annotationType) {
    case WORDS: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "WORD")
        .forEach(element => {
          annotations.push(getWord(element))
        })
      return annotations
    }
    case LINE: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "LINE")
        .forEach(element => {
          annotations.push(getLine(element))
        })
      return annotations
    }
    case KEY_VALUE: {
      const annotations: Annotation[] = []
      data.annotationData.elements.forEach(element => {
        if (element.type === "KEY") {
          annotations.push(getKey(element))
        } else if (element.type === "VALUE") {
          annotations.push(getValue(element))
        }
      })
      return annotations
    }
    case KEY_VALUE_CONTAINERS: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === KV_CONTAINER)
        .forEach(element => {
          annotations.push(getKeyValueContainer(element))
        })
      return annotations
    }
    case TABLES: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "TABLE")
        .forEach(element => {
          annotations.push(getTable(element))
        })
      return annotations
    }
    case TABLE_CELLS: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "TABLE_CELL")
        .forEach(element => {
          annotations.push(getTableCell(element))
        })
      return annotations
    }
    case CLICKABLE: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "CLICKABLE_ITEM")
        .forEach(element => {
          annotations.push(getClickableItem(element as ClickableItemElement))
        })
      return annotations
    }
    case CONTENT_AREA: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "DOCUMENT_CONTENT_AREA")
        .forEach(element => {
          annotations.push(getContentArea(element))
        })
      return annotations
    }
    case HANDWRITING: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "HANDWRITING")
        .forEach(element => {
          annotations.push(getHandwriting(element))
        })
      return annotations
    }
    case PRINTED: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "PRINTED")
        .forEach(element => {
          annotations.push(getPrinted(element))
        })
      return annotations
    }
    case SEGMENTATION: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "SEGMENT")
        .forEach(element => {
          annotations.push(getSegmentation(element))
        })
      return annotations
    }
    case LAYOUT: {
      const annotations: Annotation[] = []
      data.annotationData.elements.forEach(element => {
        if (element.type === "HEADER") {
          annotations.push(getHeader(element))
        }
      })
      data.annotationData.elements.forEach(element => {
        if (element.type === "PARAGRAPH") {
          annotations.push(getParagraph(element))
        }
      })
      return annotations
    }
    case SIGNATURE: {
      const annotations: Annotation[] = []
      data.annotationData.elements
        .filter(element => element.type === "Signature")
        .forEach(element => {
          annotations.push(getSignature(element))
        })
      return annotations
    }
    default:
      const annotations: Annotation[] = []
      return annotations
  }
}

export default arenaObjectParser
