import React, { ComponentType } from "react";
import styled, { keyframes } from "styled-components";

const TOP = "TOP",
  BOTTOM = "BOTTOM",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL";

type Slide = "LEFT" | "RIGHT" | "TOP" | "BOTTOM";
type Direction = "VERTICAL" | "HORIZONTAL";

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const slideInDirection = (
  direction: Direction,
  value: number
) => keyframes`
    from {
        opacity: 0;
        transform: ${
          direction === VERTICAL
            ? `translateY(${value}px)`
            : `translateX(${value}px)`
        };
    }
    to {
        opacity: 1;
        transform: ${
          direction === VERTICAL ? `translateY(0px)` : `translateX(0px)`
        };
    }
`;

const slideOffsetsInPixels = {
  LEFT: -10,
  RIGHT: 10,
  TOP: -20,
  BOTTOM: 20
};

export const withSlideAnimation = (
  Component: ComponentType<any>,
  slide: Slide
) => {
  const direction = slide === TOP || slide === BOTTOM ? VERTICAL : HORIZONTAL;
  const AnimatedWrapper = styled.div`
    animation: ${slideInDirection(direction, slideOffsetsInPixels[slide])} 0.2s
      forwards;
  `;
  return (props: any) => (
    <AnimatedWrapper>
      <Component {...props} />
    </AnimatedWrapper>
  );
};

export const withAnimation = (Component: ComponentType<any>) => {
  const AnimatedWrapper = styled.div`
    animation: ${slideInDirection(HORIZONTAL, slideOffsetsInPixels[LEFT])} 0.2s
      forwards;
  `;
  return (props: any) => (
    <AnimatedWrapper className={"animated-wrapper"}>
      <Component {...props} />
    </AnimatedWrapper>
  );
};
