import {
    WordElement, LineElement, PrintedElement, HandWritingElement, SegmentElement, SignatureElement,
    DocumentContentAreaElement, TableCellElement, TableElement, ClickableItemElement,
    KeyValueContainerElement, KeyElement, ValueElement, ParagraphElement, HeaderElement
} from "../arenaSchema";
import { Annotation } from "../arenaParser";
import { getGeometry } from "./getGeometry";
const WORD_WITH_CONSENSUS = "WORD_WITH_CONSENSUS";
const WORD_WITHOUT_CONSENSUS = "WORD_WITHOUT_CONSENSUS";
const SKIPPED = "__SKIPPED__";
const WORD_SKIPPED = "WORD_SKIPPED";
const PURE_WORD_BBOX = "PURE_WORD_BBOX";

export type Element = WordElement | LineElement | PrintedElement | HandWritingElement
    | SegmentElement | SignatureElement | DocumentContentAreaElement
    | TableElement | TableCellElement | ClickableItemElement
    | KeyValueContainerElement | KeyElement | ValueElement | ParagraphElement | HeaderElement

function getLabel(element: Element) {
    if ("text" in element && element.text) {
        if ("gt" in element.text) {
            return element.text.gt;
        } else if (element.text.annotations) {
            const reducer = (accumulator: string, currentValue: string) => accumulator + ", " + currentValue
            const possibleWords = element.text.annotations.reduce(reducer);
            return possibleWords;
        } else {
            return SKIPPED;
        }
    } else {
        return "";
    }
}

function getLabelName(element: Element) {
    if ("text" in element && element.text) {
        if ("gt" in element.text) {
            return WORD_WITH_CONSENSUS;
        } else if (element.text.annotations) {
            return WORD_WITHOUT_CONSENSUS;
        } else {
            return WORD_SKIPPED;
        }
    } else {
        return PURE_WORD_BBOX;
    }
}

export function getWord(element: Element): Annotation {
    return ({
        label: getLabel(element),
        labelName: getLabelName(element),
        geometry: getGeometry(element),
        id: element.id
    })
}