export const GET_PROJECTS = "getProjects"
export const GET_DATASETS = "getDatasets"
export const GET_ASSETS = "getAssets"
export const GET_ASSETS_IMAGE_BATCH = "getAssetImageBatch"
export const GET_ASSET_IMAGE = "getAssetImage"
export const GET_ASSET_ANNOTATION = "getAssetAnnotation"
export const TRIGGER_WORKFLOW_CREATION = "triggerWorkflowCreation"
export const GET_FACES_FROM_IMAGE_URL = "getFacesFromImageUrl"
export const GET_FACE_CLUSTER_ASSETS = "getFaceClusterAssets"
export const GET_FACE_CLUSTER_IDENTITIES = "getFaceClusterIdentities"
export const POST_FACE_CLUSTER_ANNOTATIONS = "postFaceClusterAnnotations"
export const GET_QA_IDENTITIES = "getQAIdentities"
export const GET_QA_IDENTITY_IMAGE_ASSETS = "getQAIdentityImageAssets"
export const GET_QA_IDENTITY_DATA = "getQAIdentityData"
export const POST_QA_RESULTS = "postQAResults"
export const GET_QA_PROJECTS = "getQAProjects"
interface Stage {
    [dev: string]: string,
    beta: string,
    prod: string,
}

interface APIConfig {
    [getProjects: string]: Stage,
    getDatasets: Stage,
    getAssets: Stage,
    getAssetImageBatch: Stage,
    getAssetImage: Stage,
    triggerWorkflowCreation: Stage,
    getFacesFromImageUrl: Stage,
    getFaceClusterAssets: Stage,
    getFaceClusterIdentities: Stage,
    postFaceClusterAnnotations: Stage,
    getQAIdentities: Stage,
    getQAIdentityImageAssets: Stage,
    getQAIdentityData: Stage,
    postQAResults: Stage,
    getQAProjects: Stage
}

export const APIConfig: APIConfig = {
    getProjects: {
        dev: "https://eie58kul2e.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://ztarkznrs6.execute-api.us-west-2.amazonaws.com/beta/",
        prod: "https://3idm9xgta4.execute-api.us-west-2.amazonaws.com/prod/"
    },
    getDatasets: {
        dev: "https://laryfsjex3.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://rqyynyif7i.execute-api.us-west-2.amazonaws.com/beta/",
        prod: "https://w7xgfaldwa.execute-api.us-west-2.amazonaws.com/prod/"
    },
    getAssets: {
        dev: "https://c9xsvung3d.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://k08k8t090m.execute-api.us-west-2.amazonaws.com/beta/",
        prod: "https://iki2wh42p7.execute-api.us-west-2.amazonaws.com/prod/"
    },
    getAssetImageBatch: {
        dev: "https://qqzd1s0v3k.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://m454xds28l.execute-api.us-west-2.amazonaws.com/beta/",
        prod: "https://rq3zek0i0m.execute-api.us-west-2.amazonaws.com/prod/"
    },
    getAssetImage: {
        dev: " https://g9pzq3tw5a.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://1mmq37ufw0.execute-api.us-west-2.amazonaws.com/beta/",
        prod: "https://j610i7sea1.execute-api.us-west-2.amazonaws.com/prod/"
    },
    triggerWorkflowCreation: {
        dev: "https://ltjm7dui0j.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://lp2hrl4yvl.execute-api.us-west-2.amazonaws.com/beta/",
        prod: "https://92w0hk8k6h.execute-api.us-west-2.amazonaws.com/prod/"
    },
    getFacesFromImageUrl: {
        dev: "https://7xbdvjs5sd.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://wd9wocbhl8.execute-api.us-west-2.amazonaws.com/beta",
        prod: "https://wd9wocbhl8.execute-api.us-west-2.amazonaws.com/beta"
    },
    getFaceClusterAssets: {
        dev: "https://7mnil2shq0.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://pmij3tengb.execute-api.us-west-2.amazonaws.com/beta",
        prod: "https://v6yv5j34u4.execute-api.us-west-2.amazonaws.com/prod"
    },
    postFaceClusterAnnotations: {
        dev: "https://blmtvicaqj.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://77ne39u27e.execute-api.us-west-2.amazonaws.com/beta",
        prod: "https://77ne39u27e.execute-api.us-west-2.amazonaws.com/beta"
    },
    getFaceClusterIdentities: {
        dev: "https://2s8ak1x8jl.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://2s8ak1x8jl.execute-api.us-west-2.amazonaws.com/test",
        prod: "https://2s8ak1x8jl.execute-api.us-west-2.amazonaws.com/test"
    },
    getQAIdentities: {
        dev: "https://oxvri39smg.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://oxvri39smg.execute-api.us-west-2.amazonaws.com/test",
        prod: "https://oxvri39smg.execute-api.us-west-2.amazonaws.com/test"
    },
    // reference image and face image
    //GetIdentityMetadataResultsAPI
    getQAIdentityImageAssets: {
        dev: "https://io366m8zei.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://xcd8cwtju9.execute-api.us-west-2.amazonaws.com/beta",
        prod: "https://lzos0nnz74.execute-api.us-west-2.amazonaws.com/prod"
    },
    // identity box on gallery page and QA identity list page
    getQAIdentityData: {
        dev: "https://io366m8zei.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://xcd8cwtju9.execute-api.us-west-2.amazonaws.com/beta",
        prod: "https://lzos0nnz74.execute-api.us-west-2.amazonaws.com/prod"
    },
    getQAProjects: {
        dev: "https://ib8ckae5g4.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://ib8ckae5g4.execute-api.us-west-2.amazonaws.com/test",
        prod: "https://ib8ckae5g4.execute-api.us-west-2.amazonaws.com/test"
    },
    postQAResults: {
        dev: "https://dz692u1d2l.execute-api.us-west-2.amazonaws.com/test",
        beta: "https://cswre3pdgc.execute-api.us-west-2.amazonaws.com/beta",
        prod: "https://htp4r2298i.execute-api.us-west-2.amazonaws.com/prod"
    }
}
