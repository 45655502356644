import React from "react";
import styled from "styled-components";
import Button from "@amzn/awsui-components-react/polaris/button";
import "@amzn/awsui-components-react/polaris";

const ImageUploadPreviewDiv = styled.div`
    height: 50px;
    width: 50px;
    margin: 0.4rem 0 0.4rem 1rem;
  `,
  ImageUploadPreviewDescriptionDiv = styled.div`
    vertical-align: top;
    display: inline-block;
  `,
  ImageName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

const calculateImageSizeInMB = (imageSize: number) =>
  (imageSize / 1024 / 1024).toFixed(2);

const ImagePreviewDescription = (imageSize: number) => {
  var fileSizeInMB = calculateImageSizeInMB(imageSize);
  return (
    <ImageUploadPreviewDescriptionDiv>
      <div className="awsui-select-option-description">
        {"File size: " + fileSizeInMB + " MB"}
      </div>
    </ImageUploadPreviewDescriptionDiv>
  );
};

export const ImagePreviewToken = (
  image: File,
  imagePreviewUrl: string,
  imageSize: number,
  onRemoveImage: (url: string) => void
) => (
  <div>
    <div className="awsui-token-group">
      <div className="awsui-token">
        <div title="Item 1" className="awsui-select-option">
          <span className="awsui-select-option-icon">
            <Button variant="icon" icon="close" onClick={() => {onRemoveImage(imagePreviewUrl)}}></Button>
            <ImageUploadPreviewDiv>
              <img
                height="100%"
                width="100%"
                src={imagePreviewUrl}
                alt="upload preview"
                style={{ objectFit: "cover" }}
              />
            </ImageUploadPreviewDiv>
          </span>
          <div className="awsui-select-option-content">
            <div className="awsui-select-option-label-content">
              <span className="awsui-select-option-label">
                <ImageName>{image.name}</ImageName>
              </span>
            </div>
            {ImagePreviewDescription(imageSize)}
          </div>
        </div>
      </div>
    </div>
  </div>
);
