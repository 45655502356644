import { Element } from "./getWord";
import { Annotation } from "../arenaParser";
import { getGeometry } from './getGeometry';

export function getAnnotationFromElement(element: Element, labelName: string): Annotation {
    return ({
        label: "",
        labelName: labelName,
        geometry: getGeometry(element),
        id: element.id
    });
}