import {
  GET_ASSETS,
  GET_ASSETS_IMAGE_BATCH,
  GET_ASSET_IMAGE,
  GET_DATASETS,
  GET_PROJECTS,
  TRIGGER_WORKFLOW_CREATION,
  APIConfig,
  GET_FACES_FROM_IMAGE_URL,
  GET_FACE_CLUSTER_ASSETS,
  GET_FACE_CLUSTER_IDENTITIES,
  POST_FACE_CLUSTER_ANNOTATIONS,
  GET_QA_IDENTITIES,
  GET_QA_IDENTITY_DATA,
  GET_QA_IDENTITY_IMAGE_ASSETS,
  POST_QA_RESULTS,
  GET_QA_PROJECTS
} from "../API/ApiConfig"
import {
  ParsedFaceClusterAsset,
  ParsedImage,
  ParsedIdentity,
  ParsedIdentityAssets,
  ParsedIdentityData,
  PostResults
} from "./Parsers/Types"
import { parseFaceUrlResponse } from "./Parsers/ParseFaceUrlResponse"
import { parseGetAssetsResponse } from "./Parsers/ParseGetAssetsResponse"
import { parseGetPresignedUrlResponse } from "./Parsers/ParseGetPresignedUrlResponse"
import {
  parseIdentityListResponse,
  parsePostQABody
} from "./Parsers/ParseIdentityListResponse"
import queryString from "query-string"
const PLACEHOLDER_IMAGE =
  "https://s3-us-west-2.amazonaws.com/thunder-assets/placeholder.png"
const NEXT_CONTINUATION_TOKEN = "NextContinuationToken"
const CONTENTS = "Contents"
const ERROR = "Error"
const S3BUCKET = "Name"

export interface Dataset {
  Name: string
  Count: number
  SyntheticData: boolean
  SyntheticType?: string
  AnnotationVersion: number
  AnnotationTypes: Array<string>
  ExportedOn: string
  DatasetType: string
  S3Path: string
  Projects: Array<string>
  [key: string]: any
}

export interface Project {
  Name: string
  Train: {
    DatasetCount: number
    AssetCount: number
  }
  Test: {
    DatasetCount: number
    AssetCount: number
  }
  Datasets: Array<Dataset>
}

interface Asset {
  Contents: []
}

export interface Url {
  name: string
  url: string
}

export interface ImageBatch {
  Images: Array<Url>
  ContinuationToken: string
  Error?: string
}

export interface IdentityList {
  Identities: Array<ParsedIdentity>
  ContinuationToken: string
  QueryExecutionId: string
  TotalCount: number
}

interface ArenaSchema {
  schemaVersion: number
  pageMetadata: {
    width: number
    height: number
    baseAssetId: string
  }
  elements: []
}

type FaceClusterIdentity = {
  id: string
  gender: string
  name: string
  yearOfBirth: number
  populationGroup: string
}

export const emptyProject = {
  Name: "",
  Train: {
    DatasetCount: 0,
    AssetCount: 0
  },
  Test: {
    DatasetCount: 0,
    AssetCount: 0
  },
  Datasets: []
}
export const emptyDataset = {
  Name: "",
  Count: 0,
  SyntheticData: false,
  AnnotationVersion: 0,
  AnnotationTypes: [],
  ExportedOn: "",
  DatasetType: "",
  S3Path: "",
  Projects: []
}

const getHttpHeaders = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`
  }
})

const constructUrl = (api: string, stage: string, params: any) => {
  const queryParams = queryString.stringify(params)
  return `${APIConfig[api][stage]}?${queryParams}`
}

/**
 * Returns all projects and its metadata
 */
export const getProjects = async (
  idToken: string,
  stage = "beta"
): Promise<Array<Project>> => {
  const url = APIConfig[GET_PROJECTS][stage]
  try {
    const response = await fetch(url, getHttpHeaders(idToken))
    return await response.json()
  } catch (e) {
    console.log(e)
    return []
  }
}

/**
 * Returns all datasets and its metadata for a specific project
 */
export const getProject = async (
  projectName: string,
  idToken: string,
  stage = "beta"
): Promise<Project> => {
  const projectUrl = constructUrl(GET_DATASETS, stage, {
    manifest_name: projectName
  })
  try {
    const response = await fetch(projectUrl, getHttpHeaders(idToken))
    const projectDetails = await response.json()
    const datasetMap = {
      ...projectDetails.TrainDataset,
      ...projectDetails.TestDataset
    }
    const datasets = Object.keys(datasetMap).map((name: string) => ({
      Name: name,
      ...datasetMap[name]
    }))
    return {
      Name: projectName,
      Train: {
        AssetCount: projectDetails.TrainAssetCount,
        DatasetCount: Object.keys(projectDetails.TrainDataset).length
      },
      Test: {
        AssetCount: projectDetails.TestAssetCount,
        DatasetCount: Object.keys(projectDetails.TestDataset).length
      },
      Datasets: datasets
    }
  } catch (e) {
    console.log(e)
    return emptyProject
  }
}

/**
 * Return dataset and its metadata given the dataset name and project
 */
export const getDataset = async (
  projectName: string,
  datasetName: string,
  idToken: string,
  stage = "beta"
): Promise<Dataset> => {
  const projectUrl = constructUrl(GET_DATASETS, stage, {
    manifest_name: projectName
  })
  try {
    const response = await fetch(projectUrl, getHttpHeaders(idToken))
    const projectDetails = await response.json()
    const datasetMap = {
      ...projectDetails.TrainDataset,
      ...projectDetails.TestDataset
    }
    return datasetMap[datasetName]
  } catch (e) {
    console.log(e)
    return emptyDataset
  }
}

/**
 * Returns the presigned url for the asset to display
 */
export const getAssetImage = async (
  s3Path: string,
  idToken: string,
  stage = "beta"
): Promise<{ url: string; annotation: string }> => {
  const assetUrl = constructUrl(GET_ASSET_IMAGE, stage, { s3_key: s3Path })
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    return await response.json()
  } catch (e) {
    console.log(e)
    return {
      url: PLACEHOLDER_IMAGE,
      annotation: ""
    }
  }
}

/**
 * Returns a batch of presigned image urls to display given an s3Prefix
 */
export const getAssetImages = async (
  s3Prefix: string,
  idToken: string,
  stage = "beta",
  continuationToken = "",
  s3Bucket = "",
  roleARN = ""
): Promise<ImageBatch> => {
  const params = {
    s3_prefix: s3Prefix,
    s3_bucket: s3Bucket,
    role_arn: roleARN,
    ...(continuationToken ? { continuationToken } : {})
  }

  try {
    const assetUrl = constructUrl(GET_ASSETS_IMAGE_BATCH, stage, params)
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      return {
        Images: [],
        ContinuationToken: "",
        Error: json[ERROR]
      }
    }
    return {
      Images: json[CONTENTS],
      ContinuationToken: json[NEXT_CONTINUATION_TOKEN]
    }
  } catch (e) {
    console.log(e)
    return {
      Images: [],
      ContinuationToken: "",
      Error: e
    }
  }
}

/**
 * Returns list of all images for the given user
 */
export const getFaceClusterAssets = async (
  userAlias: string,
  idToken: string,
  viewType: string,
  stage = "beta"
): Promise<Array<ParsedFaceClusterAsset>> => {
  const assetUrl = constructUrl(GET_FACE_CLUSTER_ASSETS, stage, {
    userId: userAlias,
    feature: "Face",
    viewType: viewType,
    requestType: "READ"
  })
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    const parsedResponse = parseGetAssetsResponse(json)
    return parsedResponse
  } catch (e) {
    console.log(e)
    throw new Error("Unable to get images " + e)
  }
}

/**
 * Returns list of all files for the given user
 */
export const getFaceClusterPresignedUrl = async (
  userAlias: string,
  idToken: string,
  fileName: string,
  feature = "Face",
  stage = "beta"
): Promise<ParsedFaceClusterAsset> => {
  const assetUrl = constructUrl(GET_FACE_CLUSTER_ASSETS, stage, {
    userId: userAlias,
    feature,
    filename: fileName,
    requestType: "WRITE"
  })
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    const parsedResponse = parseGetPresignedUrlResponse(json)
    return parsedResponse
  } catch (e) {
    console.log(e)
    throw new Error("Unable to upload files " + e)
  }
}

/**
 * Returns the the bounding boxes for faces in the given image url
 */
export const getFacesFromImageUrl = async (
  url: string,
  idToken: string,
  stage = "beta"
): Promise<Array<ParsedImage>> => {
  const assetUrl = constructUrl(GET_FACES_FROM_IMAGE_URL, stage, { url: url })
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    const parsedImages = parseFaceUrlResponse(json)
    return parsedImages
  } catch (e) {
    console.log(e)
    throw new Error("Unable to get faces " + e)
  }
}

/**
 * Returns list of all previously created face identities for the given user
 */
export const getFaceClusterIdentities = async (
  userAlias: string,
  idToken: string,
  stage = "beta"
): Promise<Array<FaceClusterIdentity>> => {
  const assetUrl = constructUrl(GET_FACE_CLUSTER_IDENTITIES, stage, {
    userId: userAlias,
    feature: "Face"
  })
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    return json
  } catch (e) {
    console.log(e)
    throw new Error("Unable to get identities " + e)
  }
}

/**
 * Post annotations from the cluster tool
 */
export const postFaceClusterAnnotations = async (
  requestBody: object,
  idToken: string,
  userAlias: string,
  stage = "beta"
): Promise<Error | null> => {
  const postUrl = constructUrl(POST_FACE_CLUSTER_ANNOTATIONS, stage, {
    userId: userAlias,
    feature: "Face"
  })
  try {
    await fetch(postUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      },
      body: JSON.stringify(requestBody)
    })
    return null
  } catch (e) {
    console.log(e)
    throw new Error("Unable to post annotations " + e)
  }
}

/**
 * Returns all Assets and its metadata for a specific dataset
 */
export const getAssetsForDataset = async (
  dataset: Dataset,
  idToken: string,
  stage = "beta"
): Promise<Asset> => {
  if (!dataset.S3Path) return { Contents: [] }
  const assetUrl = constructUrl(GET_ASSETS, stage, { s3_key: dataset.S3Path })
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const assets = await response.json()
    return assets
  } catch (e) {
    console.log(e)
    return { Contents: [] }
  }
}

/**
 * Create the workflow for the privided asset
 */
export const triggerWorkflowCreation = async (
  requestBody: object,
  onRequestFinished: (resp: {
    succeed: boolean
    errorMsg?: string
    workflowArn?: string
  }) => void,
  idToken: string,
  stage = "beta"
): Promise<{} | null> => {
  const fetchUrl = `${APIConfig[TRIGGER_WORKFLOW_CREATION][stage]}`
  try {
    const response = await fetch(fetchUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      },
      body: JSON.stringify(requestBody)
    })
    const json = await response.json()
    if (json.hasOwnProperty("errorType")) {
      onRequestFinished({
        succeed: false,
        errorMsg: json.errorType
      })
    } else {
      const payload = json.Payload || [],
        arn = payload.length > 0 ? payload[0].WorkflowExecutionARN : null
      onRequestFinished({
        succeed: true,
        workflowArn: arn
      })
    }
    return json
  } catch (e) {
    onRequestFinished({
      succeed: false,
      errorMsg: "Failed to create the workflow"
    })
    console.log(e)
    return null
  }
}

/**
 * Returns all projects for QA
 */
export const getQAProjects = async (
  idToken: string,
  stage = "beta"
): Promise<Array<Project>> => {
  const url = APIConfig[GET_QA_PROJECTS][stage]
  try {
    const response = await fetch(url, getHttpHeaders(idToken))
    return await response.json()
  } catch (e) {
    console.log(e)
    return []
  }
}

/**
 * Returns list of all identities for the Face QA tool
 */
export const getQAIdentities = async (
  idToken: string,
  params: Object,
  stage = "dev"
): Promise<IdentityList> => {
  const assetUrl = constructUrl(GET_QA_IDENTITIES, stage, params)
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    const parsedResponse = parseIdentityListResponse(json["Identities"])
    return {
      Identities: parsedResponse,
      ContinuationToken: json["ContinuationToken"],
      QueryExecutionId: json["QueryExecutionId"],
      TotalCount: json["TotalCount"]
    }
  } catch (e) {
    console.log(e)
    throw new Error("Unable to get identities " + e)
  }
}

/**
 * Returns list of all assets for a given identity for the Face QA tool
 */
export const getQAIdentityImageAssets = async (
  idToken: string,
  params: Object,
  stage = "dev"
): Promise<ParsedIdentityAssets> => {
  const assetUrl = constructUrl(GET_QA_IDENTITY_IMAGE_ASSETS, stage, params)
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    return json.Result
  } catch (e) {
    console.log(e)
    throw new Error("Unable to get identities " + e)
  }
}

/**
 * Returns identity metadata for a given identity for the Face QA tool
 */
export const getQAIdentityData = async (
  idToken: string,
  queryParams: Object,
  stage = "dev"
): Promise<ParsedIdentityData> => {
  const assetUrl = constructUrl(GET_QA_IDENTITY_DATA, stage, queryParams)
  try {
    const response = await fetch(assetUrl, getHttpHeaders(idToken))
    const json = await response.json()
    if (json[ERROR]) {
      console.log(json[ERROR])
      throw new Error("Unable to parse json response " + json[ERROR])
    }
    return json.Result
  } catch (e) {
    console.log(e)
    throw new Error("Unable to get identities " + e)
  }
}

/**
 * Post results from QA for one identity
 */
export const postQAResults = async (
  results: PostResults,
  idToken: string,
  stage = "beta"
): Promise<Error | null> => {
  const url = APIConfig[POST_QA_RESULTS][stage]
  const requestBody = parsePostQABody(results)
  try {
    await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      },
      body: JSON.stringify(requestBody)
    })
    return null
  } catch (e) {
    console.log(e)
    throw new Error("Unable to post annotations " + e)
  }
}
