import React from "react";
import styled from "styled-components";

export const PolarisContainer = ({
  header,
  content,
  style
}: {
  header?: string;
  content?: JSX.Element;
  style?: object;
}) => (
  <div className="awsui-util-container" style={style ? style : {}}>
    {header && (
      <div className="awsui-util-container-header">
        <h2>{header}</h2>
      </div>
    )}
    {content}
  </div>
);

export const Container = styled.div`
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas: "title title" "left right";
    grid-gap: 2rem;
    .title {
      grid-area: title;
      margin-bottom: -1rem;
    }
    .flex-space-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `,
  LeftPanel = styled.div`
    grid-area: left;
  `,
  RightPanel = styled.div`
    grid-area: right;
  `,
  StickyContainer = styled.div`
    width: 100%;
    position: sticky;
    top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  ReferenceImagesContainer = styled.div`
    text-align: center;
    min-height: 200px;
    max-height: 65vh;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 1rem;
  `,
  ImagesContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 1.5rem;
    margin-bottom: 1rem;
  `,
  ImageContainer = styled.div`
    width: 100%;
    height: 260px;
    position: relative;
    background: "var(--awsui-color-background-container-content)";
    &:hover {
      .overlay {
        background: rgba(0, 0, 0, 0.5);
      }
      .image-actions {
        pointer-events: auto;
        opacity: 1;
      }
    }
    &.marked {
      .overlay {
        background: rgba(237, 79, 50, 0.5);
      }
    }
    .overlay,
    .image-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      pointer-events: none;
    }
    .overlay {
      background: rgba(0, 0, 0, 0);
      transition: background 0.3s;
    }
    .image-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      opacity: 0;
      .awsui-checkbox-label {
        color: #fff;
      }
      .expand-image {
        position: absolute;
        bottom: 2rem;
      }
    }
  `;
