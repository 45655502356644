import preloadImage from "./PreloadImage";

export type Region = {
  x: number;
  y: number;
  height: number;
  width: number;
};

export type Padding = {
  width?: number;
  height?: number;
};

/**
 * @param {*} padding Either object with optional height and width properties. If number, both height and width are given the same padding
 */
const parsePadding = (padding?: Padding | number) =>
  (typeof padding === "number" && {
    padWidth: padding,
    padHeight: padding
  }) ||
  (typeof padding === "object" && {
    padWidth: padding.width || 0,
    padHeight: padding.height || 0
  }) || { padWidth: 0, padHeight: 0 };

/**
 * Stateless function that crops a region from an image onto a canvas.
 *
 * @param {*} url
 * @param {*} region A non-rotated bounding box
 * @param {*} canvas HTMLCanvasElement
 * @param {*} padding Either object with optional height and width properties or a number. If number, both height and width are given the same padding
 * @returns A canvas element or undefined
 */
export default async (
  url: string,
  { x, y, width, height }: Region,
  canvas: HTMLCanvasElement | null,
  padding?: number | Padding
) => {
  if (url.length && canvas instanceof HTMLCanvasElement) {
    const img = await preloadImage(url).catch(e => "CropImage: " + e);
    const { padWidth, padHeight } = parsePadding(padding);
    const { naturalWidth, naturalHeight } = img,
      cropWidth = width * naturalWidth + padWidth,
      cropHeight = height * naturalHeight + padHeight;
    Object.assign(canvas, { width: cropWidth, height: cropHeight });
    const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(
        img,
        x * naturalWidth,
        y * naturalHeight,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
      return canvas;
    }
    return undefined;
  }
};
