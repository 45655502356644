/**
 * ImageGrid
 * Displays a grid of images. The number of items in a row is dependent on the size of the image
 */

import React, {useEffect, Fragment, SyntheticEvent} from 'react'
import styled from 'styled-components'
import PageLoader from '../Components/PageLoader'
const HEIGHT_ABOVE_SCROLL_END = 300

type Props = {
    images: Array<GridItem>,
    onImageClick(item: GridItem, index: number, event: SyntheticEvent): void,
    onScrollEnd(): void,
    isLoading: boolean,
    hasMore?: boolean,
    renderNameComponent?: (name: string) => React.ReactElement<any, any>,
    onSelect?: (item: GridItem, index: number, event: SyntheticEvent) => void,
}

type GridItem = {
    name: string,
    url: string,
    selected?: boolean,
}
const Gallery = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: minmax(50px, auto);
    grid-gap: 15px;
`
const GalleryItem = styled.div`
    margin-bottom: 5px;
`

const Footer = styled.div`
    margin-top: 2.5rem;
    text-align: center;
`
const FooterSeparator = styled.hr`
    margin-bottom: 1.5rem;
    border: 0.5px solid #687078;
`
const FooterText = styled.p`
    && {
        font-size: 2rem;
        color: #687078;
    }
`

const ImageContainer = styled.div`
    position: relative;
    height: auto;
    width: ${(props: ImageProps) => props.selected ? 96 : 100}%;
`

type ImageProps = {
    selected: boolean | undefined
}
const GalleryImage = styled.img`
    height: auto;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    opacity: 1;
    vertical-align: middle;
`

const Coverlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    cursor: pointer;
    background: orange;
    opacity: ${(props: ImageProps) => props.selected ? 0.5 : 0};
    transition: all .15s ease-in-out;
`

const SelectIcon = styled.div`
    left: 10px;
    top: 10px;
    position: absolute;
    font-family: sans-serif;
    cursor: pointer;
    font-weight: 400;
    font-size: 2rem;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    background: ${(props: ImageProps) => props.selected ? "#ff9900" : "#879596"};
    color: white;
    transform: ${(props: ImageProps) => props.selected ? "scale(1.1)" : "none"};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: all .15s ease-in-out;
    &:hover{
        background: #ff9900;
        color: white;
        transform: scale(1.1);
    }
`

const atEndofScroll = () => {
    const scrollableElement = document.getElementsByTagName('main')[0] //awsui-app-layout__content
    return window.innerHeight + scrollableElement.scrollTop + HEIGHT_ABOVE_SCROLL_END >= scrollableElement.scrollHeight
}

const ImageGrid = ({images, onImageClick, onScrollEnd, isLoading, hasMore=false, renderNameComponent=undefined, onSelect=undefined}: Props) => {
    const handleScroll = () => {
        if(!hasMore || isLoading) return

        if(atEndofScroll()) {
            onScrollEnd()
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true)
        return function cleanup() {
            window.removeEventListener('scroll', handleScroll, true)
        }
    })
    return (
        <Fragment>
            <Gallery>
            {
                images.map((image, index) => (
                    <GalleryItem key={image.name}>
                        {renderNameComponent && renderNameComponent(image.name)}
                        <ImageContainer selected={image.selected}>
                            <GalleryImage
                                id={image.name} 
                                onClick={(event: SyntheticEvent) => {onImageClick(image, index, event)}} 
                                src={image.url} 
                            />
                            <Coverlay selected={image.selected} onClick={(event: SyntheticEvent) => {onImageClick(image, index, event)}} />
                            {
                               onSelect && <SelectIcon selected={image.selected} onClick={(event: SyntheticEvent) => {onSelect(image, index, event)}}>
                                    {
                                        image.selected ? "x" : "+"
                                    }
                                </SelectIcon>
                            }
                        </ImageContainer>
                    </GalleryItem>
                ))
            }
            </Gallery>
            { 
                (isLoading) && <PageLoader />
            }
            {
                !(isLoading || hasMore) && images && images.length > 0 && <Footer>
                    <FooterSeparator/>
                    <FooterText>End of content</FooterText>
                </Footer>
            }
        </Fragment>
    )
}

export default ImageGrid
