import React, { Component } from 'react'
import PageTitle from '../Components/PageTitle'
import PageSubtitle from '../Components/PageSubtitle'
import AnimatedTable from '../Components/AnimatedTable'
import TableFiltering from '@amzn/awsui-components-react/polaris/table-filtering'
import TablePagination from '@amzn/awsui-components-react/polaris/table-pagination'
import TableSelection from '@amzn/awsui-components-react/polaris/table-selection'
import TableSorting from '@amzn/awsui-components-react/polaris/table-sorting'
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import { generateDatasetLink } from '../Routes/RouteConfig'
import { Project, Dataset, getProject } from '../API/Api'
import EmptyTableView from '../Components/EmptyTableView'
import TableCounter from '../Components/TableCounter'
import { numberToString } from '../Utils/StringUtils'
import { CognitoUser } from '@aws-amplify/auth';
import { getIdentityToken } from '../Utils/UserUtils';

const PAGE_SIZE = 50

type Props = {
    match: {
        params: {
            project: string
        },
        url: string,
    },
    stage: string,
    user: {
      cognitoUser: CognitoUser
    }
}

type State = {
  project: Project,
  loading: boolean
}

export default class ProjectView extends Component<Props, State> {
  state: State = {
    project: {
      Name: "",
      Datasets: [],
      Train: {
        AssetCount: 0,
        DatasetCount: 0,
      },
      Test: {
        AssetCount: 0,
        DatasetCount: 0,
      }
    },
    loading: true,
  }

  columnDefinitions = [
    {
      id: "name",
      header: "Dataset name",
      cell: (item: Dataset) => generateDatasetLink(
        this.props.match.url, item.Name)
    },
    {
      id: "type",
      header: "Dataset type",
      cell: (item: Dataset) => item.DatasetType
    },
    {
      id: "numAssets",
      header: "Number of assets",
      cell: (item: Dataset) => numberToString(item.Count)
    },
    {
      id: "date",
      header: "Exported on",
      cell: (item: Dataset) => new Date(item.ExportedOn).toLocaleDateString()
    },
    {
      id: "version",
      header: "Latest version",
      cell: (item: Dataset) => numberToString(item.AnnotationVersion)
    },
    {
      id: "annotationTypes",
      header: "Annotation types",
      cell: (item: Dataset) => item.AnnotationTypes.reduce((prev, curr) => `${prev}, ${curr}`)
    },
  ]

  componentDidMount = async () => {
    const idToken = getIdentityToken(this.props.user.cognitoUser)
    const project = await getProject(this.props.match.params.project, idToken, this.props.stage)
    this.setState({project: project, loading: false})
  }

  getProjectMetadata = (project: Project) => [
    {
      name: "Training",
      metadata: [
        { 
          key: "Number of training datasets", 
          value: `${numberToString(project.Train.DatasetCount)} datasets`
        },
        {
          key: "Number of training assets", 
          value: `${numberToString(project.Train.AssetCount)} assets`
        },
      ]
    },
    {
      name: "Testing",
      metadata: [
        {
          key: "Number of testing datasets", 
          value: `${numberToString(project.Test.DatasetCount)} datasets`
        },
        {
          key: "Number of testing assets", 
          value: `${numberToString(project.Test.AssetCount)} assets`
        }
      ]
    }
  ]

  render() {
    const { project, loading } = this.state
    const metadata = this.getProjectMetadata(project)
    return <div>
        <PageTitle title={`${this.props.match.params.project} datasets`} />
        <PageSubtitle content={`Aggregate metadata and list of all datasets for the ${this.props.match.params.project} project`}/>
        <div className="awsui-util-container awsui-util-no-gutters">
          <div className="awsui-util-container-header">
            <h2>Project details</h2>
          </div>
          <div>
            <ColumnLayout
              borders="horizontal"
              className="awsui-util-no-gutters"
            >
              <div data-awsui-column-layout-root={true}>
                <ColumnLayout
                  columns={2}
                  variant="text-grid"
                  className="awsui-util-ph-l awsui-util-pb-l awsui-util-pt-m"
                >
                  <div data-awsui-column-layout-root="true">
                    {
                      metadata.map(data => (
                        <div key={data.name}>
                          <div className="awsui-util-spacing-v-s">
                            <h3 className="awsui-util-p-n">{data.name}</h3>
                            {
                              data.metadata.map(keyValue => (
                                <div key={keyValue.key}>
                                  <div className="awsui-util-label">{keyValue.key}</div>
                                  <div>{keyValue.value}</div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </ColumnLayout>
              </div>
              <AnimatedTable
                  variant="borderless"
                  loading={loading}
                  loadingText="Loading datasets"
                  columnDefinitions={this.columnDefinitions}
                  items={project.Datasets}
                  header={<h2>Datasets <TableCounter>{` (${project.Datasets.length})`}</TableCounter></h2>}
                  wrapLines={false}
                  empty={<EmptyTableView itemType="datasets" />}>
                  <TableFiltering filteringPlaceholder="Find datasets" />
                  <TablePagination pageSize={PAGE_SIZE} />
                  <TableSorting
                      sortableColumns={[
                          { id: "name", field: "Name" },
                          { id: "type", field: "DatasetType" },
                          { id: "numAssets", field: "Count" },
                          { id: "version", field: "AnnotationVersion" },
                          { id: "annotationTypes", field: "AnnotationTypes" },
                          { id: "date", field: "ExportedOn" },
                      ]}
                  />
                  <TableSelection />
                </AnimatedTable>
            </ColumnLayout>
          </div>
        </div>
      </div>
}
}