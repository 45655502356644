import React, { Component } from "react";
import { Header } from "../Components/Homepage/Header";
import { FAQ } from "../Components/Homepage/FAQ";
import { Actions } from "../Components/Homepage/ActionButtons";
import { NewFeaturesTable } from "../Components/Homepage/NewFeaturesTable";

export default class HomeView extends Component {
  render = () => {
    return (
      <div>
        <Header></Header>
        <div className="awsui-grid awsui-util-p-s awsui-util-m-m">
          <div className="awsui-row">
            <div className="col-xxxs-12 col-s-8">
              <Actions />
              <FAQ />
            </div>
            <div className="col-xxxs-12 col-s-4">
              <NewFeaturesTable />
            </div>
          </div>
        </div>
      </div>
    );
  };
}
