import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const Dropzone = styled.div`
  display: flex;
  height: 70vh;
  padding: 20px;
  align-items: center;
  && {
    h3 {
      margin: auto;
      text-align: center;
    }
  }
`;

export const ImageDropzone = (
  onDrop: (files: Array<File>) => void,
  maxImageSize: number
) => {
  const dropzoneArguments = {
    multiple: true,
    maxSize: maxImageSize,
    accept: "image/*, .zip, video/*",
    onDrop: onDrop,
    noKeyboard: true
  };
  const { getRootProps, getInputProps } = useDropzone(dropzoneArguments);
  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <h2>Upload zip/image/video files</h2>
      </div>
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <h3>Drag 'n' drop files here, or click to select files to upload</h3>
      </Dropzone>
    </div>
  );
};
