import React from "react";
import styled from "styled-components";

const lightGrey = "var(--awsui-color-grey-300)",
  headerBackground = "var(--awsui-color-aws-squid-ink)";

const StyledHeader = styled.div`
    color: white;
    background: ${headerBackground};
    .header-description {
      color: ${lightGrey};
      font-weight: lighter;
    }
  `;


export const Header = () => (
    <StyledHeader className="awsui-util-p-xxl">
      <div className="awsui-grid awsui-util-p-s">
        <div className="awsui-row">
          <div className="col-12">
            <div className="awsui-text-large">
              <strong>Rekognition Thunder</strong>
            </div>
            <div>
              <h3 className="header-description">
                Search, generate, and manage the Computer Vision data you require
              </h3>
            </div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );