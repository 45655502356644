import React, { useEffect, useState } from "react";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import styled from "styled-components";
import * as Styled from "../IdentityDetails/StyledComponents";
import { ParsedIdentityAssets } from "../../API/Parsers/Types";
import ScrollWrapper from "../ScrollWrapper";

const FaceImage = styled.img`
	height: 100%;
	width: 100%;
	objectFit: 100%;
`;

const FACE_TASK_TYPE = 'Face';
const PAGE_SIZE = 24;
const SCROLL_DEBOUNCE_TIME = 100;
const LOAD_MORE_THRESHOLD = 0.75;
let container: HTMLElement;

let onWindowScroll = (ev: Event, queryFrom: number, setQueryFrom: Function) => {
	if(checkScrollPosition()){
		setQueryFrom(queryFrom + PAGE_SIZE);
	}
};

/* 
	Calculates scroll position. 
	0 means at the top, 1 means at the bottom. 
	We want to load before they hit the bottom, so check if they are at or above the threshold
*/
const checkScrollPosition = () => {
	if(!container){
		container = document.querySelector('.awsui-app-layout__content') as HTMLElement;
	}

	const { clientHeight, scrollHeight, scrollTop } = container;

	return (scrollTop / (scrollHeight - clientHeight) >= LOAD_MORE_THRESHOLD);
}

const generateImages = (images: ParsedIdentityAssets, isImageMarked: Function, onImageMark: Function) => {
	return images.map((img, i) => (
		<Styled.ImageContainer
			key={i}
			className={isImageMarked(img) ? 'marked' : ''}>
    		<FaceImage
      			src={img.url}
      			key={i} />
   			<div className='image-actions'>
        		<Checkbox
          			label='Mark as incorrect'
          			onChange={(e: CustomEvent<Checkbox.ChangeDetail>) => onImageMark(e, img)}
          			checked={isImageMarked(img)} />
    		</div>
    		<div className='overlay'></div>
  		</Styled.ImageContainer>
	))
};

const FaceImages = (props: { getImages: Function, images: ParsedIdentityAssets, isImageMarked: Function, onImageMark: Function, setImages: Function}) => {
	const { getImages, images, isImageMarked, onImageMark, setImages } = props;
	const [queryFrom, setQueryFrom] = useState(0);
	const defaultArr: ParsedIdentityAssets = [];
	const [cachedImages, setCachedImages] = useState(defaultArr);

	useEffect(() => {
		getImages(FACE_TASK_TYPE, setImages, queryFrom, PAGE_SIZE);
	}, [queryFrom]);

	useEffect(() => {
		setCachedImages(cachedImages.concat(images));
	}, [images]);

	return (
		<ScrollWrapper debounceTime={SCROLL_DEBOUNCE_TIME} onWindowScroll={(ev: Event) => onWindowScroll(ev, queryFrom, setQueryFrom)}>
			<Styled.ImagesContainer>
				{generateImages(cachedImages, isImageMarked, onImageMark)}
	        </Styled.ImagesContainer>
        </ScrollWrapper>
	);
};

export default FaceImages;