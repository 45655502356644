import React from "react";
import styled from "styled-components";
import Button from "@amzn/awsui-components-react/polaris/button";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { ImagePreviewToken } from "./CustomTokenGroup";
import { Link } from "react-router-dom";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Select from "@amzn/awsui-components-react/polaris/select";
import { supportedProjects } from './projects'

type ImageData = {
  image: File;
  previewUrl: any;
  date: number;
  size: number;
};

const ImageListContainer = styled.div`
  overflow-y: auto;
  height: 70vh;
`,
StyledLink = styled(Link)`
&& {
  color: black;
  :hover {
    text-decoration: none;
  }
}
`

const ProjectSelector = styled.div`
  width: 250px;
  margin: 5px;
`

const filePreviews = (
  images: Array<ImageData>,
  onRemoveImage: (url: string) => void
) => {
  return images.map((image: ImageData, i) => {
    return (
      <div key={i}>
        {ImagePreviewToken(
          image.image,
          image.previewUrl,
          image.size,
          onRemoveImage
        )}
      </div>
    );
  });
};

const options = supportedProjects.map(project => ({
  id: project,
  label: project
}))

export const UploadListContainer = (
  numFiles: number,
  uploadImages: () => void,
  onRemoveImage: (url: string) => void,
  imagesArray: Array<ImageData>,
  uploading: boolean,
  uploadSuccess: boolean,
  project: string,
  onChangeProject: (project: string) => void
) => {
  const selectedOption = { id: project, label: project}
  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>
              Files to Upload <Badge content={numFiles}></Badge>
            </h2>
          </div>
          <div className="awsui-util-action-stripe-group">
            <ProjectSelector>
              <Select 
                selectedOption={selectedOption}
                options={options}
                onChange={(event) => {
                  onChangeProject(event.detail.selectedOption.label)
                }} 
              />
            </ProjectSelector>
            <Button
              text="Upload"
              variant="primary"
              onClick={uploadImages}
              disabled={imagesArray.length === 0}
            ></Button>
          </div>
        </div>
      </div>
      <ImageListContainer>
        <ColumnLayout columns={3}>
          {uploading ? (
            <div className="awsui-util-t-c">
              <Spinner size={"large"} />
              <h2>Uploading files, please remain on page</h2>
            </div>
          ) : uploadSuccess ? (
            <div className="awsui-util-t-c awsui-util-spacing-v-s">
              <Icon
                size="big"
                variant="success"
                name="status-positive"
              ></Icon>
              <h2>Files successfully uploaded!</h2>
              <Button>
                <StyledLink to="/">Return to Homepage</StyledLink>
              </Button>
            </div>
          ) : (
            <div data-awsui-column-layout-root="true">
              {filePreviews(imagesArray, onRemoveImage)}
            </div>
          )}
        </ColumnLayout>
      </ImageListContainer>
    </div>
  );
};
