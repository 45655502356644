import React, { useState } from "react";
import styled from "styled-components";
import Button from "@amzn/awsui-components-react/polaris/button";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from "@amzn/awsui-components-react/polaris/select";
import uuid from "uuid/v4"; // v4 Random

const FormContainer = styled.div`
  && .awsui-form-field-controls {
    margin-bottom: 1rem;
  }
`;

type FormData = {
  name: string;
  gender: string;
  yearOfBirth: string;
  populationGroup: string;
  id: string;
};

type Props = {
  submitForm: (submittedData: FormData) => void;
};

const GenderOptions = [
  {
    label: "Female",
    id: "1580tv"
  },
  {
    label: "Male",
    id: "Q65810972"
  }
];

const PopulationGroupOptions = [
  {
    label: "African or African American",
    id: "Q49085"
  },
  {
    label: "East Asian",
    id: "Q26764649"
  },
  {
    label: "South Asian",
    id: "Q1060646"
  },
  {
    label: "Caucasian",
    id: "Q71296091"
  },
  {
    label: "Hispanic or Latino",
    id: "Q58669"
  },
  {
    label: "Middle Eastern",
    id: "Q7029822"
  },
  {
    label: "Native American, Hawaiian or Other",
    id: "Q67128428"
  },
  {
    label: "Unsure",
    id: "Q00000"
  }
];

const yearOptions = () => {
  const currYear: number = new Date().getFullYear();
  const yearList: string[] = Array.from(new Array(200), (val, index) =>
    (currYear - index).toString()
  );
  return yearList.map((year, index) => {
    return { label: year, id: index };
  });
};

export const IdentityForm = ({ submitForm }: Props) => {
  const [gender, setGender] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [name, setName] = useState("");
  const [populationGroup, setPopulationGroup] = useState("");
  const [selectedGender, setSelectedGender] = useState<Select.Option | null>(
    null
  );
  const [selectedYear, setSelectedYear] = useState<Select.Option | null>(null);
  const [
    selectedPopGroup,
    setSelectedPopGroup
  ] = useState<Select.Option | null>(null);
  const clearForm = () => {
    setGender("");
    setName("");
    setPopulationGroup("");
    setYearOfBirth("");
    setSelectedPopGroup(null);
    setSelectedGender(null);
    setSelectedYear(null);
  };
  
  const submitDisabled = gender !== "" && yearOfBirth !== "" && name !== "" && populationGroup !== "" ? false : true

  return (
    <div className="awsui-util-spacing-v-s">
      <FormContainer>
        <Form>
          <FormField label="Anonymized name">
            <Input
              value={name}
              onInput={event => setName(event.detail.value)}
            ></Input>
          </FormField>
          <FormField label="Gender">
            <Select
              selectedOption={selectedGender}
              options={GenderOptions}
              onChange={event => {
                setSelectedGender(event.detail.selectedOption);
                setGender(event.detail.selectedOption.label);
              }}
            ></Select>
          </FormField>
          <FormField label="Year of Birth">
            <Select
              selectedOption={selectedYear}
              options={yearOptions()}
              onChange={event => {
                setSelectedYear(event.detail.selectedOption);
                setYearOfBirth(event.detail.selectedOption.label);
              }}
            ></Select>
          </FormField>
          <FormField label="Population Group">
            <Select
              selectedOption={selectedPopGroup}
              options={PopulationGroupOptions}
              onChange={event => {
                setSelectedPopGroup(event.detail.selectedOption);
                setPopulationGroup(event.detail.selectedOption.label);
              }}
            ></Select>
          </FormField>
        </Form>
      </FormContainer>
      <Button
        text="Add Identity"
        variant="primary"
        onClick={() => {
          submitForm({
            name: name,
            gender: gender,
            populationGroup: populationGroup,
            yearOfBirth: yearOfBirth,
            id: uuid()
          });
          clearForm();
        }}
        disabled={submitDisabled}
      ></Button>
    </div>
  );
};
