import React, { useEffect, useRef } from "react";
import { throttle } from "lodash";

const DEFAULT_DEBOUNCE_TIME = 250;

const handleScroll = (ev: Event, onWindowScroll?: Function) => {
	if(onWindowScroll){
		onWindowScroll(ev);
	}
}

const ScrollWrapper = (props: { children: object, onWindowScroll: Function, debounceTime?: number}) => {
	const { children, debounceTime, onWindowScroll } = props;
	const scrollEl = document.getElementsByClassName('awsui-app-layout__content')[0];

	useEffect(() => {
		const throttleHandleScroll = throttle(handleScroll, debounceTime || DEFAULT_DEBOUNCE_TIME);
		const boundFn = (ev: Event) => throttleHandleScroll(ev, onWindowScroll);
		scrollEl.addEventListener('scroll', boundFn);

		return () => {
			scrollEl.removeEventListener('scroll', boundFn);
		}
	}, [onWindowScroll]);

	/*
		The fragment fixes this error:
		JSX element type 'Element[]' is not a constructor function for JSX elements
	*/
	return (
		<>
			{children}
		</>
	);
}

export default ScrollWrapper;