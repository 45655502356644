import { Reducer } from "react";
import {
  getFaceClusterAssets,
  getFacesFromImageUrl,
  getFaceClusterIdentities,
  postFaceClusterAnnotations
} from "../../API/Api";
import { getIdentityToken } from "../../Utils/UserUtils";
import { CognitoUser } from "@aws-amplify/auth";

export type Identity = {
  id: string | null;
  name: string;
  gender: string;
  yearOfBirth: string;
  populationGroup: string;
  age?: number;
};

export type Region = {
  x: number;
  y: number;
  height: number;
  width: number;
  age?: number;
};

type Image = {
  url: string;
  filename: string;
  annotationsComplete?: boolean;
};

interface Face extends Image {
  region: Region;
}

export interface AnnotatedFace extends Face {
  annotation: Identity;
}

type State = {
  identities: Array<Identity>;
  showModal: boolean;
  faces: Array<AnnotatedFace>;
  currentFaceIndex: number;
  images: Array<Image>;
  currentImgFilename: string;
  imagesAvailable: boolean;
  currentAnnotation: Identity;
  identitiesInUse: Array<string>;
  loading: boolean;
  identitiesLoading: boolean;
  imageError: boolean;
  submitDisabled: boolean;
  userAlias: string;
  annotatedFaces: Array<AnnotatedFace>;
  completedImgFilenames: Array<string>;
};

type Action = {
  type: string;
  payload?: any;
};

/**
 * Returns new object with updated properties
 * @param current
 * @param payload
 */
const mergeState = (state: State, payload: any): State =>
  Object.assign({ ...state }, payload);

const parseUserAlias = (cognitoUser: CognitoUser) => {
  const userEmail = cognitoUser.getUsername();
  const alias = userEmail.split("_").pop();
  return alias!.substring(0, alias!.lastIndexOf("@"));
};

export const getIdentities = async (user: any, stage: string) => {
  const idToken = getIdentityToken(user.cognitoUser);
  const alias = parseUserAlias(user.cognitoUser);
  try {
    const identities = await getFaceClusterIdentities(alias, idToken, stage);
    return identities;
  } catch (e) {
    return [];
  }
};

export const postAnnotations = async (
  user: any,
  stage: string,
  postData: any
) => {
  const idToken = getIdentityToken(user.cognitoUser);
  const alias = parseUserAlias(user.cognitoUser);
  try {
    await postFaceClusterAnnotations(postData, idToken, alias, stage);
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserImages = async (user: any, stage: string) => {
  const idToken = getIdentityToken(user.cognitoUser);
  const alias = parseUserAlias(user.cognitoUser);
  const NEW = "NEW";
  try {
    const userImages = await getFaceClusterAssets(alias, idToken, NEW, stage);
    return {
      images: userImages
    };
  } catch (e) {
    return [];
  }
};

export const getImageFaces = async (url: string, user: any, stage: string) => {
  const idToken = getIdentityToken(user.cognitoUser);
  try {
    return await getFacesFromImageUrl(url, idToken, stage);
  } catch (e) {
    return e;
  }
};

/**
 * Generator that gets face bboxes for images
 * @param images
 * @param user
 * @param stage
 */
export const getFacesGenerator = async function*(
  images: Array<{ url: string; filename: string }>,
  user: { cognitoUser: CognitoUser },
  stage: string
) {
  let imgs = [...images];
  while (imgs.length !== 0) {
    const [img] = imgs.splice(0, 1);
    const results = await getImageFaces(img.url, user, stage);
    yield results.map((result: any) => ({
      ...result,
      filename: img.filename
    }));
  }
};

// IDENTITY FORM TEMPLATE
export const identityTemplate = {
  id: null,
  name: "",
  gender: "",
  populationGroup: "",
  yearOfBirth: ""
};

// INITIAL STATE
export const initialState: State = {
  identities: [],
  showModal: false,
  faces: [],
  currentImgFilename: "",
  currentFaceIndex: 0,
  currentAnnotation: identityTemplate,
  identitiesInUse: [],
  loading: true,
  identitiesLoading: true,
  imageError: false,
  submitDisabled: true,
  images: [],
  imagesAvailable: false,
  userAlias: "",
  annotatedFaces: [],
  completedImgFilenames: []
};

// ACTION TYPES
export const SET_STATE = "setState",
  SET_CURRENT_INDEX = "setCurrentIndex",
  DELETE_IDENTITY = "deleteIdentity",
  ADD_IDENTITY = "submitIdentityForm",
  IDENTITY_SELECT = "identitySelect",
  HANDLE_IMAGE_ERROR = "handleImageError",
  SUBMIT_IMAGES = "submitImages",
  ADD_NEW_FACES = "addNewFaces",
  SET_IMG_PATHNAMES = "setImgPathnames",
  SET_ANNOTATED_FACES = "setAnnotatedFaces";

export const UNSURE_ID = "Q00000",
  UNSURE = "Unsure";

export const UNSURE_IDENTITY = {
  id: UNSURE_ID,
  name: UNSURE,
  gender: UNSURE,
  populationGroup: UNSURE,
  yearOfBirth: "0",
  age: 0
};

export const reducer: Reducer<State, Action> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * Generally set new state
     */
    case SET_STATE:
      return mergeState(state, payload);

    /**
     * Set index of current image, set annotation if image at index has one
     */
    case SET_CURRENT_INDEX:
      return mergeState(state, {
        currentFaceIndex: payload,
        currentAnnotation:
          (state.faces[payload] && state.faces[payload].annotation) ||
          identityTemplate,
        imageError: false
      });

    /**
     * Create new identity
     */
    case ADD_IDENTITY:
      return mergeState(state, {
        identities: [...state.identities, payload].sort(
          (a, b) => (a.name < b.name && -1) || (a.name > b.name && 1) || 0
        ),
        showModal: false
      });

    /**
     * Remove UNUSED identity
     */
    case DELETE_IDENTITY:
      const filtered = state.identities.filter(
        ({ id }: Identity) => id !== payload
      );
      return mergeState(state, { identities: filtered });

    /**
     * Handle Identity selection
     */
    case IDENTITY_SELECT:
      // Annotate face
      const faces = state.faces.map(
        (img, i) =>
          (i === state.currentFaceIndex &&
            Object.assign(img, { annotation: payload })) ||
          img
      );

      // Mark identity as in use if not already in use
      const identitiesInUse = state.identitiesInUse.includes(payload.id) ? state.identitiesInUse : [...state.identitiesInUse, payload.id]

      // Get new index
      let currentFaceIndex =
        state.currentFaceIndex !== state.faces.length - 1
          ? state.currentFaceIndex + 1
          : state.currentFaceIndex;

      const currentImgFilename = state.faces[currentFaceIndex].filename;

      const newImageToAnnotate =
        state.currentImgFilename !== currentImgFilename;

      let images;
      if (newImageToAnnotate || !state.imagesAvailable) {
        images = state.images.map(
          image =>
            (image.filename === state.currentImgFilename &&
              Object.assign(image, { annotationsComplete: true })) ||
            image
        );
      }

      const submitDisabled = state.submitDisabled && !newImageToAnnotate && !state.images[state.images.length - 1].annotationsComplete;

      return mergeState(state, {
        faces,
        currentFaceIndex,
        currentImgFilename,
        identitiesInUse,
        submitDisabled,
        currentAnnotation:
          faces[currentFaceIndex].annotation || identityTemplate,
        ...(images ? { images } : {})
      });

    /**
     * Call POST and clearfaces in localStorage
     */
    case SUBMIT_IMAGES:
      // Manage state cleanup on submit
      return state;

    case SET_ANNOTATED_FACES:
      const completedImgFilenames = state.images
        .filter(image => image.annotationsComplete)
        .map(img => img.filename);
      const annotatedFaces = state.faces.filter(
        face => completedImgFilenames.includes(face.filename) && face.annotation
      );
      return mergeState(state, {
        annotatedFaces,
        completedImgFilenames,
        loading: true
      });

    default:
      throw new Error();
  }
};
