import React from 'react'
import styled from 'styled-components'
import Button from '@amzn/awsui-components-react/polaris/button'
import Auth from '@aws-amplify/auth';
import Input from '@amzn/awsui-components-react/polaris/input';

const LoginContainer = styled.div`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 30%;
  min-width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  padding: 4rem 5rem 3rem 5rem;
  box-shadow: 0 2px 5px 0 var(--awsui-color-shadow-medium)
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
`

const Title = styled.div`
    margin-left: 10px;
    font-weight: lighter;
    font-size: 4rem;
`

const Logo = styled.img`
    height: 70px;
    width: 70px;
`

const horizontalRule = styled.div`
    position: relative;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
    clear: both;
    overflow: hidden;
`

const SeparatorText = styled.h3`
    && {
        margin: 20px 0 10px 0;
        font-size: 1.5rem;
    }
`

const LoginForm = styled.div`
    && > * {
        margin: 8px;
        width: 300px;
    };
    display: flex;
    flex-direction: column;
    align-items: center;
`

const AuthenticationView = () => (
    <LoginContainer>
        <Header>
            <Logo src="https://thunder-assets.s3-us-west-2.amazonaws.com/thunder_logo.png"/>
            <Title>Thunder</Title>
        </Header>
        <Button
            text="I am an Amazon employee"
            variant="normal" 
            onClick={() => Auth.federatedSignIn({customProvider: 'Federate'})}
        />
        <SeparatorText>External vendors should login with</SeparatorText>
        <LoginForm>
            <Input type="email" placeholder="Email"></Input>
            <Input type="password" placeholder="Password"></Input>
            <Button text="Login" variant="normal"></Button>
        </LoginForm>
    </LoginContainer>
)

export default AuthenticationView