import React, { useState, useEffect } from "react";
import PageTitle from "../Components/PageTitle";
import PageSubtitle from "../Components/PageSubtitle";
import AnimatedTable from "../Components/AnimatedTable";
import TableFiltering from "@amzn/awsui-components-react/polaris/table-filtering";
import TablePropertyFiltering from "@amzn/awsui-components-react/polaris/table-property-filtering";
import TablePagination from "@amzn/awsui-components-react/polaris/table-pagination";
import TableSelection from "@amzn/awsui-components-react/polaris/table-selection";
import TableSorting from "@amzn/awsui-components-react/polaris/table-sorting";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { generateIdentityLink } from "../Routes/RouteConfig";
import {
  getAssetsForDataset,
  Dataset,
  emptyDataset,
  getQAIdentities,
  getQAIdentityData
} from "../API/Api";
import EmptyTableView from "../Components/EmptyTableView";
import styled from "styled-components";
import { numberToString } from "../Utils/StringUtils";
import { CognitoUser } from "@aws-amplify/auth";
import { getIdentityToken } from "../Utils/UserUtils";
import { ParsedIdentityData, ParsedIdentityDataItem } from "../API/Parsers/Types";
import { updateUrlHashParams } from "../Utils/UpdateUrl";

const PAGE_SIZE = 20;

type Props = {
  match: {
    params: {
      project: string;
      dataset: string;
    };
    url: string;
  };
  location: {
    data: Dataset;
  };
  stage: string;
  user: {
    cognitoUser: CognitoUser;
  };
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const createColumnDefinitions = (url: string) => [
  {
    id: "name",
    header: "Identity name",
    cell: (item: ParsedIdentityDataItem) =>
      generateIdentityLink(url, item.label_id, item.name)
  },
  {
    id: "id",
    header: "Identity id",
    cell: (item: ParsedIdentityDataItem) => item.label_id
  },
  {
    id: "gender",
    header: "Gender",
    cell: (item: ParsedIdentityDataItem) => item.gender
  },
  {
    id: "population_group",
    header: "Population group",
    cell: (item: ParsedIdentityDataItem) => item.population_group
  },
  {
    id: "checked_for_QA",
    header: "Checked for QA",
    cell: (item: ParsedIdentityDataItem) => (item.verified_gender || item.verified_population_group ? 'Yes' : 'No')
  }
];

const filteringOptions = [
  {
    groupValuesLabel: "Gender Values",
    propertyKey: "gender",
    propertyLabel: "Gender",
    values: ["Female", "Male"]
  },
  {
    groupValuesLabel: "Population Group Values",
    propertyKey: "populationGroup",
    propertyLabel: "Population Group",
    values: [
      "African or African American",
      "Caucasian",
      "East Asian",
      "Hispanic or Latino",
      "South Asian",
      "Middle Eastern",
      "Native American, Hawaiian or Other"
    ]
  },
  {
    groupValuesLabel: "Checked for QA Values",
    propertyKey: "checkedForQA",
    propertyLabel: "Checked for QA",
    values: ['Yes', 'No']
  }
];

const renderTable = (
  pageUrl: string,
  isLoading: boolean,
  assets: ParsedIdentityData,
  updateFilters: (filters: TablePropertyFiltering.FilteringToken[]) => void,
  hasMore: boolean,
  numPages: number,
  setNumPages: React.Dispatch<React.SetStateAction<number>>,
  getIdentities: (query_from: number, return_size: number) => void,
  currentPageIndex: number,
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>,
  totalIdentityCount: number
) => (
  <AnimatedTable
    loading={isLoading}
    loadingText="Loading assets"
    columnDefinitions={createColumnDefinitions(pageUrl)}
    items={assets}
    wrapLines={false}
    header={
      <h2>
        Identities{" "}
        <span className="awsui-util-header-counter">
          ({totalIdentityCount})
        </span>
      </h2>
    }
    empty={<EmptyTableView itemType="assets" />}
  >
    <TablePropertyFiltering
      filteringOptions={filteringOptions}
      groupValuesText="Values"
      groupPropertiesText="Attributes"
      clearFiltersText="Clear filters"
      placeholder="Filter identities by attributes"
      allowFreeTextFiltering={true}
      hideOperations={true}
      filteringCountTextFunction={count =>
        `${assets.length} ${assets.length === 1 ? 'match': 'matches'}`
      }
      filteringFunction={null}
      onPropertyFilteringChange={(
        e: CustomEvent<TablePropertyFiltering.ChangeDetail>
      ) => updateFilters(e.detail.tokens)}
      onFilteringDelayedInput={(
        e: CustomEvent<TablePropertyFiltering.InputChangeDetail>
      ) => console.log(e.detail)}
    />
    <TablePagination
      pageSize={PAGE_SIZE}
      openEnd={true}
      currentPageIndex={currentPageIndex}
      onNextPageClick={(e: CustomEvent<TablePagination.PageClickDetail>) => {
        if (hasMore && e.detail.requestedPageIndex === numPages + 1) {
          // update query_from and return_size
          getIdentities(0, PAGE_SIZE);
          setCurrentPageIndex(e.detail.requestedPageIndex);
          setNumPages(numPages + 1);
        }
      }}
      onPaginationChange={e => {
        setCurrentPageIndex(e.detail.currentPageIndex);
      }}
    />
    <TableSorting
      sortableColumns={[
        { id: "name", field: "name" },
        { id: "gender", field: "gender" },
        { id: "population_group", field: "populationGroup" },
        { id: "last_qa_date", field: "lastQADate" },
        { id: "checked_for_QA", field: "checkedForQA" }
      ]}
    />
    <TableSelection />
  </AnimatedTable>
);

const IdentityDatasetView = (props: Props) => {
  const datasetName = props.match.params.dataset;
  const pageUrl = props.match.url;
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [dataset, setDataset] = useState<Dataset>(emptyDataset);
  const [continuationToken, setContinuationToken] = useState("");
  const [queryExecutionId, setQueryExecutionId] = useState("");
  const [identities, setIdentites] = useState<ParsedIdentityData>([]);
  const [numPages, setNumPages] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalIdentityCount, setTotalIdentityCount] = useState(0);

  const getIdentities = async (query_from: number, return_size: number, updatedFilters?: any) => {
    const idToken = getIdentityToken(props.user.cognitoUser);
    const queryParams = updatedFilters ? updatedFilters : filters;
    const response = await getQAIdentityData(idToken, { 
      query_from, 
      return_size,
      task_type: 'Face',
      annotation_type: 'LabelVerification'
    }, props.stage);

    setContinuationToken("");
    setQueryExecutionId("");
    setHasMore(false);
    setTotalIdentityCount(response.length);
    setIdentites(response);
    setIsLoading(false);
  };

  const updateFilters = (filters: TablePropertyFiltering.FilteringToken[]) => {
    var newFilters: any = {};
    filters.map(filter => {
      newFilters[filter.propertyKey] = filter.value;
    });
    setFilters(newFilters);
    updateUrlHashParams(newFilters);
    getIdentities(0, PAGE_SIZE, newFilters);
  };

  useEffect(() => {
    getIdentities(0, PAGE_SIZE);
  }, []);

  return (
    <div>
      {
        renderTable(
          pageUrl,
          isLoading,
          identities,
          updateFilters,
          hasMore,
          numPages,
          setNumPages,
          getIdentities,
          currentPageIndex,
          setCurrentPageIndex,
          totalIdentityCount
        )
    }
    </div>
  );
};

export default IdentityDatasetView;
