/**
 * Calls a function that is repeatedly called only once during a
 * given wait period
 * 
 * @param func : The function to call
 * @param waitMillis : The duration to listen to any additional function calls,
 *                      so this method only calls the function once during
 *                      that wait period. 
 */
export const debounce = (func: Function, waitMillis: number) => {
    let timeout: number
    
    return function(this: any, ...args: any) {
        const functionCall = () => func.apply(this, args)
        clearTimeout(timeout)
        timeout = setTimeout(functionCall, waitMillis)
    }
}