import queryString from "query-string";

export const updateUrlHashParams = (params: any) => {
    const queryParams = queryString.stringify(params);
    const existingHash = window.location.hash;
    const paramsIndex = existingHash.indexOf("?");
    window.location.hash =
      paramsIndex === -1
        ? existingHash.substring(paramsIndex) + "?" + queryParams
        : existingHash.substring(0, paramsIndex) + "?" + queryParams;
  };