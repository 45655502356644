import React, {useEffect, useState, Fragment, ComponentType} from 'react'
import ReactDOM from 'react-dom'
import styled, {keyframes, css} from 'styled-components'
import Icon from '@amzn/awsui-components-react/polaris/icon'
import { Url } from '../API/Api'

const NUM_IMAGES_FROM_THE_END_TO_FETCH = 10

export type Dimensions = {
    x: number,
    y: number,
    width: number,
    height: number, 
}

type Props = {
    image: null | Url,
    nextImage: Url,
    prevImage: Url,
    showModal: boolean,
    onDismiss: () => void,
    onMoveNextRequest: () => void,
    onMovePrevRequest: () => void,
    ActionBar?: ComponentType<any>,
    currentImageNumber: number,
    numberOfImages: number,
    dimensions: Dimensions,
    loadMoreImages?: () => void
}

type AnimationState = 0 | 1 | 2 | 3
const AnimationState: {INIT: 0, BEGIN: 1, NONE: 2, END: 3} = {
    INIT: 0,
    BEGIN: 1,
    NONE: 2,
    END: 3,
}

type OverlayProps = {
    animate: AnimationState,
}

const BeginOverlayAnimation = keyframes`
    0% {
        opacity: 0;
        z-index: -100;
    }
    30% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
        z-index: 2000;
    }
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: rgba(243,243,243,0.95);
    opacity: ${props => props.animate === AnimationState.NONE ? 1 : 0};
    z-index: ${props => props.animate === AnimationState.NONE ? 2000 : -100};
    animation: ${(props: OverlayProps) => {
        if(props.animate === AnimationState.BEGIN) {
            return css`${BeginOverlayAnimation} linear forwards`
        }
        if(props.animate === AnimationState.END) {
            return css`${BeginOverlayAnimation} linear reverse`
        }
        return 'none'
    }};
    animation-duration: 0.5s;
    min-width: 800px;
    overflow-y: scroll;
`
const Counter = styled.div`
    position: fixed;
    height: auto;
    bottom: 18px;
    right: 30px;
    text-align: right;
    user-select: none;
`

const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    margin: 2.5rem 1rem;
    justify-content: space-between;
    align-items: center;
`

const Title = styled.h1`
    && {
        margin: 0 1.5rem;
        font-weight: 400;
        cursor: text;
    }

    @media (max-width: 750px) {
        && {
            font-size: 2.2rem;
        }
    }
`

const IconContainer = styled.span`
    margin: 0 1.5rem;
    color: #6d737a;
    :hover {
        color: #000;
    }
`

const ArrowIconContainer = styled.span`
    position: fixed;
    top: 50%;
    ${(props: {left:boolean}) => props.left ? `left: 20px` : `right: 20px`}
    color: #6d737a;
    :hover {
        color: #000;
    }
`
const Box = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  background-color: #fafafa;
  border: 2px solid #eaeded;
  transition: all 0.5s linear;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`
const KeyboardInput = styled.span`
  color: #df3312;
  font-size: 1.4rem;
  font-family: Monaco,Menlo,Consolas,Courier Prime,Courier,Courier New,monospace;
`
const HintGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  align-items: center;
`
const KeyboardShortcuts = styled.div`
    position: fixed;
    left: 10px;
    bottom: 20px;
`

type AnimationProps = {
    originalDimensions: Dimensions,
    width: number,
    height: number,
    animate: AnimationState,
}

const smoothPositioning = (props: AnimationProps) => keyframes`
    0% {
        left: ${props.originalDimensions.x}px;
        top: ${props.originalDimensions.y}px;
        width: ${props.originalDimensions.width}px;
        height: ${props.originalDimensions.height}px;
        margin-left: 0px;
    }
    100% {
        width: ${props.width}px;
        height: ${props.height}px;
        top: 100px;
        left: 50%;
        margin-left: ${-props.width/2}px;
    }
`

const Image = styled.img`
    position: absolute;
    width: ${props => props.animate === AnimationState.NONE ? props.width : props.originalDimensions.width}px;
    height: ${props => props.animate === AnimationState.NONE  ? props.height : props.originalDimensions.height}px;
    top: ${props => props.animate === AnimationState.NONE  ? 100 : props.originalDimensions.y}px;
    left: ${props => props.animate === AnimationState.NONE  ? `${50}%` : `${props.originalDimensions.x}px`};
    margin-left: ${props => props.animate === AnimationState.NONE ? -props.width/2 : 0}px;
    animation: ${(props: AnimationProps) => {
        if(props.animate === AnimationState.BEGIN) {
            return css`${smoothPositioning} cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards`
        }
        if(props.animate === AnimationState.END) {
            return css`${smoothPositioning} cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse`
        }
        return 'none'
    }};
    animation-duration: 0.3s;
`

const calcuateImageDimensions = (imageWidth: number, imageHeight: number): {width: number, height: number} => {
    if(imageWidth <= 0 || imageHeight <= 0) return {width: 0, height: 0}
    const windowWidth = window.innerWidth
    const width = 0.5 * windowWidth
    const height = width * imageHeight / imageWidth
    return {height, width}
}

const renderKeyboardShortcutHelper = () => (
    <KeyboardShortcuts>
        <h3>Keyboard shortcuts</h3>
        <HintGroup>
            <Box><KeyboardInput><Icon variant="normal" name="caret-right-filled"/></KeyboardInput></Box>
            <p>Next image</p>
        </HintGroup>
        <HintGroup>
            <Box><KeyboardInput><Icon variant="normal" name="caret-left-filled"/></KeyboardInput></Box>
            <p>Previous image</p>
        </HintGroup>
        <HintGroup>
            <Box><KeyboardInput>Esc</KeyboardInput></Box>
            <p>Exit view</p>
        </HintGroup>
    </KeyboardShortcuts>
)

const preventParentClickBehavior = (event: React.MouseEvent) => event.stopPropagation()

const ImageModal = (props: Props) => {
    const [animate, setAnimate] = useState<AnimationState>(AnimationState.INIT)
    const [modalState, setModalState] = useState(false)
    const closeOverlay = (event: React.MouseEvent) => {
        preventParentClickBehavior(event)
        setAnimate(AnimationState.END)
        props.onDismiss()
    }
    const onMoveNext = (event: React.MouseEvent) => {
        preventParentClickBehavior(event)
        props.onMoveNextRequest()
    }
    const onMovePrev = (event: React.MouseEvent) => {
        preventParentClickBehavior(event)
        props.onMovePrevRequest()
    }
    const onKeyPress = (event: KeyboardEvent) => {
        if(event.defaultPrevented) return
        switch(event.key) {
            case "ArrowLeft":
                props.onMovePrevRequest()
                break
            case "ArrowRight":
                props.onMoveNextRequest()
                break
            case "Escape":
                setAnimate(AnimationState.END)
                props.onDismiss()
                break
            default:
                return
        }
        event.preventDefault()
    }

    useEffect(function animationStateMachine() {
        // The modal is 'off' but we need need it 'on'
        if(props.showModal && !modalState) {
            setAnimate(AnimationState.BEGIN)
            setModalState(props.showModal)
        }
        // The modal is 'on' but we need it 'off'
        else if(!props.showModal && modalState) {
            setAnimate(AnimationState.END)
            setModalState(props.showModal)
        }
        if(animate === AnimationState.BEGIN) {
            setTimeout(() => setAnimate(AnimationState.NONE), 500)
        }
        if(animate === AnimationState.END) {
            setTimeout(() => setAnimate(AnimationState.INIT), 500)
        }
    })

    useEffect(function subscribeToKeyPress() {
        document.addEventListener('keydown', onKeyPress)
        return () => {
            document.removeEventListener('keydown', onKeyPress)
        }
    })

    useEffect(function attachFullScreenOverlay() {
        const div = document.createElement('div')
        const app = document.getElementsByClassName('awsui-app-layout')[0]
        app.appendChild(div)
        ReactDOM.render(renderOverlay(), div)

        return () => {
            ReactDOM.unmountComponentAtNode(div)
            app.removeChild(div)
        }
    })

    useEffect(() => {
        if(props.currentImageNumber + NUM_IMAGES_FROM_THE_END_TO_FETCH === props.numberOfImages) {
            props.loadMoreImages && props.loadMoreImages()
        }
    })

    const renderOverlay = () => {
        const {ActionBar, currentImageNumber, numberOfImages, dimensions, image} = props
        const {height, width} = calcuateImageDimensions(dimensions.width, dimensions.height)
        return (
            <Overlay onClick={closeOverlay} animate={animate}>
                <Counter>
                    {`${currentImageNumber} of ${numberOfImages}`}
                </Counter>
                {
                    renderKeyboardShortcutHelper()
                }
                <TopBar>
                    <Title onClick={preventParentClickBehavior}>{image && image.name}</Title>
                    <IconContainer onClick={closeOverlay}>
                        <Icon name="close" size="big" />
                    </IconContainer>
                </TopBar>
                {ActionBar && <ActionBar />}
                {
                    props.prevImage && <ArrowIconContainer left={true} onClick={onMovePrev}>
                        <Icon name="angle-left" size="big" />
                    </ArrowIconContainer>
                } 
                {
                    props.nextImage && <ArrowIconContainer left={false} onClick={onMoveNext}>
                        <Icon name="angle-right" size="big" />
                    </ArrowIconContainer>
                }
                <Image animate={animate} originalDimensions={dimensions} width={width} height={height} src={props.image ? props.image.url : ''} />
            </Overlay>
        )
    }

    return (
        <Fragment />
    )
}

export default ImageModal