import React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { IdentityForm } from "./IdentityForm";

type FormData = {
  name: string;
  gender: string;
  yearOfBirth: string;
  populationGroup: string;
  inUse?: boolean;
};

type Props = {
  showModal: boolean;
  submitForm: (submittedData: FormData) => void;
  onClose?: () => void;
};

export const IdentityFormModal = ({
  showModal,
  submitForm,
  onClose
}: Props) => (
  <Modal
    header="Add new identity"
    content={<IdentityForm submitForm={submitForm}></IdentityForm>}
    visible={showModal}
    expandToFit={true}
    onDismiss={onClose}
  ></Modal>
);
