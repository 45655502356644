import React from 'react'
import FormSection from '@amzn/awsui-components-react/polaris/form-section'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group'
import {getActions, checkStepDone} from '../Utils'
import {StepConfig, WorkflowAttrs} from '../Interfaces'
import {IStep} from "../../../Utils/Marvin_Wizard/interfaces";
import {WorkflowConfigs} from '../Config'

const WorkflowType = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const items = Object.keys(WorkflowConfigs).map(
        (key: string): {
            value: string,
            label: string
        } => ({
            value: key,
            label: WorkflowConfigs[key]['workflowName']
        })
    )
    const currData = props.getData()
    return <FormSection
        header="Select Workflow Type"
    >
        <div
            className="wrap-wizard-content"
            data-awsui-column-layout-root="true"
        >
            <FormField
                className="awsui-util-d-b awsui-util-mb-m"
                stretch={true}
            >
                <RadioGroup
                    items={items}
                    onChange={(e) => {
                        props.setData({
                            ...currData,
                            workflowType: e.detail.value
                        })
                    }}
                    value={currData.workflowType}
                />
            </FormField>
        </div>
    </FormSection>
}

export default (config: StepConfig): IStep => {
    const currData = config.getData()
    return {
        id: 'step_workflow_type',
        content: <WorkflowType
            getData={config.getData}
            setData={config.setData}
        />,
        title: 'Select workflow type',
        onClick: (stepIndex: number) => {
            return (e: any) => {
                config.toStep(stepIndex)
            }
        },
        actions: getActions(
            config.index,
            config.totalStep,
            config.toStep,
            checkStepDone(config.index, currData)
        )
    }
}
