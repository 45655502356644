import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import cropImage, { Padding, Region } from "../Utils/CropImage";
type Props = {
  url: string;
  region: Region;
  style?: Object;
  padding?: Padding | number;
  fallback?: JSX.Element;
  onError?: Function;
  onClick?: Function;
};

// Styles
const Canvas = styled.canvas`
  max-width: 100%;
  max-height: 100%;
`;

export default (props: Props) => {
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const canvas = useRef(null);

  const shouldUpdate = () =>
    (props.url !== currentUrl && canvas.current) || currentUrl !== "";

  const drawCanvas = () => (
    setError(false),
    cropImage(props.url, props.region, canvas.current, props.padding).catch(
      e => (setError(true), props.onError && props.onError(props.url))
    )
  );

  // Watch for prop changes
  useEffect(() => {
    if (shouldUpdate()) {
      setCurrentUrl(props.url);
      process.nextTick(() => drawCanvas());
    }
  }, [props]);

  return (
    (!error && <Canvas style={props.style} ref={canvas} onClick={(e) => props.onClick && props.onClick(e, props.url)} />) ||
    props.fallback ||
    null
  );
};
