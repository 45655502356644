import React, {Fragment} from 'react'
import FormSection from '@amzn/awsui-components-react/polaris/form-section'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import Textarea from '@amzn/awsui-components-react/polaris/textarea'
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group'
import {CheckboxField, TextField, TextSelector, FeatureSection} from '../FormComponents'
import {getActions, checkStepDone} from '../Utils'
import {StepConfig, WorkflowAttrs} from '../Interfaces'
import {IStep} from "../../../Utils/Marvin_Wizard/interfaces";
import {assetSourceTypes, getIngestionAccounts} from '../Config'


// Hook for Dataset related form elements
const DatasetBlock = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => <Fragment>
        <TextField
            getData={props.getData}
            setData={props.setData}
            attrs={{
                name: "Dataset ID",
                id: 'datasetId',
                required: true,
                validation: [{
                    pattern: /^[\w-]+$/,
                    errorMsg: "Dataset IDs should only contain letters, numbers, underscores and hyphens."
                }]
            }}
        />
        <CheckboxField
            getData={props.getData}
            setData={props.setData}
            attrs={{
                name: "The assets in the above dataset have been rectified.",
                id: 'isRectified',
                required: false
            }}
        />
    </Fragment>

// Hook for asset source type selector
const SourceTypeSelector = (props: {
    getData: () =>  WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const currData = props.getData(),
    onAssetSourceChange = (e:any) => {
        props.setData({
            ...props.getData(),
            assetSourceType: e.detail.value
        })
    }
    return <FormField
        className="awsui-util-d-b awsui-util-mb-m"
        stretch={true}
        label="Asset Source Type"
    >
        <RadioGroup
            items={Object.keys(assetSourceTypes).map(
                key => ({
                    value: key,
                    ...assetSourceTypes[key]
                })
            )}
            onChange={onAssetSourceChange}
            value={currData.assetSourceType}
        />
    </FormField>
}

// Hook for Asset Lists Asset List
const AssetListTextarea = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const currData = props.getData()
    const onAssetListChange = (e:any) => {
        props.setData({
            ...currData,
            assetsList: e.detail.value
        })
    }

    return <FormField
        className="awsui-util-d-b awsui-util-mb-m"
        stretch={true}
        label="List of Asset"
    >
        <Textarea 
            placeholder="Put the AssetIDs in the textarea, one line for each asset"
            rows={10}
            value={currData.hasOwnProperty('assetsList')?currData.assetsList:""}
            onChange={onAssetListChange}
        ></Textarea>
    </FormField>
}

// Hook for Asset Lists Asset List
const IngestionBlock = (props: {
    stage: string,
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const listAccounts = getIngestionAccounts(props.stage)
    return <FeatureSection title="Asset ingestion config">
        <TextSelector
            {...props}
            attrs={{
                name: "Account for Ingestion",
                id: 'ingestionAccount',
                required: true,
                options: listAccounts.map(
                    (account, i) => ({
                        id: account,
                        label: account
                    })
                )
            }}
        />
        <TextField
            {...props}
            attrs={{
                name: "S3 Bucket",
                id: 's3Bucket',
                required: true
            }}
        />
        <TextField
            {...props}
            attrs={{
                name: "S3 Prefix",
                id: 's3Prefix',
                required: false
            }}
        />
        <CheckboxField
            {...props}
            attrs={{
                name: "Reuse existing Dataset",
                id: 'reuseExistingDataset',
                required: false
            }}
        />
        <TextField
            {...props}
            attrs={{
                name: "Metadata - Source",
                id: 'source',
                required: false
            }}
        />
    </FeatureSection>
}

// Main Hook for the component
const AssetSource = (props: {
    stage: string,
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const currData = props.getData(),
    assetSourceTypeSelected = currData.assetSourceType

    return <FormSection
        header="Select Asset Source"
    >
        <div
            className="wrap-wizard-content"
            data-awsui-column-layout-root="true"
        >
            <DatasetBlock
                getData={props.getData}
                setData={props.setData}
            />
            <SourceTypeSelector
                getData={props.getData}
                setData={props.setData}
            />
            {
                assetSourceTypeSelected === 's3prefix' &&
                <IngestionBlock
                    stage={props.stage}
                    getData={props.getData}
                    setData={props.setData}
                />
            }
            {
                assetSourceTypeSelected === 'assetIds' &&
                <AssetListTextarea
                    getData={props.getData}
                    setData={props.setData}
                />
            }
        </div>
    </FormSection>
}

export default (config: StepConfig): IStep => {
    const currData = config.getData()
    return {
        id: 'step_asset_source',
        content: <AssetSource
            stage={config.stage}
            getData={config.getData}
            setData={config.setData}
        />,
        title: 'Select asset source',
        onClick: (stepIndex: number) => {
            return (e: any) => {
                config.toStep(stepIndex)
            }
        },
        actions: getActions(
            config.index,
            config.totalStep,
            config.toStep,
            checkStepDone(config.index, currData)
        )
    }
}
