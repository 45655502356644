import styled from "styled-components";

export const StyledImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    && {
      height: 60vh;
      && {
        background: var(--awsui-color-grey-100);
        margin-bottom: 3rem;
        padding-bottom: 0;
      }
    }
  `,
  ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ContainerIdentityList = styled.div`
    height: 65vh;
    overflow: hidden;
    overflow-y: auto;
    && awsui-spinner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
    }
  `,
  ContainerIdentityOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    awsui-button button {
      margin-bottom: 1rem;
    }
  `;
