import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import '@amzn/awsui-components-react/index.css';
import {Route, Switch, HashRouter as Router} from 'react-router-dom';
import {AuthenticatedRoute} from './Routes/AuthenticatedRoute'
import App from './App';
import AuthenticationView from './Views/AuthenticationView';
import * as serviceWorker from './serviceWorker';
import Amplify from '@aws-amplify/core'
import {AmplifyConfig} from './Amplify/AmplifyConfig'
import { StageProvider } from './StageContext';
import PageLoader from './Components/PageLoader'

const DEFAULT_STAGE = "dev"

type Stage = {
    Stage: string,
}

/**
 * Gets the application stage from the stage config json from cloudfront.
 * This will return invalid json on your local machine
 */
const getStage = async (): Promise<Stage> => {
    try {
        const response = await fetch("/config/config.json", {})
        return await response.json()
    } catch(e) {
        return {Stage: DEFAULT_STAGE}
    }
}

const Root = () => {
  const [stage, setStage] = useState(DEFAULT_STAGE)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchStage = async () => {
        const {Stage} = await getStage()
        setStage(Stage)
        Amplify.configure(AmplifyConfig[Stage])
        setIsLoading(false)
        console.log(`Setting environment as ${Stage}`)
    }
    fetchStage()
  }, [])

  return (
    <StageProvider value={{stage}}>
        {
            isLoading ? <PageLoader />
            : <Router>
                <Switch>
                    <Route path="/authenticate" component={AuthenticationView}/>
                    <AuthenticatedRoute path="/" component={App}/>
                </Switch>
            </Router>
        }
    </StageProvider>
  )
}

ReactDOM.render(
    <div className="awsui">
        <Root />
    </div>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
