import React from 'react'
import styled from 'styled-components'

type Props = {
    title: string
}

const Title = styled.h1`
    && {
        font-size: 3.5rem;
    }
    ::first-letter {
        text-transform: capitalize;
    }

    @media (max-width: 900px) {
        && {
            font-size: 2.8rem;
        }
    }
`

const PageTitle = ({title}: Props) => (
    <Title className="awsui-text-large">{title}</Title>
)

export default PageTitle