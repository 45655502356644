import { CognitoUser } from "@aws-amplify/auth";

/**
 * Given a valid cognito user, returns the Identity token 
 * used to authorize access to the APIs
 */
export const getIdentityToken = (user: CognitoUser) => {
    const session = user && user.getSignInUserSession()
    const sessionToken = session && session.getIdToken()
    return sessionToken && sessionToken.getJwtToken() || ""
}