import React from "react";
import styled from "styled-components";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

type faqItem = {
  question: string;
  answer: JSX.Element | string;
};

type FAQList = Array<faqItem>;

const SIMUrl =
  "https://sim.amazon.com/issues/create?assignedFolder=ac918b03-ea96-4b77-b2c8-6b5f235c4195";

const orange = "var(--awsui-color-orange-500)",
  darkGrey = "var(--awsui-color-grey-400)",
  questionBackground = "var(--awsui-color-grey-200)";

const StyledQuestion = styled.div`
    && {
      background: ${questionBackground};
      border-left: 4px solid ${orange};
      h1 {
        color: ${orange};
        display: inline-block;
        padding: 10px;
      }
    }
  `,
  StyledAnswer = styled.div`
    && {
      border-left: 4px solid ${darkGrey};
      color: ${darkGrey};
      h1 {
        display: inline-block;
        padding: 10px;
      }
    }
  `;

export const FAQ = () => {
  /* Add questions and answers for the FAQ here, if your answer has a link in it, supply the answer as a JSX Element, otherwise supply it as a string*/
  const faqList: FAQList = [
    {
      question: "The website is not working. Who do I contact to fix it?",
      answer: (
        <span>
          If you are having an issue, please create a SIM{" "}
          <a href={SIMUrl} target="_blank">
            here
          </a>
        </span>
      )
    }
  ];
  return (
    <div>
      <h1>Frequently Asked Questions (FAQ)</h1>
      <ColumnLayout columns={1} borders="horizontal">
        <div data-awsui-column-layout-root="true">
          {faqList.map((faq, i) => (
            <div key={i}>
              <StyledQuestion>
                <span>
                  <h1>Q.</h1>
                </span>
                <span className="awsui-util-font-size-3">
                  <strong>{faq.question}</strong>
                </span>
              </StyledQuestion>
              <StyledAnswer>
                <span>
                  <h1>A.</h1>
                </span>
                <span className="awsui-util-font-size-3">
                  <strong>{faq.answer}</strong>
                </span>
              </StyledAnswer>
            </div>
          ))}
        </div>
      </ColumnLayout>
    </div>
  );
};
