import React, {useEffect} from 'react'
import styled from 'styled-components'
import FormSection from '@amzn/awsui-components-react/polaris/form-section'
import Toggle from '@amzn/awsui-components-react/polaris/toggle'
import Cards from '@amzn/awsui-components-react/polaris/cards'
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox'
import AnimatedTable from '../../AnimatedTable'
import {getActions, checkStepDone} from '../Utils'
import {StepConfig, WorkflowAttrs, ConfigWorkflow} from '../Interfaces'
import {IStep} from "../../../Utils/Marvin_Wizard/interfaces";
import {WorkflowConfigs} from '../Config'

const SEGMENTATION = 'Segmentation'

interface Feature {
    featureName: string,
    featureDescription: string,
    annotationTypes: string[]
}

interface Section {
    id: string,
    width: number,
    content: (item: Feature) => JSX.Element,
}

interface CardDefinition {
    header: (item: Feature) => JSX.Element,
    sections: Array<Section>
}

interface TableItem {
    [key: string]: JSX.Element
}

const CardTitle = styled.h2`
  && {
    font-weight: 500;
    font-size: 2rem;
  }
`

//function to get a dictionary to map the annotation type to its feature
const GET_TYPE_TO_FEATURE_MAP = (workflow: ConfigWorkflow) => 
    Object.keys(workflow.features).reduce(
        (
            map: {
                [annotationType: string]: string
            },
            feature: string
        ) => {
            return {
                ...map,
                ...workflow.features[feature].annotationTypes.reduce(
                    (
                        mapFeature: {
                            [annotationType: string]: string
                        },
                        annotationType: string
                    ) => ({
                        ...mapFeature,
                        [annotationType]: feature
                    }),
                    {}
                )
            }
        },
        {}
    )

//Hook for the Annotation Type Table
const AnnotationTable = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void,
    annotationTypes: string[]
}) => {
    const currData = props.getData()
    const currWorkflowType = currData.workflowType
    const currAnnotationStatus: {
        [annotationType: string]: {
            [attrId: string]: boolean
        }
    } = currData.annotationTypes || {}
    if(currWorkflowType) {
        const currWorkflow = WorkflowConfigs[currWorkflowType],
        annotationTypeAttrs = currWorkflow.annotationTypeAttrs,
        getTypesAffect = (annotationType: string) => {
            return currWorkflow.annotationTypes[annotationType].typesAffect
        },
        checkHasDependentRelation = (attrId: string) => {
            const listItemsFiltered = annotationTypeAttrs.filter((item: {
                name: string,
                id: string,
                hasDependent: boolean
            }) => {
                return item.id === attrId
            })
            return listItemsFiltered.length ? listItemsFiltered[0].hasDependent : false
        }
        let disabledAnnotationTypes: {
            [attrName: string]: string[]
        } = annotationTypeAttrs.reduce(
            (
                mapOfTypeArray: {
                    [attrId: string]: string[]
                },
                itemAttr: {
                    "name": string,
                    "id": string
                }
            ) => {
                return {
                    ...mapOfTypeArray,
                    [itemAttr.id]: []
                }
            },
            {}
        )
        Object.keys(currAnnotationStatus).forEach(
            (annotationType: string) => {
                Object.keys(
                    currAnnotationStatus[annotationType]
                ).forEach(
                    (attrId: string) => {
                        if(currAnnotationStatus[annotationType][attrId]){
                            disabledAnnotationTypes[attrId] = [
                                ...disabledAnnotationTypes[attrId],
                                ...getTypesAffect(annotationType)
                            ]
                        }
                    }
                )
            }
        )
        const mapTypeToFeature: {
            [annotationType: string]: string
        } = GET_TYPE_TO_FEATURE_MAP(currWorkflow),
        onTypeToggleChange = (
            annotationType: string,
            attrId: string
        ) => (e: any) => {
            const enabled = e.detail.checked;
            let  updatedAnnotationTypes = {
                ...currAnnotationStatus
            }
            let  updatedFeatures: {
                [feature: string]: boolean
            } = {}
            if(enabled){
                updatedAnnotationTypes = [
                    annotationType,
                    ...(checkHasDependentRelation(attrId) ? getTypesAffect(annotationType) : [])
                ].reduce(
                    (
                        resp: {
                            [annotationType: string]: {
                                [attr: string]: boolean
                            }
                        },
                        item: string
                    ) => {
                        updatedFeatures[mapTypeToFeature[item]] = true;
                        return {
                            ...resp,
                            [item]: {
                                ...(currAnnotationStatus[item]),
                                [attrId]: true
                            }
                        }
                    },
                    updatedAnnotationTypes
                )
            }else{
                updatedAnnotationTypes = {
                    ...updatedAnnotationTypes,
                    [annotationType]: {
                        ...(currAnnotationStatus[annotationType]),
                        [attrId]: false
                    }
                }
            }
            props.setData({
                ...currData,

                'features': {
                    ...currData.features,
                    ...updatedFeatures
                },
                'annotationTypes': {
                    ...currData.annotationTypes,
                    ...updatedAnnotationTypes
                }
            })
        },
        typeTableDefinitions = [
            {
                id: "typeName",
                header: "Type Name",
                cell: (item: TableItem)=> item.typeName
            },
            ...annotationTypeAttrs.map((attr: {
                id: string,
                name: string
            })=> ({
                id: attr.id,
                header: attr.name,
                cell: (item: TableItem)=> item[attr.id]
            }))
        ],
        tableItems = props.annotationTypes.map(
            (annotationType: string) => ({
                typeName: annotationType,
                ...annotationTypeAttrs.reduce((resp: object, attr: {
                    id: string,
                    name: string
                }) => {
                    return {
                        ...resp,
                        [attr.id]: <Toggle
                            checked={currAnnotationStatus[annotationType][attr.id]}
                            disabled={
                                annotationType === SEGMENTATION || ( // disable since the backend does not support yet
                                checkHasDependentRelation(attr.id) &&
                                disabledAnnotationTypes[attr.id].indexOf(annotationType) > -1
                                )
                            }
                            onChange={onTypeToggleChange(annotationType, attr.id)}
                        />
                    }
                }, {})
            })
        )
        return <AnimatedTable
            loading={false}
            loadingText="Loading data"
            columnDefinitions={typeTableDefinitions}
            items={tableItems}
            wrapLines={false}
        />
    }else{
        return null
    }
}

//Main Hook for this component
const AnnotationType = (props: {
    getData: () => WorkflowAttrs,
    setData: (data: WorkflowAttrs) => void
}) => {
    const currData = props.getData()
    const currWorkflowType = currData.workflowType
    if(currWorkflowType) {
        let disabledFeatures: string[] = []
        const currWorkflow = WorkflowConfigs[currWorkflowType],
        getFeatureDependency = (feature: string) => {
            return currWorkflow.features[feature].dependencies
        },
        currFeatureStatus: {
            [feature: string]: boolean
        } = currData.features || {},
        annotationTypeAttrs = currWorkflow.annotationTypeAttrs,
        annotationFeatures = Object.keys(currWorkflow.features)
            // if 'isRectified' is set as true in the previous step, no need to have the 'Segmentation' feature here, since the 'Segmentation' annotation has been done already. This part is not quite generic so I will just keep the logic in the component.
            .filter((key: string) => (!(currData.isRectified && key==='Segmentation')))
            .map(
                (key: string): Feature => {
                    if(currFeatureStatus[key]) {
                        disabledFeatures = [
                            ...disabledFeatures,
                            ...getFeatureDependency(key)
                        ]
                    }
                    return {
                        featureName: key,
                        featureDescription: currWorkflow.features[key].description,
                        annotationTypes: currWorkflow.features[key].annotationTypes
                    }
                }
            ),
        cardDefinition: CardDefinition = {
            header: (item: Feature) => {
                return <CardTitle>
                    <Checkbox
                        onChange={onFeatureSelected(item.featureName)}
                        label={item.featureName}
                        checked={currFeatureStatus[item.featureName] || false}
                        disabled={disabledFeatures.indexOf(item.featureName) > -1}
                    />
                </CardTitle>
            },
            sections: [{
                id: "section",
                width: 75,
                content: (item: Feature) => {
                    const checked=currData.features && currData.features[item.featureName]
                    return checked ? <AnnotationTable
                        getData={props.getData}
                        setData={props.setData}
                        annotationTypes={item.annotationTypes}
                    /> : <p>{item.featureDescription}</p>
                
                }
            }]
        }

        const onFeatureSelected = (featureName:string) => (e: any) => {
            const selected = e.detail.checked
            let updatedValues = {}
            if(selected) {
                updatedValues = [
                    featureName,
                    ...getFeatureDependency(featureName)
                ].reduce((
                    resp: {
                        [feature: string]: boolean
                    }, 
                    feature: string
                ) => ({
                    ...resp,
                    [feature]: true
                }), {})
            }else{
                updatedValues = {
                    [featureName]: false
                }
            }
            props.setData({
                ...currData,
                features: {
                    ...currData.features,
                    ...updatedValues
                }
            })
        }

        useEffect(() => {
            if(!currData.hasOwnProperty('features')) {
                props.setData({
                    ...currData,
                    features: Object.keys(currWorkflow.features).reduce((resp: {
                        [feature: string]: boolean
                    }, feature: string) => ({
                        ...resp,
                        [feature]: false
                    }), {}),
                    annotationTypes: Object.keys(currWorkflow.annotationTypes).reduce((
                        resp: {
                            [annotationType: string]: {
                                [attr: string]: boolean
                            }
                        }, 
                        annotationType: string
                    ) => ({
                        ...resp,
                        [annotationType]: annotationTypeAttrs.reduce((
                            resp: {
                                [attr: string]: boolean
                            }, 
                            attr: {
                                id: string,
                                name: string
                            }
                        ) => ({
                            ...resp,
                            [attr.id]: false
                        }), {})
                    }), {}),
                })
            }
        })

        return <FormSection
            header="Select Annotation Types"
        >
            <div
                className="wrap-wizard-content"
                data-awsui-column-layout-root="true"
            >
                <Cards
                    cardDefinition={cardDefinition}
                    items={annotationFeatures}
                    loadingText="Loading resources"
                    cardsPerRow={[
                        {cards: 1}
                    ]}
                >
                </Cards>
            </div>
        </FormSection>
    }else{
        return null
    }
}

export default (config:StepConfig): IStep => {
    const currData = config.getData()
    return {
        id: 'step_annotation_type',
        content: <AnnotationType
            getData={config.getData}
            setData={config.setData}
        />,
        title: 'Select annotation types',
        onClick: (stepIndex: number) => {
            return (e) => {
            }
        },
        actions: getActions(
            config.index,
            config.totalStep,
            config.toStep,
            checkStepDone(config.index, currData)
        )
    }
}
