import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {RouteConfig, RouteType} from './RouteConfig'

const Router = () => <Switch>
    {Object.values(RouteConfig).map(({href, component}: RouteType) => (
        <Route exact key={href} path={href} component={component} /> 
    ))}
</Switch>

export default Router