import React, { useState } from "react"
import WordsTable from "./wordsTable"
import { Annotation } from "./arenaParser"
import { BBox } from "@amzn/barometer"

interface Props {
  originalImage: {
    url: string
    name: string
  }
  annotations: Annotation[]
}

function TranscriptionWithTable({ originalImage, annotations }: Props) {
  const [clickedId, setClickedId] = useState("")

  function onClickWord(
    event: React.MouseEvent<SVGPolygonElement | SVGRectElement>
  ) {
    setClickedId(event.currentTarget.getAttribute("id") || "")
  }

  const words = {
    WORD_WITH_CONSENSUS: "#FF0000",
    WORD_WITHOUT_CONSENSUS: "#009926",
    WORD_SKIPPED: "#0f00e0",
    PURE_WORD_BBOX: "#cef520"
  }

  const dimensions = {
    width: 100,
    height: 100
  }

  return (
    <div className="awsui-grid">
      <div className="awsui-row">
        <div className="col-7">
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <h2>{originalImage.name}</h2>
            </div>
            <BBox
              asset={{
                URL: originalImage.url,
                type: "Image",
                dimensions: dimensions
              }}
              legend={words}
              annotations={annotations}
              onClick={onClickWord}
              config={{
                boxStrokeWidth: 3
              }}
            />
          </div>
        </div>
        <div className="col-5">
          <WordsTable
            url={originalImage.url}
            annotations={annotations}
            searchId={clickedId}
          />
        </div>
      </div>
    </div>
  )
}

export default TranscriptionWithTable
