import {
  ParsedIdentity,
  ResponseIdentity,
  ParsedIdentityAssets,
  ResponseIdentityAssets,
  ResponseIdentityData,
  ParsedIdentityData,
  PostResults,
  ParsedPostResults
} from "./Types";

export const parseIdentityListResponse = (
  response: Array<ResponseIdentity>
): Array<ParsedIdentity> => {
  return response.map(identity => {
    return {
      id: identity.Id,
      name: identity.Name,
      populationGroup: identity.PopulationGroup,
      gender: identity.Gender,
      lastQADate: identity.LastQADate,
      checkedForQA: identity.CheckedForQA
    };
  });
};

export const parsePostQABody = (results: PostResults): ParsedPostResults => {
  return {
    IdentityId: results.identityId,
    Assets: results.assets
  };
};
