import React, {ComponentType} from 'react'

const StageContext = React.createContext({stage: "dev"})
export const StageProvider = StageContext.Provider
export const StageConsumer = StageContext.Consumer


export const withStage = (Component: ComponentType<any>) => {
    return (props: any) => (
        <StageConsumer>
            {context => <Component {...props} stage={context.stage} />}
        </StageConsumer>
    )
} 