export const parseJson: Function = (strJson: string) => {
    try {
        const json = JSON.parse(strJson)
        if(json && typeof json === "object" ) {
            return json
        }
    }
    catch(e) {
        console.log(e)
        return false
    }
}
