import numeral from 'numeral'

/**
 * Converts a S3 Path
 * "Hieroglyph_GA/Test/RedactedText_Scanned_EmploymentDocs_2_Rectified_v1_4.0/ImageAssets/013eff61-210c-4594-846b-C29263375c1f.Jpg"
 * 
 * To the filename
 * "013eff61-210c-4594-846b-C29263375c1f.Jpg"
 */
export const stripFilenameFromS3Path = (path: string) => {
    return path.substring(path.lastIndexOf('/') + 1)
}

/**
 * Example 
 * str = "my-awesome-bucket/path/to/dir"
 * prefix = "my-awsesome-bucket/path"
 * 
 * returns "path/to/dir"
 *
 * @param str Original string
 * @param prefix String to remove that is contained in the original string
 */
export const removePrefixDirectoryFromString = (str: string, prefix: string) => {
    const removeEverythingBeforeTheSlash = prefix.slice(0, prefix.lastIndexOf('/')+1)
    return str.replace(removeEverythingBeforeTheSlash, "")
}

export const numberToString = (number: number) => numeral(number).format('0,0')
export const bytesToString = (bytes: number) => numeral(bytes).format('0.00 b')