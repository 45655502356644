type ImageFilters = {
  [key: string]: any;
};

export const START_DATE = "startDate",
  END_DATE = "endDate",
  DATE_KEY = "date",
  CONSENSUS_KEY = "consensus",
  CONSENSUS = 'filter_by_must_attributes.consensus_result_contains_label',
  CONSENSUS_HIGH = "range_attributes.resulted_consensus_percentage.lte",
  CONSENSUS_LOW = "range_attributes.resulted_consensus_percentage.gte";

export type Filters = {
  [key: string]: any;
  [CONSENSUS_HIGH]?: string;
  [CONSENSUS_LOW]?: string;
  [END_DATE]?: string;
  hair?: string;
  imageQuality?: string;
  pose?: string;
  skin?: string;
  [START_DATE]?: string;
};

export const imageFilters: ImageFilters = {
  [CONSENSUS_KEY]: {
    label: "Consenus"
  },
  /*
    Removing these filters for now, they aren't needed for first release
  */
  // hair: {
  //   options: [
  //     { text: "Bald", id: "bald" },
  //     { text: "Short Hair", id: "short-hair" },
  //     { text: "Medium Hair", id: "medium-hair" },
  //     { text: "Long Hair", id: "long-hair" }
  //   ],
  //   label: "Hair Style"
  // },
  // pose: {
  //   options: [
  //     { text: "Front facing", id: "front-facing" },
  //     { text: "Off centre", id: "off-centre" },
  //     { text: "Profile", id: "profile" }
  //   ],
  //   label: "Pose"
  // },
  // skin: {
  //   options: [
  //     { text: "Shade 1", id: "shade-1" },
  //     { text: "Shade 2", id: "shade-2" },
  //     { text: "Shade 3", id: "shade-3" },
  //     { text: "Shade 4", id: "shade-4" }
  //   ],
  //   label: "Skin"
  // },
  // imageQuality: {
  //   options: [
  //     { text: "Sharp and focused", id: "sharp" },
  //     { text: "Mildly blurred", id: "mild-blur" },
  //     { text: "Blurred", id: "blurred" }
  //   ],
  //   label: "Image Quality"
  // },
  // [DATE_KEY]: {
  //   label: "Date"
  // }
};
