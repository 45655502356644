import React from 'react'
import SideNavigation from '@amzn/awsui-components-react/polaris/side-navigation'
import {RouteConfig} from '../Routes/RouteConfig'

type Props = {
    pathname: string,
}

const header = {
    ...RouteConfig.Home
}

const items = [
    {
        ...RouteConfig.Projects,
        type: "link",
        href: `#${RouteConfig.Projects.href}`,
    },
    {
        ...RouteConfig.Workflows,
        type: "link",
        href: `#${RouteConfig.Workflows.href}`,
    },
    {
        ...RouteConfig.Visualizer,
        type: "link",
        href: `#${RouteConfig.Visualizer.href}`,
    },
    {
        ...RouteConfig.QAProjects,
        type: "link",
        href: `#${RouteConfig.QAProjects.href}`
    }
]

const NavigationDrawer = (props: Props) => (
    <SideNavigation 
        activeHref={`#${props.pathname}`}
        {...{header, items}}
    />
)

export default NavigationDrawer