import React from 'react'
import styled from 'styled-components'

type Props = {
    content: string
}

const Subtitle = styled.h5`
    && {
        margin-bottom: 2rem;
    }
`

const PageSubtitle = ({content}: Props) => (
    <Subtitle>{content}</Subtitle>
)

export default PageSubtitle