// @flow
import React from 'react';
import styled, {keyframes} from 'styled-components';

const ANIMATION_SPEED = 3,
      INITIAL_COLOR = '#ffad32',
      FINAL_COLOR = "#ec7211"

const popOut = ({xTrans, yTrans}) =>  keyframes`
    0%{
    opacity: 0;
        transform: translateX(-80px) translateY(50px) scale(1);
        fill: ${INITIAL_COLOR};
    }
    25%{
        opacity: 1;
    }
    50%{
        transform: translateX(${-80 + xTrans}px) translateY(${ 50 + yTrans}px) scale(1.15);
        fill: ${FINAL_COLOR};
    }
    75%{
        opacity: 1;
    }
    100%{
    opacity: 0;
        transform: translateX(-80px) translateY(50px) scale(1);
        fill: ${INITIAL_COLOR};
    }
`

const LoaderSegment = styled.path`
    opacity: 0;
    transform: translateX(-80px) translateY(50px) scale(1);
    animation: ${popOut} ${ANIMATION_SPEED}s ease infinite;
    animation-delay: ${({delay}) => delay}s;
    fill: ${INITIAL_COLOR};
`,
LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;
`,
FlexBreaker = styled.div`
    flex-basis: 100%;
    width: 0px; 
    height: 0px; 
    overflow: hidden;
`;

const DriftingHex = ({delay}) =><svg key={Math.random()} viewBox="0 0 470 500" width="20%" height="20%">
    <g xmlns="http://www.w3.org/2000/svg" id="g3000">
    <LoaderSegment
        delay={delay + .05}
        xTrans={-34} yTrans={-44}
        id="path2989" d="m309,207.288513l0,221.610687l181,-88.644287l0,-221.610664l-181,88.644264z"/>
    <LoaderSegment
        delay={delay + .1}
        xTrans={-54} yTrans={-44}
        d="m309,207.288513l0,221.610687l-180.999985,-88.644287l0,-221.610664l180.999985,88.644264z" id="path2991"/>
    <LoaderSegment 
        delay={delay + .15}
        xTrans={-44} yTrans={-64}
        d="m309,29.99999l-180.999985,88.644258l180.999985,88.644264l181,-88.644264l-181,-88.644258z" id="path2987"/>
   </g>    
</svg>

export default () => 
    <LoaderContainer>
        <DriftingHex delay={.6}/>
        <FlexBreaker/>
        <DriftingHex delay={.2}/>
        <div style={{width: '5%'}}/>
        <DriftingHex delay={.4}/>
    </LoaderContainer>