import React, { useEffect, useReducer, Fragment } from "react";
import { Prompt } from "react-router";
import Button from "@amzn/awsui-components-react/polaris/button";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import ActionStripe from "../Components/ClusterTool/ActionStripe";
import ImageCropper from "../Components/ImageCropper";
import { IdentityFormModal } from "../Components/ClusterTool/IdentityFormModal";
import { IdentityList } from "../Components/ClusterTool/IdentityList";
import { CognitoUser } from "@aws-amplify/auth";
import {
  StyledImageContainer,
  ContainerHeader,
  ContainerIdentityList,
  ContainerIdentityOptions
} from "../Components/ClusterTool/StyledComponents";
import {
  reducer,
  initialState,
  getUserImages,
  getFacesGenerator,
  getIdentities,
  postAnnotations,
  UNSURE_ID,
  SET_ANNOTATED_FACES,
  UNSURE_IDENTITY,
  SET_CURRENT_INDEX,
  DELETE_IDENTITY,
  ADD_IDENTITY,
  IDENTITY_SELECT,
  SET_STATE
} from "../Components/ClusterTool/reducer";
import clusterAnnotations from "../Components/ClusterTool/postParser";

type Props = {
  stage: string;
  user: {
    cognitoUser: CognitoUser;
  };
};

const NO_IDENITITY_WARNING = 'Click "Add" to create a new Identity.',
  ROUTER_PROMPT = "Changes you made may not be saved.",
  NO_NEW_IMAGES = "There are no new images to annotate.";

let facesGenerator: any | null = null;

const ClusterTool = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const reducerDispatch = (type: string, payload?: any) =>
    dispatch({ type, payload });

  /**
   * Dispatchers
   */
  const setState = (payload: any) => reducerDispatch(SET_STATE, payload),
    addIdentity = (payload: any) => reducerDispatch(ADD_IDENTITY, payload),
    removeIdentity = (payload: any) =>
      reducerDispatch(DELETE_IDENTITY, payload),
    identitySelected = (payload: any) =>
      reducerDispatch(IDENTITY_SELECT, payload),
    setAnnotatedFaces = () => reducerDispatch(SET_ANNOTATED_FACES);

  /**
   * API calls
   */
  const fetchUserImages = async () => {
    const { images }: any = await getUserImages(props.user, props.stage);
    if (!images.length) setState({ loading: false });
    dispatch({
      type: SET_STATE,
      payload: {
        imagesAvailable: images.length > 0,
        images: images,
        ...(images.length ? { currentImgFilename: images[0].filename } : {})
      }
    });
  };

  const fetchMoreFaces = async () => {
    if (facesGenerator) {
      const { done, value } = await facesGenerator.next();
      const payload = done
        ? { imagesAvailable: false }
        : { faces: [...state.faces, ...value] };
      setState(Object.assign(payload));
    }
  };

  const fetchUserIdentities = async () => {
    try {
      const identities = await getIdentities(props.user, props.stage);
      const identityIds = identities.map(identity => {
        return identity.id;
      });
      setState({
        identities: identities,
        identitiesInUse: [...state.identitiesInUse, ...identityIds],
        identitiesLoading: false
      });
    } catch (e) {
      alert(e);
    }
  };

  const postFaceAnnotations = async () => {
    try {
      await postAnnotations(
        props.user,
        props.stage,
        clusterAnnotations(state.annotatedFaces)
      );
      const remainingFaces = state.faces.filter(
        face => !state.completedImgFilenames.includes(face.filename)
      );
      setState({
        faces: remainingFaces,
        annotatedFaces: [],
        currentFaceIndex: 0,
        submitDisabled: true,
        loading: false
      });
    } catch (e) {
      console.log(e);
      setState({
        annotatedFaces: [],
        currentFaceIndex: 0,
        submitDisabled: true,
        completedImgFilenames: [],
        loading: false
      });
      alert("Something went wrong: " + e);
    }
  };

  const handleSubmit = () => setAnnotatedFaces();

  /**
   * Lifecyles hooks
   * Mounted/Unmounted
   */
  useEffect(() => {
    fetchUserImages();
    fetchUserIdentities();
    return () => (window.onbeforeunload = null);
  }, []);

  /**
   * Watchers
   */
  useEffect(() => {
    if (state.imagesAvailable && !facesGenerator) {
      facesGenerator = getFacesGenerator(state.images, props.user, props.stage);
      fetchMoreFaces().then(() => {
        setState({ loading: false });
      });
    }
  }, [state.images]);

  useEffect(() => {
    if (state.completedImgFilenames.length) {
      postFaceAnnotations();
    }
  }, [state.completedImgFilenames]);

  return (
    <Fragment>
      <Prompt when={window.onbeforeunload !== null} message={ROUTER_PROMPT} />
      <IdentityFormModal
        submitForm={addIdentity}
        showModal={state.showModal}
        onClose={() =>
          dispatch({ type: SET_STATE, payload: { showModal: false } })
        }
      />
      <ActionStripe
        items={state.faces}
        index={state.currentFaceIndex}
        previous={() =>
          dispatch({
            type: SET_CURRENT_INDEX,
            payload: state.currentFaceIndex - 1
          })
        }
        next={() =>
          dispatch({
            type: SET_CURRENT_INDEX,
            payload: state.currentFaceIndex + 1
          })
        }
        submitDisabled={state.submitDisabled}
        submit={handleSubmit}
      />
      <div style={{ marginTop: "2rem" }}>
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-xxxs-12 col-s-9">
              <StyledImageContainer className="awsui-util-container awsui-util-no-gutters">
                {(state.loading && <Spinner size={"large"} />) ||
                  (state.faces.length && (
                    <ImageCropper
                      url={state.faces[state.currentFaceIndex].url}
                      region={state.faces[state.currentFaceIndex].region}
                      padding={{ width: 20 }}
                      style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%"
                      }}
                      fallback={
                        <div style={{ textAlign: "center" }}>
                          <Icon size={"big"} name={"status-warning"} />
                          <p>Image not found</p>
                        </div>
                      }
                      onError={(url: string) =>
                        dispatch({
                          type: SET_STATE,
                          payload: { imageError: url }
                        })
                      }
                    />
                  )) || <p>{NO_NEW_IMAGES}</p>}
              </StyledImageContainer>
              <ContainerIdentityOptions>
                {(!state.imageError && state.identities.length && (
                  <Fragment>
                    {state.identities.map((identity, i) => (
                      <Button
                        text={identity.name}
                        key={i}
                        disabled={
                          identity.id === state.currentAnnotation.id ||
                          state.loading ||
                          !state.faces.length
                        }
                        onClick={(e: CustomEvent) => {
                          if (
                            state.currentFaceIndex === state.faces.length - 1 &&
                            state.imagesAvailable
                          ) {
                            setState({ loading: true });
                            fetchMoreFaces().then(() => {
                              identitySelected(identity);
                              setState({ loading: false });
                            });
                          } else {
                            identitySelected(identity);
                          }
                        }}
                      />
                    ))}
                    <Button
                      text={UNSURE_IDENTITY.name}
                      onClick={e => identitySelected(UNSURE_IDENTITY)}
                      disabled={
                        state.faces.length === 0 ||
                        state.currentAnnotation.id === UNSURE_ID ||
                        state.loading
                      }
                    />
                  </Fragment>
                )) ||
                  null}
              </ContainerIdentityOptions>
            </div>
            <div className="col-xxxs-12 col-s-3">
              <div className="awsui-util-container">
                <ContainerHeader className="awsui-util-container-header">
                  Identities
                  <div className="awsui-util-f-r">
                    <Button
                      icon="add-plus"
                      iconAlt="Add identity"
                      text="Add"
                      iconAlign="right"
                      onClick={(e: CustomEvent) =>
                        dispatch({
                          type: SET_STATE,
                          payload: { showModal: true }
                        })
                      }
                    />
                  </div>
                </ContainerHeader>
                <ContainerIdentityList>
                  {(state.identitiesLoading && <Spinner size="large" />) ||
                    (state.identities.length &&
                      IdentityList(
                        state.identities,
                        state.identitiesInUse,
                        removeIdentity
                      )) ||
                    NO_IDENITITY_WARNING}
                </ContainerIdentityList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClusterTool;
