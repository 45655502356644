import { Alert, Form } from "@amzn/awsui-components-react";
import * as React from "react";
import { render } from "react-dom";

import "./styles.css";

import { IStep } from "./interfaces";
import { WizardButtonGroup } from "./wizardButtonGroup";
import { WizardNavigation } from "./wizardNavigation";

export interface IWizardProps {
    currentStep: number;
    steps: IStep[];
    stepPrefix: string;
    error?: string;
}

class Wizard extends React.Component<IWizardProps, {}> {

    getCurrentStep(): IStep {
        let { currentStep, steps } = this.props;
        return steps[currentStep];
    }

    render() {
        let currentStep = this.getCurrentStep();

        return (
            <main className="awsmob-wizard awsui-grid" >
                <article className="awsui-row">
                    <WizardNavigation currentStep={this.props.currentStep} steps={this.props.steps} stepPrefix={this.props.stepPrefix} />
                    <Form
                        header={currentStep.title}
                        description={currentStep.subtitle || ""}
                        actions={currentStep.actions && <WizardButtonGroup actions={currentStep.actions} />}
                        className="col-m-10">
                        {currentStep.content}
                        <Alert className="awsui-util-mb-l" type="error" visible={this.props.error != null} content={this.props.error} />
                    </Form>
                </article>
            </main >
        );
    }
}

export default Wizard;
