import React, {ComponentType} from 'react'

const UserContext = React.createContext({cognitoUser: null})
export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer

export const withUser = (Component: ComponentType<any>) => (
    (props: any) => (
        <UserConsumer>
            {context => <Component {...props} user={context} />}
        </UserConsumer>
    )
)