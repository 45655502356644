import React, { ComponentType } from "react";
import ProjectsView from "../Views/ProjectsView";
import ProjectView from "../Views/ProjectView";
import DatasetView from "../Views/DatasetView";
import WorkflowCreateView from "../Views/WorkflowCreateView";
import VisualizerView from "../Views/VisualizerView";
import AssetView from "../Views/AssetView";
import HomeView from "../Views/HomeView";
import ClusterView from "../Views/ClusterTool";
import ImageUpload from "../Views/ImageUpload";
import IdentityDatasetView from "../Views/IdentityDatasetView";
import IdentityDetailsView from "../Views/IdentityDetailsView";
import QAProjectsView from "../Views/QAProjectsView";
import { Link } from "react-router-dom";
import { withAnimation } from "../Animations/Animations";
import { withStage } from "../StageContext";
import { withUser } from "../UserContext";

export type RouteType = {
  href: string;
  component: any;
};

const withRouteProps = (Component: ComponentType<any>) =>
  withAnimation(withStage(withUser(Component)));

export const RouteConfig = {
  Home: {
    href: "/",
    text: "Thunder console",
    component: withRouteProps(HomeView)
  },
  Projects: {
    href: "/projects",
    text: "View exported project datasets",
    component: withRouteProps(ProjectsView)
  },
  Project: {
    href: "/projects/:project",
    component: withRouteProps(ProjectView)
  },
  Dataset: {
    href: "/projects/:project/datasets/:dataset",
    component: withRouteProps(DatasetView)
  },
  DatasetAsset: {
    href: "/projects/:project/datasets/:dataset/assets/:asset",
    component: withRouteProps(AssetView)
  },
  ProjectAsset: {
    href: "/projects/:project/assets/:asset",
    component: withRouteProps(AssetView)
  },
  Workflows: {
    href: "/workflows/create",
    text: "Create a workflow",
    component: withRouteProps(WorkflowCreateView)
  },
  Visualizer: {
    href: "/visualizer",
    text: "Visualize an S3 directory",
    component: withRouteProps(VisualizerView)
  },
  ClusterTool: {
    href: "/cluster",
    component: withRouteProps(ClusterView)
  },
  ImageUpload: {
    href: "/upload",
    component: withRouteProps(ImageUpload)
  },
  IdentityDataset: {
    href: "/qa/faces",
    component: withRouteProps(IdentityDatasetView)
  },
  IdentityDetailsView: {
    href: "/qa/faces/:identity",
    component: withRouteProps(IdentityDetailsView)
  },
  QAProjects: {
    href: "/qa",
    text: "View projects for QA",
    component: withRouteProps(QAProjectsView)
  }
};

export const generateDatasetLink = (
  currentUrl: string,
  datasetName: string
) => (
  <Link
    to={{
      pathname: `${currentUrl}/datasets/${datasetName}`
    }}
  >
    {datasetName}
  </Link>
);

export const generateAssetLink = (
  currentUrl: string,
  assetName: string,
  key: string
) => (
  <Link
    to={{
      pathname: `${currentUrl}/assets/${assetName}`,
      search: `key=${key}`
    }}
  >
    {assetName}
  </Link>
);

export const generateIdentityLink = (
  currentUrl: string,
  identityId: string,
  name: string,
  startDate?: string | Date
) => (
  <Link
    to={{
      pathname: `${currentUrl}/${identityId}${
        startDate ? "?startDate=" + startDate : ""
      }`
    }}
  >
    {name}
  </Link>
);
