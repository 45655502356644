import {ParsedFaceClusterAsset, ReponseFaceClusterAsset } from "./Types"

export const parseGetAssetsResponse = (
  response: Array<ReponseFaceClusterAsset>
): Array<ParsedFaceClusterAsset> => {
  return response.map(image => {
    return {
      url: image.S3PresignedUrl,
      filename: image.FileName
    };
  });
};
