import React, {useState, useEffect, ComponentType} from 'react'
import Auth from '@aws-amplify/auth'
import {Route, Redirect} from 'react-router-dom'
import PageLoader from '../Components/PageLoader'
import {UserProvider} from '../UserContext'

type Props = {
    component: ComponentType<any>,
    path: string,
}

export const AuthenticatedRoute = ({component: Component, ...componentProps}: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        authenticate()
    }, [])

    const authenticate = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            setIsAuthenticated(true)
            setUser(user)
            setIsLoading(false)
        } catch(e) {
            // Retry because sometimes this is called before amplify stores the access token in local storage
            setTimeout(async () => {
                try {
                    const user = await Auth.currentAuthenticatedUser()
                    setIsAuthenticated(true)
                    setUser(user)
                    setIsLoading(false)
                } catch(e) {
                    setIsAuthenticated(false)
                    setIsLoading(false)
                    console.log('errors', e)
                }
            }, 1000)
        }
    }
    return (
        isLoading 
            ? <PageLoader />
            : <Route 
                {...componentProps}
                render={props => isAuthenticated 
                    ? <UserProvider value={{cognitoUser: user}}><Component {...props} /></UserProvider>
                    : <Redirect to={{pathname: '/authenticate'}} />
                }
            />
    )
}