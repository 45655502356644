export interface IStep {
    id: string;
    title: JSX.Element | string;
    subtitle?: string;
    navTitle?: JSX.Element | string;
    content: JSX.Element;
    actions?: IAction[];
    onClick?: (toStep: number) => (event: any) => void;
    hidden?: boolean;
};

export interface IAction {
    id?: string;
    variant: "icon" | "normal" | "primary" | "link";
    text: string;
    disabled?: boolean;
    loading?: boolean;
    onClick: (event: any) => void;
};

export const StepStyle = {
    ACTIVE: "active",
    FUTURE: "future",
    PAST: "past"
};
