import {ParsedFaceClusterAsset, ReponseFaceClusterAsset } from "./Types"

export const parseGetPresignedUrlResponse = (
  response: Array<ReponseFaceClusterAsset>
): ParsedFaceClusterAsset => {
  return {
    url: response[0].S3PresignedUrl,
    filename: response[0].FileName
  };
};
