import React from 'react'
import {Config, WorkflowAttrs} from './Interfaces'
import {IStep} from "../../Utils/Marvin_Wizard/interfaces";
import Badge from '@amzn/awsui-components-react/polaris/badge'
import getStepWorkflowType from "./Steps/WorkflowType"
import getStepAssetSource from "./Steps/AssetsSource"
import getStepAnnotationTypes from "./Steps/AnnotationTypes"
import getStepOtherConfig from "./Steps/OtherConfig"
import getStepReview from "./Steps/Review"

export const assetSourceTypes: {
    [key: string]: {
        label: string
    }
} = {
    "datasetId": {label: "Dataset ID"},
    "s3prefix": {label: "S3 Prefix"}
}

export const getIngestionAccounts = (stage: string) => {
    switch(stage) {
        case 'prod':
            return [
                'rekognition-tools-aws-prod',
                'rekognition-data-aws-hawkeye'
            ]
        default:
            return [
                'rekognition-tools-aws-nonprod'
            ]
    }
}

export const formValueTypes: {[t: string]: string} = {
    BOOLEAN: 'boolean',
    STRING: 'string',

}

export const WorkflowConfigs: Config = {
	"textract_e2e": {
        'valueFeature': 'Hieroglyph',
		"workflowName": "Textract Annotation Workflow",
		"features": {
		    "Segmentation": {
                "dependencies": [],
                "description": "Annotation the segment of the document in an image",
                "annotationTypes": ["Segmentation"]
            },
		    "Words": {
                "dependencies": ["Segmentation"],
                "description": "Annotate all the words in the document",
                "annotationTypes": ["WordBBox"]
            },
		    "Tables": {
                "dependencies": ["Segmentation", "Words"],
                "description": "Table annotation for document",
                "annotationTypes": ["TableBBox", "TableWithCells"]
            },
		    "ContentArea": {
                "dependencies": ["Segmentation"],
                "description": "Annotation the content area of a document",
                "annotationTypes": ["ContentAreaBBox"]
            },
		    "Lines": {
                "dependencies": ["Segmentation", "Words"],
                "description": "Annotation all the lines in a document",
                "annotationTypes": ["LineBBoxAssociation"]
            },
		    "Transcriptions": {
                "dependencies": ["Segmentation", "Words"],
                "description": "Type the words in the document",
                "annotationTypes": ["Transcription"]
            },
		    "KeyValues": {
                "dependencies": ["Segmentation","Words"],
                "description": "Annotate the key-value pair in a document",
                "annotationTypes": ["KeyValue", "ClickableItemVerification"]
            },
		    "RedactedText": {
                "dependencies": ["Segmentation"],
                "description": "Annotate the reducted area in a document",
                "annotationTypes": ["RedactedTextBBox"]
            }
		},
		"annotationTypes": {
			"Segmentation": {
				"typesAffect": [
                    "ContentAreaBBox",
                    "RedactedTextBBox",
                    "WordBBox",
                    "LineBBoxAssociation",
                    "Transcription",
                    "KeyValue",
                    "ClickableItemVerification",
                    "TableBBox",
                    "TableWithCells"
                ]
			},
            "ContentAreaBBox": {
				"typesAffect": []
            },
            "RedactedTextBBox": {
				"typesAffect": []
            },
            "WordBBox": {
				"typesAffect": [
                    "LineBBoxAssociation",
                    "Transcription",
                    "KeyValue",
                    "ClickableItemVerification"
                ]
            },
            "LineBBoxAssociation": {
				"typesAffect": []
            },
            "Transcription": {
				"typesAffect": []
            },
            "KeyValue": {
				"typesAffect": ["ClickableItemVerification"]
            },
            "ClickableItemVerification": {
				"typesAffect": []
            },
            "TableBBox": {
				"typesAffect": ["TableWithCells"]
            },
            "TableWithCells": {
				"typesAffect": []
            }
		},
        "annotationTypeAttrs": [
            {
                "name": "Annotation Revisions",
                "id": "AnnotationRevisions",
                "hasDependent": true
            }
        ],
		"extraConfigs": [
            {
                "id": "workflowBatchName",
                "name": "Workflow Batch Name",
                "type": formValueTypes.STRING,
                "required": false,
                "validation": [{
                    pattern: /^[\w-]+$/,
                    errorMsg: "Batch Name should only contain letters, numbers, underscores and hyphens."
                },{
                    pattern: /^.{0,80}$/,
                    errorMsg: "Batch name cannot exceed a length of 80 characters"
                }]
            },
            {
                "id": "allowRerun",
                "name": "Allow Rerun",
                "type": formValueTypes.BOOLEAN,
                "required": false
            }
        ]
	}
}


export const StepMapping: {
    getStep: (config: {
        stage: string,
        index: number,
        totalStep: number,
        toStep: (step: number) => void,
        getData: () => WorkflowAttrs,
        setData: (data: object) => void
    }) => IStep,
    getValues: (data: WorkflowAttrs) => ({
        [key:  string]: {
            label: string,
            required: boolean,
            validation: Array<{
                pattern: RegExp,
                errorMsg: string
            }>,
            getDomReview: () => any
        }
    })
}[] = [
    {
        getStep: getStepWorkflowType,
        getValues: (currData: WorkflowAttrs) => {
            return {
                'workflowType': {
                    label: 'Workflow Type',
                    required: true,
                    getDomReview: () => {
                        return currData['workflowType'] ?
                            WorkflowConfigs[currData['workflowType']].workflowName : ''
                    }
                }
            }
        }
    },
    {
        getStep: getStepAssetSource,
        getValues: (currData: WorkflowAttrs) => {
            if(currData.hasOwnProperty('workflowType')) {
                return {
                    'assetSourceType': {
                        label: 'Asset Source',
                        required: true,
                        getDomReview: () => {
                            return assetSourceTypes[currData['assetSourceType']].label
                        }
                    },
                    'datasetId': {
                        label: 'Dataset ID',
                        required: true,
                        validation: [{
                            pattern: /^[\w-]+$/,
                            errorMsg: "Dataset IDs should only contain letters, numbers, underscores and hyphens."
                        }],
                        getDomReview: () => {
                            return currData['datasetId']
                        }
                    },
                    'isRectified': {
                        label: 'The provided dataset has been rectified',
                        required: false,
                        getDomReview: () => {
                            return currData['isRectified'] ? "Yes" : "No"
                        }
                    },
                    'reuseExistingDataset': {
                        label: 'Reuse Existing Dataset',
                        required: false,
                        getDomReview: () => {
                            return currData['reuseExistingDataset'] ? "Yes" : "No"
                        }
                    },
                    'source': {
                        label: 'Source',
                        required: false,
                        getDomReview: () => {
                            return currData['source']
                        }
                    },
                    ...(
                        currData.assetSourceType === 'assetIds' ? {
                            'assetsList': {
                                label: 'List of Assets',
                                required: true
                            }
                        } : {}
                    ),
                    ...(
                        currData.assetSourceType === 's3prefix' ? {
                            'ingestionAccount': {
                                label: 'Account for Ingestion',
                                required: true,
                                getDomReview: () => {
                                    return currData['ingestionAccount']
                                }
                            },
                            's3Bucket': {
                                label: 'S3 Bucket',
                                required: true,
                                getDomReview: () => {
                                    return currData['s3Bucket']
                                }
                            },
                            's3Prefix': {
                                label: 'S3 Prefix',
                                required: false,
                                getDomReview: () => {
                                    return currData['s3Prefix']
                                }
                            }
                        } : {}
                    )
                }
            }else{
                return {}
            }
        }
    },
    {
        getStep: getStepAnnotationTypes,
        getValues: (currData: WorkflowAttrs) => {
            return {
                'features': {
                    label: 'Features',
                    required: true,
                    getDomReview: () => {
                        if(currData["features"]) {
                            return Object.keys(currData['features']).map(
                                (feature, i) => {
                                    const value = currData['features']?currData['features'][feature]:false
                                    return <span
                                        key={feature}
                                        style={{
                                            display: 'inline-block',
                                            padding: '0 5px 5px'
                                        }}
                                    ><Badge
                                        color={value ? 'green' : 'grey'}
                                        content={feature}
                                    /></span>
                                }
                            )
                        }else{
                            return null
                        }
                    }
                },
                'annotationTypes': {
                    label: 'Annotation Types',
                    required: true,
                    getDomReview: () => {
                        if(
                            currData.hasOwnProperty('workflowType') &&
                            currData.workflowType &&
                            WorkflowConfigs[currData.workflowType]
                        ) {
                            const currWorkflow = WorkflowConfigs[currData.workflowType]
                            const dataAnnotaitonTypeAttrs: {
                                [attr: string]: {
                                    label: string,
                                    items: any[]
                                }
                            } = currWorkflow.annotationTypeAttrs.reduce(
                                (resp, item) => {
                                    return {
                                        ...resp,
                                        [item.id]: {
                                            label: item.name,
                                            items: []
                                        }
                                    }
                                },
                                {}
                            )
                            if(currData['annotationTypes']) {
                                const dataAnnotationTypes = currData['annotationTypes'] ? currData['annotationTypes'] : {}
                                Object.keys(currData['annotationTypes']).forEach(
                                    (annotationType, i) => {
                                        Object.keys(dataAnnotationTypes[annotationType]).forEach(
                                            (attr, i) => {
                                                dataAnnotaitonTypeAttrs[attr].items.push(
                                                    <span
                                                        key={annotationType}
                                                        style={{
                                                            display: 'inline-block',
                                                            padding: '0 5px 5px'
                                                        }}
                                                    >
                                                        <Badge
                                                            color={dataAnnotationTypes[annotationType][attr] ? 'green' : 'grey'}
                                                            content={annotationType}
                                                        />
                                                    </span>
                                                )
                                            }
                                        )
                                    }
                                )
                            }
                            return <div style={{paddingLeft: '15px'}}>
                                {
                                    Object.values(dataAnnotaitonTypeAttrs).map(
                                        (item, i) => {
                                            return <div key={item.label}>
                                                <div className="awsui-util-label">{item.label}</div>
                                                <div>{item.items}</div>
                                            </div>
                                        }
                                    )
                                }
                            </div>
                        }else{
                            return ''
                        }
                    }
                }
            }
        }
    },
    {
        getStep: getStepOtherConfig,
        getValues: (currData: WorkflowAttrs) => {
            if(
                currData.hasOwnProperty('workflowType') &&
                currData.workflowType &&
                WorkflowConfigs[currData.workflowType]
            ) {
                const currWorkflow = WorkflowConfigs[currData.workflowType]
                return currWorkflow['extraConfigs'].reduce(
                    (
                        mapItems: {
                            [key: string]: {
                                label: string,
                                required: boolean
                            }
                        },
                        item: {
                            id: string,
                            type: string,
                            name: string,
                            required: boolean,
                            validation?: Array<{
                                pattern: RegExp,
                                errorMsg: string
                            }>
                        } 
                    ) => {
                        return {
                            ...mapItems,
                            [item.id]: {
                                label: item.name,
                                required: item.required,
                                validation: item.validation,
                                getDomReview: () => {
                                    const value = currData[item.id]
                                    if(typeof value === formValueTypes.BOOLEAN) {
                                        return value ? "Yes" : "No"
                                    }else if(typeof value === formValueTypes.STRING) {
                                        return value
                                    }else{
                                        return 'The format of the value is not supported for now'
                                    }
                                }
                            }
                        }
                    },
                    {}
                )
            }else{
                return {}
            }
        }
    },
    {
        getStep: getStepReview,
        getValues: (currData: WorkflowAttrs) => {
            return {}
        }
    },
]


