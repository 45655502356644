import React, { Component } from 'react'
import PageTitle from '../Components/PageTitle'
import PageSubtitle from '../Components/PageSubtitle'
import Cards from '@amzn/awsui-components-react/polaris/cards'
import CardsSelection from '@amzn/awsui-components-react/polaris/cards-selection'
import CardsPagination from '@amzn/awsui-components-react/polaris/cards-pagination'
import CardsFiltering from '@amzn/awsui-components-react/polaris/cards-filtering'
import Button from '@amzn/awsui-components-react/polaris/button'
import TableCounter from '../Components/TableCounter'
import {Link} from 'react-router-dom'
import { Project, getProjects } from '../API/Api'
import styled from 'styled-components'
import { TableSelection } from '@amzn/awsui-components-react';
import { CognitoUser } from '@aws-amplify/auth';
import { getIdentityToken } from '../Utils/UserUtils';

const MAX_PAGE_SIZE = 10

type CardDefinition = {
    header: (item: Project) => JSX.Element,
    sections: Array<section>
}
type section = {
    id: string,
    width: number,
    header: (item: Project) => string,
    content: (item: Project) => JSX.Element,
}

type State = {
  projects: Array<Project>,
  selectedProject: Project | null
}
type Props = {
  stage: string,
  user: {
    cognitoUser: CognitoUser
  }
}

const CardTitle = styled.h2`
  && {
    font-weight: 500;
    font-size: 2rem;
  }
`

const LinkButton = styled(Button)`
  && {
    margin: 10px 0px;
  }
`

const cardDefinition: CardDefinition = {
    header: (item: Project) => <CardTitle><Link to={`/projects/${item.Name.toLocaleLowerCase()}`}>{item.Name}</Link></CardTitle>,
    sections: [
      {
        id: "section1",
        width: 75,
        header: (item: Project) => 'Number of datasets',
        content: (item: Project) => <div>
          <div>XX (coming soon!)</div>
        </div>
      }
    ]
  }

const CreateLinkButton = (text:string, project:string, disabled: boolean) => (
    <Link to={`/projects/${project.toLocaleLowerCase()}`}>
        <LinkButton disabled={disabled} text={text} variant="normal" />
    </Link>
)

export default class HomeView extends Component<Props, State> {
  state: State = {
    projects: [],
    selectedProject: null
  }

  componentDidMount = async () => {
    const idToken = getIdentityToken(this.props.user.cognitoUser)
    const projects = await getProjects(idToken, this.props.stage)
    this.setState({projects})
  }

  handleSelectionChange = (item: CustomEvent<TableSelection.SelectionChangeDetail<Project>>) => {
    this.setState({selectedProject: item.detail.selectedItems[0]})
  }

  renderActionStripe = () => (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>Projects<TableCounter>{` (${this.state.projects.length})`}</TableCounter></h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        {
          this.state.selectedProject
            ? CreateLinkButton("View datasets", this.state.selectedProject.Name, false)
            : CreateLinkButton("View datasets", "", true)
        }
      </div>
    </div>
  )

  render = () => {
    const {projects} = this.state
        return <div>
            <PageTitle title="Projects"/>
            <PageSubtitle content="List of all active projects for the Rekognition team"/>
            <Cards
                header={this.renderActionStripe()}
                cardDefinition={cardDefinition}
                items={projects}
                loadingText="Loading resources">
            <CardsSelection onSelectionChange={this.handleSelectionChange} selectionType="single" />
            <CardsFiltering filteringPlaceholder="Find projects"></CardsFiltering>
            <CardsPagination pageSize={MAX_PAGE_SIZE}></CardsPagination>
            </Cards>
        </div>
    }
}
