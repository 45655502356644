import React from "react"
import {
  Table,
  Button,
  TableFiltering,
  TablePagination,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector
} from "@amzn/awsui-components-react"
import { Annotation } from "./arenaParser"
import ImageCropper from "../Components/ImageCropper"
import TableCounter from "../Components/TableCounter"

interface Item {
  bboxId: string
  bboxArea: JSX.Element | undefined
  annotatedWords: string
}

interface Props {
  url: string
  annotations: Annotation[]
  searchId: string
}

function WordsTable(props: Props) {
  const { url, annotations, searchId } = props
  const items: Item[] = []
  annotations.forEach(annotation => {
    if (annotation.geometry.polygon && annotation.geometry.polygon.length > 2) {
      let region
      if (annotation.geometry.boundingBox) {
        region = {
          x: annotation.geometry.boundingBox.x,
          y: annotation.geometry.boundingBox.y,
          width: annotation.geometry.boundingBox.w,
          height: annotation.geometry.boundingBox.h
        }
      } else {
        region = {
          x: annotation.geometry.polygon[0].x,
          y: annotation.geometry.polygon[0].y,
          width:
            annotation.geometry.polygon[1].x - annotation.geometry.polygon[0].x,
          height:
            annotation.geometry.polygon[2].y - annotation.geometry.polygon[1].y
        }
      }
      items.push({
        bboxId: annotation.id,
        bboxArea: <ImageCropper url={url} region={region} />,
        annotatedWords: annotation.label || ""
      })
    } else if (annotation.geometry.boundingBox) {
      const region = {
        x: annotation.geometry.boundingBox.x,
        y: annotation.geometry.boundingBox.y,
        width: annotation.geometry.boundingBox.w,
        height: annotation.geometry.boundingBox.h
      }
      items.push({
        bboxId: annotation.id,
        bboxArea: <ImageCropper url={url} region={region} />,
        annotatedWords: annotation.label || ""
      })
    }
  })
  return (
    <Table
      loadingText="Loading resources"
      columnDefinitions={[
        {
          id: "bboxId",
          header: "BBox ID",
          cell: item => item.bboxId,
          minWidth: "140px"
        },
        {
          id: "bboxArea",
          header: "BBox Area",
          cell: item => item.bboxArea,
          minWidth: "200px"
        },
        {
          id: "text",
          header: "Annotated Words",
          cell: item => item.annotatedWords,
          minWidth: "260px"
        }
      ]}
      items={items}
      features={["filtering", "pagination"]}
      header={
        <h2>
          Transcriptions{" "}
          <TableCounter>{` (${annotations.length})`}</TableCounter>
        </h2>
      }
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No resources</b>
          </div>
          <p className="awsui-util-mb-s">No resources to display.</p>
        </div>
      }
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">We can’t find a match.</p>
          <div className="awsui-util-mb-m">
            <Button>Clear filter</Button>
          </div>
        </div>
      }
    >
      <TableFiltering
        filteringPlaceholder="Find words"
        filteringCountTextFunction={count =>
          `${count} ${count === 1 ? "match" : "matches"}`
        }
        filteringText={searchId}
      />
      <TablePagination pageSize={15} />
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={[
            { value: 10, label: "10 items" },
            { value: 15, label: "15 items" },
            { value: 20, label: "20 items" }
          ]}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Enable to wrap table cell content, disable to truncate text."
        />
        <TableContentSelector
          title="Select visible columns"
          options={[
            {
              label: "Properties",
              options: [
                { id: "bboxArea", label: "BBox Area", visible: true },
                { id: "text", label: "Annotated Words", visible: true }
              ]
            }
          ]}
        />
      </TablePreferences>
    </Table>
  )
}

export default WordsTable
