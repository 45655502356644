import { Button } from "@amzn/awsui-components-react";
import * as React from "react";

import { IAction } from "./interfaces";

export interface IWizardButtonGroupProps {
  actions: IAction[];
}

export class WizardButtonGroup extends React.Component<IWizardButtonGroupProps, {}> {
  render() {
    const actions = this.props.actions;

    return (
      <div>
        {actions.map((action, index) => (
          <Button
            key={index}
            id={action.id}
            variant={action.variant}
            text={action.text}
            disabled={action.disabled ? action.disabled : false}
            loading={action.loading ? action.loading : false}
            onClick={(event: any) => action.onClick(event)} />
        ))}
      </div>
    );
  }
}
